<template>
  <div class="step-strategy">
    <div class="step_title">выбор стратегии</div>
    <p class="step_description mb-5">
      выберите стратегию инвестирования, после прохождения регистрации вы
      сможете в любой момент ее поменять или отключить. стратегии не требуют
      подтверждения квалификации инвестора для инвестирования более 600 000
      рублей в год
    </p>
    <div class="strategy-container">
      <div
        v-for="strategy in strategies"
        :key="strategy.name"
        class="strategy"
        :class="{
          'chosen-strategy': strategy.isChosen,
          'grey-strategy':
            strategy.serverName ===
            constants.strategiesServerNames.defaultBorrowerDistribution,
        }"
        @click="choseStrategy(strategy.serverName)"
      >
        <div class="strategy-content-block">
          <div class="strategy-name-row">
            <div class="strategy-name-container">
              <div class="subtitle second-text no_tablet">стратегия</div>
              <div
                class="main-text no-text-transform"
                :class="{
                  'alfa-name':
                    strategy.serverName ===
                      constants.strategiesServerNames.alfaConservative ||
                    strategy.serverName ===
                      constants.strategiesServerNames.alfaLiquid,
                }"
              >
                <i
                  v-if="
                    strategy.serverName ===
                      constants.strategiesServerNames.alfaConservative ||
                    strategy.serverName ===
                      constants.strategiesServerNames.alfaLiquid
                  "
                  class="alfa-icon no_tablet"
                />
                {{ strategy.name }}
              </div>
            </div>
            <div
              class="chosen-strategy-checkbox"
              :class="{ 'chosen-strategy-checkbox-active': strategy.isChosen }"
            >
              <div class="chosen-strategy-checkbox-inside" />
            </div>
          </div>
          <div
            class="strategy-description second-text"
            v-html="strategy.shortDescription"
          />
        </div>

        <div class="strategy-rates-block">
          <div
            v-if="strategy.currentRate?.rate"
            class="strategy-rate-container"
          >
            <span class="subtitle second-text no_tablet"
              >номинальная ставка</span
            >
            <span class="subtitle second-text only_tablet">номинальная</span>
            <span class="current-rate-text main-text">
              {{ createRateText(strategy.currentRate?.rate) }}
              <span
                v-if="nextStrategyRateTitle(strategy)"
                class="rate-star second-text"
              >
                *
              </span>
              <PotokTooltip
                v-if="nextStrategyRateTitle(strategy)"
                :placement="'top'"
                :triggers="['hover']"
                class="no_tablet"
              >
                <template #popper>
                  {{ nextStrategyRateTitle(strategy) }}
                </template>
              </PotokTooltip>
            </span>
          </div>
          <div
            v-if="strategy.currentRate?.expectedMinReturnRate"
            class="strategy-rate-container second-rate"
          >
            <span class="subtitle second-text no_tablet"
              >эффективная ставка
            </span>
            <span class="subtitle second-text only_tablet">эффективная</span>
            <span class="main-text">
              {{ createRateText(strategy.currentRate?.expectedMinReturnRate) }}
            </span>
          </div>
        </div>

        <div class="strategy-conditions-block">
          <div class="strategy-condition-mobile-row only_tablet">
            <PotokButton
              size="link"
              theme="linkIconUpload"
              class="button-mobile-condition"
              @click="
                onClickShowCondition(
                  strategy.fullDescription,
                  strategy.fullDescriptionTitle,
                )
              "
            >
              условия стратегии
            </PotokButton>
            <i
              v-if="
                strategy.serverName ===
                  constants.strategiesServerNames.alfaConservative ||
                strategy.serverName ===
                  constants.strategiesServerNames.alfaLiquid
              "
              class="strategy-conditions-alfa-icon only_tablet"
            />
          </div>
          <PotokButton
            theme="secondary"
          >
            {{ strategy.isChosen ? 'выбрано' : 'выбрать' }}
          </PotokButton>

          <PotokButton
            theme="link"
            size="link"
            class="button-pc-condition no_tablet"
            @click.stop="
              onClickShowCondition(
                strategy.fullDescription,
                strategy.fullDescriptionTitle,
              )
            "
          >
            условия стратегии
          </PotokButton>

          <BonusFromBalance
            v-if="
              isBonusFromBalanceActive &&
              isStrategyIncludedBonus(strategy.serverName) &&
              bonusFromBalanceData.isShowIcon
            "
            :loading="loading"
            class="onboard-bonus-from-balance"
          />

          <div
            v-if="
              nextFreeStrategyRateTitle &&
              strategy.serverName ===
                constants.strategiesServerNames.withoutRisk
            "
            class="next-free-strategy-rate second-text only_tablet"
          >
            {{ nextFreeStrategyRateTitle }}
          </div>
        </div>
      </div>
    </div>

    <PotokDivider margin-y="20" />

    <div>
      <div class="action_wrapper">
        <PotokButton
          size="large"
          :disabled="!isEnabledContinue"
          @click="onClickContinue"
        >
          Продолжить
        </PotokButton>
        <PotokButton
          v-if="!store.isAlfaCustomer"
          size="link"
          theme="link"
          class="button-withoutstrategy"
          :disabled="loading"
          @click="putOnboardStrategy('manual')"
        >
          Без стратегии
        </PotokButton>
      </div>
    </div>

    <DynamicStrategiesModals
      :selected-strategy="chosedStrategy"
      :is-show="isShowDynamicModals"
      @close="handleCloseDynamicModals"
      @finish="handleFinishDymamicModals"
    />

    <ModalSimplifiedSystem
      v-if="isShowSimplifiedSystemModal"
      @close="(isShowSimplifiedSystemModal = false)"
      @confirm="confirmSimplifiedSystemMessage"
    />

    <ModalConfirmSMS
      v-if="isShowModalConfirm"
      :loading="loading"
      :confirm-function="onClickConnectStrategyConfirm"
      :error-message="confirmErrorMessage"
      :confirm-message="confirmMessage"
      :title="'подписание документа'"
      @close="(settingsId = null)"
    />
    <ModalFull v-if="message" :title="messageTitle" @close="onCloseMessage">
      <template #content>
        <div v-html="message" />
      </template>
    </ModalFull>
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';

import { format, parseISO } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { PotokTooltip, PotokButton } from 'potok-uikit';
import { useStateStore } from '@/store/stateStore';
import server from '@/server';
import ModalConfirmSMS from '@/components/_generic/modals/ModalConfirmSMS.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import BonusFromBalance from '@/components/_generic/BonusFromBalance.vue';
import ModalSimplifiedSystem from '@/components/AutoInvest/ModalSimplifiedSystem';
import DynamicStrategiesModals from '@/components/_generic/DynamicStrategiesModals.vue';
import tracker from '@/tracker';
import constants from '@/constants';

const message = ref(null);
const messageTitle = ref(null);

const store = useStateStore();
const emit = defineEmits(['updateStep']);

const loading = ref(false);

const settingsId = ref(null);
const newCompanyId = ref(null)
const confirmErrorMessage = ref(null);
const confirmMessage = ref(null);

const strategies = ref(null);

const isShowDynamicModals = ref(false);
const isShowSimplifiedSystemModal = ref(false);

const isBonusFromBalanceActive = computed(() => {
  return store.state.features?.general?.remainingBalanceInterestPromo?.enabled;
});

const isEnabledContinue = computed(() => {
  const chosenStrategy = strategies.value?.find((item) => item.isChosen);
  return chosenStrategy && !loading.value;
});

const chosedStrategy = computed(() => {
  return strategies.value?.find((item) => item.isChosen);
});

const isShowModalConfirm = computed(() => !!settingsId.value);

const freeStrategy = computed(() =>
  strategies.value.find(
    (item) => item.serverName === constants.strategiesServerNames.withoutRisk,
  ),
);

const isStrategyIncludedBonus = (strategy) => {
  return bonusFromBalanceData.value?.eligibleStrategies.includes(strategy);
};

const bonusFromBalanceData = computed(() => {
  return store.state.features.remainingBalanceInterestPromo;
});

const nextFreeStrategyRateTitle = computed(() => {
  if (
    !freeStrategy.value?.futureRate?.rate ||
    !freeStrategy.value?.futureRate?.startAt
  ) {
    return null;
  }

  const computedRate = `*${createRateText(freeStrategy.value?.futureRate?.rate)}`;

  return `${computedRate} с ${format(parseISO(freeStrategy.value?.futureRate?.startAt), 'dd.MM.yyyy', { locale: ru })}`;
});

const nextStrategyRateTitle = (strategy) => {
  if (!strategy?.futureRate?.rate || !strategy?.futureRate?.startAt) {
    return null;
  }

  const computedRate = `*${createRateText(strategy?.futureRate?.rate)}`;

  return `${computedRate} с ${format(parseISO(strategy?.futureRate?.startAt), 'dd.MM.yyyy', { locale: ru })}`;
};

const createRateText = (rate) => {
  const finalRate = rate * 100;
  const fixedRate = Number(finalRate.toFixed(1));
  return `${fixedRate}%`;
};
const choseStrategy = (serverName) => {
  const prevChosenStrategy = strategies.value.find((item) => item.isChosen);
  if (prevChosenStrategy) {
    prevChosenStrategy.isChosen = false;
  }

  let newChosenStrategy = strategies.value.find(
    (item) => item.serverName === serverName,
  );

  if (!newChosenStrategy) {
    newChosenStrategy = strategies.value[0];
  }
  newChosenStrategy.isChosen = true;
};
const onClickShowCondition = (condition, name) => {
  message.value = condition;
  const regexp = />(.*?)</g;
  let matchAll = name.matchAll(regexp);
  matchAll = Array.from(matchAll);
  messageTitle.value = matchAll[0][1];
};

const onClickContinue = () => {
  isShowDynamicModals.value = true;
};

// const confirmSimplifiedSystemMessage = () => {
//   const selectedStrategy = strategies.value.find((item) => item.isChosen);

//   isShowSimplifiedSystemModal.value = false;
//   putOnboardStrategy(selectedStrategy.serverName);
// };

const handleCloseDynamicModals = () => {
  isShowDynamicModals.value = false;
};

const handleFinishDymamicModals = () => {
  isShowDynamicModals.value = false;
  putOnboardStrategy(chosedStrategy.value.serverName);
};

const putOnboardStrategy = (strategy) => {
  const query = {
    strategy,
    companyId: store.selectedCompany.id,
  };

  tracker.queue(tracker.commands.SEND, 'Investor_LK_Onboard_Step_3');

  loading.value = true;

  if (strategy === 'manual') {
    return server.putUserOnboardStrategy
      .send(query)
      .pipe(onPutStrategySuccess, onPutStrategyError)
      .exec();
  }

  return server.putUserOnboardStrategy
    .send(query)
    .pipe(onFetchOnboardStrategySuccess, () => {
      loading.value = false;
    })
    .exec();
};
const onFetchOnboardStrategySuccess = (obj) => {
  loading.value = false;
  if (obj.data.isSuccessful) {
    confirmMessage.value = 'подключение выбранной стратегии';
    settingsId.value = obj.data.data.settingsId;
    if (obj.data.data.companyId) {
      newCompanyId.value = obj.data.data.companyId;
    }
  }
};
const onClickConnectStrategyConfirm = ({ code }) => {
  confirmErrorMessage.value = null;
  const query = {
    code,
    companyId: newCompanyId.value,
  };
  loading.value = true;
  return server.putUserOnboardStrategyConfirm
    .send(query, {
      params: {
        settingsId: settingsId.value,
      },
    })
    .pipe(onPutStrategySuccess, onPutStrategyError)
    .exec();
};
const onPutStrategySuccess = (obj) => {
  loading.value = false;
  if (obj.data.isSuccessful) {
    settingsId.value = null;
    emit('updateStep', {
      step: obj.data.onboardingStatus,
    });
  } else if (obj.data.error) {
    confirmErrorMessage.value = obj.data.error;
  } else {
    confirmErrorMessage.value =
      'Неизвестная ошибка. Обратитесь в тех. поддержку';
  }
};
const onPutStrategyError = () => {
  loading.value = false;
};

const onCloseMessage = () => {
  message.value = null;
  messageTitle.value = null;
  loading.value = false;
};

const getStrategies = () => {
  loading.value = true;

  const query = {
    companyId: store.selectedCompanyId,
  };

  return server.getStrategies
    .send(query)
    .pipe(
      (obj) => {
        const strategiesResponse = obj.data;
        const modifiedStrategies = [];

        const keys = Object.keys(strategiesResponse);

        if (!keys.length) {
          strategies.value = [];
          return;
        }

        for (let i = 0; i < keys.length; i++) {
          const newItem = {
            ...strategiesResponse[keys[i]],
            serverName: keys[i],
            isChosen: false,
          };

          modifiedStrategies.push(newItem);
        }

        strategies.value = modifiedStrategies;

        if (store.isAlfaCustomer) {
          choseStrategy(constants.strategiesServerNames.alfaConservative);
        } else {
          choseStrategy(constants.strategiesServerNames.withoutRisk);
        }
        loading.value = false;
      },
      () => {
        loading.value = false;
      },
    )
    .exec();
};

watch(
  () => store.selectedCompanyId,
  () => {
    if (store.selectedCompanyId) {
      getStrategies();
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.step-strategy {
  text-transform: none;
}

.strategy {
  position: relative;
  display: flex;
  background: $background;
  border-radius: 14px;
  border: 1px solid $background;
  padding: 30px 20px 20px;
  margin: 14px 0;
  cursor: pointer;

  &:hover {
    background: #fff;
  }

  @media (max-width: $size_991) {
    flex-direction: column;
    padding: 20px 20px 14px 14px;
  }
}

.chosen-strategy {
  border: 1px solid $breakwater;
  background: #fff;
}

.strategy-content-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;

  @media (max-width: $size_991) {
    margin-bottom: 0;
  }
}

.strategy-name-row {
  display: flex;

  @media (max-width: $size_991) {
    justify-content: space-between;
    align-items: start;
    margin-bottom: 6px;
  }
}

.second-text {
  line-height: 22px;
  color: $grey;
}

.main-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $deep_dive;
  text-transform: lowercase;

  @media (max-width: $size_991) {
    font-size: 20px;
    line-height: 26px;
  }
}

.subtitle {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media (max-width: $size_991) {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0.01px;
    margin-bottom: 6px;
  }
}

.strategy-condition-mobile-row {
  width: 100%;
  display: none;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $size_991) {
    display: flex;
  }
}

.strategy-conditions-alfa-icon {
  display: block;
  height: 14px;
  width: 68px;
  margin-left: auto;
  background-image: url('@img/alfa-full.svg');
}

.next-free-strategy-rate {
  position: absolute;
  bottom: 15px;
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.01em;

  @media (max-width: $size_991) {
    bottom: 40px;
  }

  @media (max-width: $size_991) {
    position: static;
    margin-top: 10px;
  }
}

.alfa-name {
  display: flex;
  align-items: center;
  color: $red;
}

.alfa-icon {
  margin-right: 5px;
  display: block;
  height: 16px;
  min-width: 16px;
  background-image: url('@img/alfa-circle.svg');
  background-size: contain;
}

.button-pc-condition {
  font-size: 14px;
  margin-top: 40px;
  color: $deep_dive;
}

.current-rate-text {
  display: flex;
  align-items: center;
}

.rate-star {
  position: relative;
  bottom: 1px;
  right: 1px;
}

.strategy-description {
  position: absolute;
  bottom: 20px;
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  padding-right: 180px;

  @media (max-width: $size_991) {
    position: static;
    padding: 0;
    color: $deep_dive;
  }
}

.chosen-strategy-checkbox {
  display: none;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  background: #fff;

  @media (max-width: $size_991) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.chosen-strategy-checkbox-active {
  background: $breakwater;
}

.chosen-strategy-checkbox-inside {
  height: 6px;
  width: 6px;
  background: 6px;
  background: #fff;
  border-radius: 50%;
}

.strategy-rates-block {
  display: flex;
  margin-left: 10px;

  @media (max-width: $size_991) {
    margin-left: 0;
    margin-top: 20px;
  }
}

.strategy-rate-container {
  display: flex;
  flex-direction: column;
}

.second-rate {
  margin-left: 40px;
}

.strategy-conditions-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;

  @media (max-width: $size_991) {
    margin-top: 20px;
    margin-bottom: 0;
    margin-left: 0;
    align-items: start;
  }
}

.button-mobile-condition {
  display: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: initial;

  @media (max-width: $size_991) {
    display: block;
  }
}

.button-withoutstrategy {
  color: $grey;
  white-space: nowrap;

  @media (max-width: $size_991) {
    margin-top: 20px;
    height: 24px;
    max-width: 126px;
  }
}

.action_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media (max-width: $size_991) {
    flex-direction: column;
    justify-content: normal;
    align-items: center;
  }
}

.strategy-name-container {
  min-width: 210px;

  @media (width <= 1200px) {
    min-width: 150px;
  }

  @media (max-width: $size_991) {
    min-width: 120px;
  }

  @media (max-width: $size_767) {
    min-width: auto;
  }
}

.grey-strategy {
  background: #e8ebee;
}

:deep(.custom-checkbox-row .check) {
  background-color: #fff;
  margin-right: 4px;
}

:deep(.modal_block_content) {
  ul {
    padding-left: 20px;
  }
}

:deep(.onboard-bonus-from-balance) {
  position: absolute;
  bottom: 20px;
  right: 166px;

  @media (width <= 1200px) {
    right: 120px;
  }

  @media (max-width: $size_991) {
    right: 14px;
    bottom: 20px;
  }
}
</style>
