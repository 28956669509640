<template>
  <div class="container container_wrapper">
    <div class="login_title">
      <div>восстановление пароля</div>
    </div>
    <div
      class="login_subtitle potok-text-body-1 mt-3 text-center"
      data-test="login-recover-step2-subtitle"
    >
      для подтверждения мы отправили код на номер
      <span>{{ props.rawPhone }}</span>
    </div>
    <div>
      <div class="login_actions">
        <div class="login_input_wrapper">
          <PotokInputLarge
            id="smsCode"
            v-model="localRawCode"
            type="text"
            :readonly="props.loadingPostRecovery"
            placeholder="код из смс"
            autocomplete="off"
            maxlength="6"
            data-test="login-recover-step2-input"
            @input="onClickPressKeyEnterCode"
            @keyup.enter="onClickEnterCode()"
          />
          <div
            v-if="errorMessageSMSCode"
            class="potok-color-red"
            data-test="login-recover-step2-error-message"
          >
            {{ errorMessageSMSCode }}
          </div>
        </div>
        <div class="mt-6">
          <PotokButton
            :disabled="props.loadingPostRecovery"
            size="large"
            class="w-100"
            data-test="login-recover-step2-continue"
            @click="onClickEnterCode()"
          >
            продолжить
          </PotokButton>

          <template v-if="leftTime > 0">
            <div
              class="countdown potok-text-body-1 potok-color-deepdive text-center mt-3"
            >
              <div
                class="countdown_span"
                data-test="login-recover-step2-countdown-timer"
              >
                отправить ещё через
                <span>{{ leftTime }} c</span>
              </div>
            </div>
          </template>
          <template v-if="leftTime == 0">
            <PotokButton
              theme="link"
              size="link"
              class="mt-7 w-100"
              :disabled="loading"
              data-test="login-recover-step2-repeat-btn"
              @click="onClickRequestRetryCode()"
            >
              выслать СМС повторно
            </PotokButton>
          </template>

          <PotokButton
            theme="link"
            size="link"
            class="mt-6 w-100"
            data-test="login-recover-step2-back"
            @click="onClickBack()"
          >
            назад
          </PotokButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, nextTick, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { PotokButton, PotokInputLarge } from 'potok-uikit';

import { focusDomElementById } from '@/utils/commonUtils/utils';
import server from '@/server';

import { convertRawPhoneToLogin } from '@/utils/phone/formatPhone';
import countdownComposable from '@/composables/countdownComposable';

const router = useRouter();

const props = defineProps({
  rawPhone: {
    type: String,
    default: '',
  },
  rawPassword: {
    type: String,
    default: '',
  },
  errorMessageRecovery: {
    type: String,
    default: '',
  },
  loadingPostRecovery: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['changeStep', 'changeMode', 'changePassword']);

const { leftTime, countdownReset, countdown } = countdownComposable();

const localRawCode = ref(null);
const loading = ref(null);
const errorMessageSMSCode = ref(null);

const isValidSmsCode = () => {
  if (!localRawCode.value) {
    errorMessageSMSCode.value = 'обязательное поле. минимум 6 символов';
    return false;
  }
  if (localRawCode.value.length < 6) {
    errorMessageSMSCode.value = 'минимум 6 символов';
    return false;
  }
  return true;
};

const onClickPressKeyEnterCode = () => {
  errorMessageSMSCode.value = null;
};

const onClickEnterCode = () => {
  if (!isValidSmsCode()) {
    return;
  }

  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
    smsCode: localRawCode.value,
    newPassword: props.rawPassword,
  };
  loading.value = true;
  return server.postUsersRecoverPassConfirm
    .send(query)
    .pipe(onConfirmRecoveringAsyncSuccess.bind(this, query), () => {
      loading.value = false;
    })
    .exec();
};

const onConfirmRecoveringAsyncSuccess = (query, { data }) => {
  loading.value = false;
  if (data.isSuccessful) {
    countdown.stop();

    return router.push({
      name: 'main',
    });
  } else {
    errorMessageSMSCode.value = 'неверный код СМС';
  }
};

const onClickBack = () => {
  emit('changeStep', {
    nextStep: 'inputPassword',
  });
};

const onClickRequestRetryCode = () => {
  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
  };
  loading.value = true;
  return server.postUsersRecoverPassRequest
    .send(query)
    .pipe(onRequestRecoveringAsyncSuccess.bind(this, query), () => {
      loading.value = false;
    })
    .exec();
};

const onRequestRecoveringAsyncSuccess = () => {
  loading.value = false;
  countdownReset();
  nextTick(() => focusDomElementById('smsCode'));
};

onMounted(() => {
  countdownReset();
});
</script>
<style lang="scss" scoped>
.button-password {
  margin-top: 23px;
}
</style>
