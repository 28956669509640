<template>
  <div class="borrower-rating">
    <PotokDivider :margin-y="isMobile ? 16 : 28" />

    <!-- desktop -->
    <div
      v-if="!isMobile"
      class="borrower-rating-header borrower-rating-container"
    >
      <span class="borrower-rating-header-label potok-text-h2">рейтинг</span>
      <BorrowerRatingTiles
        class="borrower-rating-final"
        :rating="props.borrower.modelBucket.rating"
      />
      <div class="final-rating-button-container">
        <PotokButton
          theme="linkIcon"
          size="link"
          class="borrower-rating-final-button"
          @click="(isShowComponents = !isShowComponents)"
        >
          <PotokImg
            :src="
              isShowComponents
                  ? imageConstants.arrowUp
                  : imageConstants.arrowDown"
            height="5"
            width="10"
          />
        </PotokButton>
      </div>
    </div>

    <!-- mobile -->
    <div
      v-if="isMobile"
      class="borrower-rating-header borrower-rating-container"
    >
      <div class="borrower-rating-header-container">
        <span class="borrower-rating-header-label potok-text-h2">рейтинг</span>

        <div class="final-rating-button-container">
          <PotokButton
            theme="linkIcon"
            size="link"
            class="borrower-rating-final-button"
            @click="(isShowComponents = !isShowComponents)"
          >
            <PotokImg
              :src="
                isShowComponents
                    ? imageConstants.arrowUp
                    : imageConstants.arrowDown"
              height="5"
              width="10"
            />
          </PotokButton>
        </div>
      </div>

      <BorrowerRatingTiles
        class="borrower-rating-final"
        :rating="props.borrower.modelBucket.rating"
      />
    </div>

    <div
      v-if="borrower.components && isShowComponents"
      class="borrower-rating-components"
    >
      <PotokDivider margin-y="0" />

      <div class="potok-text-uppercase mt-5 mb-5">из чего состоит рейтинг</div>
  
      <div
        v-for="(item, index) in borrower.components"
        :key="index"
        class="borrower-rating-component borrower-rating-container mb-5"
      >
        <BorrowerRatingTiles
          :rating="item.rating"
          is-component
          is-small
          class="borrower-rating-component-tiles"
        />
        <div class="borrower-rating-component-right">
          <span class="potok-text-body-2">{{ item.label }}</span>
          <PotokTooltip
            :placement="'top'"
            class="rating-component-dropdown"
            :triggers="['hover']"
            defaultIconClass="ml-2"
          >
            <template #popper>
              {{ item.description }}
            </template>
          </PotokTooltip>
        </div>
      </div>
    </div>

    <PotokDivider v-if="borrower.components" margin-y="28" />

    <div class="mt-5 mb-5 potok-color-grey">
      Рейтинг – это оценка риска, который несет Инвестор, предоставляя денежные средства тому или иному Заемщику.
      <a href="https://blog.potok.digital/instrumentarij-investora-kak-chitat-kreditnyj-rejting/" target="_blank">Подробнее</a>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { PotokDivider, PotokTooltip } from 'potok-uikit';

import BorrowerRatingTiles from '@/components/Loan/LoanAboutBorrower/LoanAboutBorrowerRating/BorrowerRatingTiles.vue';

import imageConstants from '@/constants/imageConstants';
import detectDevice from '@/composables/detectDeviceComposable';

const { isMobile } = detectDevice();

const props = defineProps({
  borrower: {
    type: [Object, null],
    default: () => {},
  },
});

const isShowComponents = ref(false);
</script>

<style lang="scss">
@use '../../../scss/variables.scss' as *;
@use '../../../scss/typography.scss' as *;

.borrower-rating {
  width: 100%;
}

.borrower-rating-header {
  display: flex;

  @media (max-width: $size_767) {
    flex-direction: column;
  }
}

.borrower-rating-header-label {
  display: flex;
  align-items: center;
}

.borrower-rating-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: $size_767) {
    width: 100%;
  }
}

.borrower-rating-container {
  display: flex;
  align-items: center;

  @media (max-width: $size_767) {
    flex-direction: column;
    align-items: initial;
    padding-right: 0;
  }
}

.borrower-rating-final {
  display: flex;
  align-items: center;
  margin-left: calc(25% - (80px - 7px));

  @media (max-width: $size_767) {
    width: 100%;
    margin-left: 0;
  }
}

.final-rating-button-container {
  height: 40px;
  width: 40px;
  margin-right: 30px;
  border-radius: 50%;
  margin-left: auto;
  background: $background;

  @media (max-width: $size_767) {
    height: 24px;
    width: 24px;
    margin-right: 0;
  }
}

.borrower-rating-final-button {
  height: 40px;
  width: 40px;

  @media (max-width: $size_767) {
    height: 24px;
    width: 24px;
  }
}

.borrower-rating-label {
  @media (max-width: $size_767) {
    @include potok-text-body-2;

    margin-bottom: 10px;
  }
}

.borrower-rating-components {
  margin-top: 28px;
  margin-left: calc(25% + 7px);

  @media (max-width: $size_767) {
    margin-top: 20px;
    margin-left: 0;
  }
}

.borrower-rating-component {
  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $size_767) {
    width: 100%;
  }
}

.borrower-rating-component-tiles {
  @media (max-width: $size_767) {
    order: 2;
    width: 100%;
  }
}

.borrower-rating-component-right {
  display: flex;
  align-items: center;
  margin-left: 20px;

  @media (max-width: $size_767) {
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: 0;
  }
}

.rating-component-dropdown {
  display: flex;
  align-items: center;
}
</style>
