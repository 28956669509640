import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

import '@yzfe/svgicon/lib/svgicon.css';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import { vClosePopper } from 'floating-vue';
import PotokImg from '@/components/_generic/PotokImg.vue';
import PotokChip from '@/components/_generic/PotokChip.vue';
import { PotokButton, CSelect, PotokDivider } from 'potok-uikit';

import App from '@/components/App.vue';
import router from '@/router/index';

import 'potok-uikit/dist/index.css';
import 'font-awesome/css/font-awesome.css';
import '@/scss/main.scss';
import '@/scss/bootstrap.scss';
import '@/libs/vue-img-inputer/style/index.scss';
import "@sjmc11/tourguidejs/src/scss/tour.scss"

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);

app.component('VSelect', vSelect);
app.component('VueSlider', VueSlider);
app.component('PotokImg', PotokImg);
app.component('PotokButton', PotokButton);
app.component('PotokDivider', PotokDivider);
app.component('CSelect', CSelect);
app.component('PotokChip', PotokChip);
app.directive('close-popper', vClosePopper);

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    app,
    dsn: 'https://97e1c00cd4c348199e51a9cc94f46f48@sentry.potok.team/12',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [/^https:\/\/i\.potok\.digital/],
    tracesSampleRate: 0.5,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.mount('#app');
export default app;
