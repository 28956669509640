/* eslint-disable */
import Tracker from '@/libs/browser-analytics';

import config from '@/../config';

const ymtOptionsLKI = {
  id: 52664983,
  accurateTrackBounce: true,
  clickmap: true,
  trackLinks: true,
  ecommerce: false,
  trackHash: false,
  webvisor: true,
};

const ymtOptionsLKZ = {
  id: 34004890,
  accurateTrackBounce: true,
  clickmap: true,
  trackLinks: true,
  ecommerce: false,
  trackHash: false,
  webvisor: true,
};

// @see https://developers.google.com/analytics/devguides/collection/analyticsjs/creating-trackers
// const gatOptions = {
//   trackingId: 'UA-70993559-2',
//   cookieDomain: 'auto'
// }

// const amplOptions = {
//   apiKey: config.VITE_AMPLITUDE_API_KEY,
// };

const isProduction = import.meta.env.MODE === 'production';
// const isProduction = true

// global gtm, VK
if (isProduction) {
  !(function () {
    var t = document.createElement('script');
    (t.type = 'text/javascript'),
      (t.async = !0),
      (t.src = 'https://vk.com/js/api/openapi.js?169'),
      (t.onload = function () {
        VK.Retargeting.Init('VK-RTRG-1794385-g42UT'), VK.Retargeting.Hit();
      }),
      document.head.appendChild(t);
  })();

  !(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-T9NZZZ7');
}
const tracker = new Tracker({
  ymtOptionsLKI,
  ymtOptionsLKZ,
  // gatOptions,
  // amplOptions,
  isProduction,
});

export default tracker;
