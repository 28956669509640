<template>
  <div class="refill-spb">
    <div class="block-left block_content">
      <div class="col-md-12">
        <div class="text-desc potok-text-h2">
          <div v-html="props.data?.text"></div>
        </div>
        <div class="form-wrapper">
          <div class="amount-wrapper">сумма пополнения</div>
          <div class="step-input-amount">
            <PotokInputMoneyField
              v-model="localAmountValue"
              placeholder="0"
              :vmaska="masks.digits(7)"
              @input="props.onInput(localAmountValue)"
            />
            <div
              v-if="props.data?.min"
              class="potok-text-body-3 potok-color-grey mt-4"
            >
              минимальная сумма пополнения:
              {{ formatCurrency(props.data?.min, 0) }}&nbsp;₽
            </div>
            <div
              v-if="props.data?.max"
              class="potok-text-body-3 potok-color-grey"
            >
              максимальная сумма пополнения:
              {{ formatCurrency(props.data?.max, 0) }}&nbsp;₽
            </div>
          </div>
          <div v-if="props.errorMessage" class="potok-color-red">
            {{ props.errorMessage }}
          </div>
          <div>
            <PotokButton
              theme="secondary"
              size="large"
              class="button-apply"
              :disabled="props.loading"
              @click="nextStep"
            >
              пополнить счет
            </PotokButton>
          </div>
        </div>
      </div>
    </div>
    <RefillBalanceSPBRightPanel />
  </div>
</template>
<script setup>
import { PotokInputMoneyField, PotokButton } from 'potok-uikit';

import masks from '@/utils/masks.js';
import RefillBalanceSPBRightPanel from './RefillBalanceSPBRightPanel.vue';
import { formatCurrency } from '@/utils/commonUtils/libUtils';

const emit = defineEmits(['update:amountValue', 'nextStep']);
const props = defineProps({
  errorMessage: {
    type: String,
  },
  amountValue: {
    type: [Number, String],
  },
  onInput: {
    type: Function,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    required: false,
  },
});

const localAmountValue = defineModel();
localAmountValue.value = props.amountValue;

const nextStep = () => {
  emit('update:amountValue', parseInt(localAmountValue.value));
  emit('nextStep');
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.refill-spb {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.block-left {
  min-width: 750px;

  @media (max-width: $size_767) {
    min-width: initial;
  }
}

.block-right {
  width: 360px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: $size_767) {
    display: none;
  }

  @media (max-width: $size_1200) {
    margin-top: 20px;
  }
}

.text-desc {
  max-width: 522px;
}

.form-wrapper {
  max-width: 380px;

  @media (max-width: $size_767) {
    width: 100%;
  }
}

.amount-wrapper {
  margin-top: 26px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.step-input-amount {
  margin-top: 13px;
}

.button-apply {
  margin-top: 19px;
  margin-bottom: 138px;
  width: 100%;

  @media (max-width: $size_767) {
    margin-bottom: 0;
  }
}

.form-wrapper {
  padding-bottom: 30px;
}
</style>
