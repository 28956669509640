const phone = () => {
  return '+7 (###) ###-##-##'
};

const currency = () => {
  return [
    '# ### ### ###',
    '### ### ###',
    '## ### ###',
    '# ### ###',
    '### ###',
    '## ###',
    '# ###',
    '###',
    '##',
    '#',
  ];
};

const sum = () => {
  return '#*';
};

const digits = (n) => {
  return new Array(n).fill('#').join('');
};

const date = () => {
  return '##.##.####';
};

const accountNumber = () => {
  return digits(20);
};

const bic = () => {
  return digits(9);
};

export default {
  phone,
  currency,
  sum,
  digits,
  date,
  accountNumber,
  bic,
};
