<template>
  <PotokModal
    @close="emits('close')"
    size="fit"
  >
    <template #content>
      <div class="content">
        <div class="title potok-text-h2 mb-8">предложить свою ставку</div>

        <PotokInputLarge
          v-model="rate"
          placeholder="введите ставку в процентах"
          :disabled="isLoading"
          class="input"
        />

        <div v-if="errorMessage && isRateGotChanged" class="error potok-color-red potok-text-body-1 mt-1">{{ errorMessage }}</div>
      </div>
    </template>

    <template #actions>
      <div class="actions">
        <PotokButton
          fullWidthDesktop
          text="предложить"
          size="large"
          @click="offerHandle"
        />
        <PotokButton
          theme="link"
          size="link"
          text="отмена"
          class="mt-5"
          @click="close"
        />
      </div>
    </template>
  </PotokModal>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { PotokModal, PotokButton, PotokInputLarge } from 'potok-uikit';

// import server from '@/server';
// import { useStateStore } from '@/store/stateStore';

// const store = useStateStore()
const emits = defineEmits(['close']);

const isLoading = ref(false);
const isRateGotChanged = ref(false);

const rate = ref(null);

const errorMessage = computed(() => {
  if (!rate.value) {
    return 'введите ставку';
  }

  if (isNaN(Number(rate.value))) {
    return 'ставка должна быть числом';
  }

  if (rate.value <= 0) {
    return 'введите корректную ставку';
  }

  return '';
});

const offerHandle = () => {
  close();
  //requestAutoInvestSettingsReconnect();
}

const close = () => {
  emits('close');
}

// const requestAutoInvestSettingsReconnect = () => {
//   isLoading.value = true;

//   return server.postCompanyAutoInvestSettingsReconnect
//     .send({}, { params: { id: store.selectedCompanyId } })
//     .pipe(
//       onRequestAutoInvestSettingsReconnectSuccess(),
//       () => (isLoading.value = false),
//     )
//     .exec();
// };

// const onRequestAutoInvestSettingsReconnectSuccess = () => {
//   isLoading.value = false;
//   confirmErrorMessage.value = null;
// };

watch(
  rate,
  () => {
    if (rate.value) {
      isRateGotChanged.value = true;
    };
  },
)
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.input {
  width: 340px;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

.error {
  text-align: start;
}

.actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
