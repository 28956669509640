<template>
  <PotokPage class="login-recover" data-test="login-recover">
    <nav class="nav-bar">
      <div class="nav-bar-left">
        <a href="/" class="nav-bar-logo mr-6" aria-label="Home"></a>
      </div>
    </nav>
    <component
      :is="currentComponent"
      :raw-phone="rawPhone"
      :raw-password="rawPassword"
      @change-step="changeStep"
    />
  </PotokPage>
</template>
<script setup>
import { ref, computed } from 'vue';

import LoginInputRecoverStep1 from '@/components/Login/LoginRecover/LoginRecoverStep1.vue';
import LoginInputRecoverStep2 from '@/components/Login/LoginRecover/LoginRecoverStep2.vue';

import { PotokPage } from 'potok-uikit';

const currentStep = ref('inputPassword');
const rawPhone = ref(null);
const rawPassword = ref(null);

const currentComponent = computed(() => {
  if (currentStep.value === 'inputPassword') {
    return LoginInputRecoverStep1;
  }
  if (currentStep.value === 'inputSMSCode') {
    return LoginInputRecoverStep2;
  }
  return null;
});

const changeStep = (obj) => {
  if (obj.nextStep) {
    currentStep.value = obj.nextStep;
  }
  if (obj.phone) {
    rawPhone.value = obj.phone;
  }
  if (obj.password) {
    rawPassword.value = obj.password;
  }
};
</script>
<style lang="scss" scoped>
@use '@/scss/variables.scss' as *;

.login_wrapper .login-recover {
  padding-top: 72px;
}

:deep(.wrapper__content) {
  background-color: white;
}

:deep(.login_title) {
  margin-top: 74px;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: $deep_dive;
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    max-width: 688px;
  }

  @media (max-width: $size_991) {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.01em;

    div {
      max-width: 208px;
    }
  }
}

:deep(.login_actions) {
  width: 340px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  margin: auto;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

:deep(.login_input_wrapper) {
  width: 340px;
  margin-top: 20px;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

:deep(.button-password) {
  height: 60px;
  width: 340px;
}

:deep(.button-password-forgot) {
  color: $grey;
  margin-top: 30px;
}

:deep(.button-back) {
  color: $grey;
  margin-top: 30px;
}
</style>
