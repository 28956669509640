<template>
  <PotokModal title="настройка копилки" size="fit" @close="close">

    <template #content>
      <div class="cashbox-modal-content">
        <div class="potok-text-body-2 mb-5">
          все поступления на лицевой счет складываются в копилку, пока средства
          в ней не достигнут целевого значения. Средства в копилке недоступны
          для автоматического инвестирования
        </div>
        <div class="potok-text-body-2 potok-color-grey mb-2">
          целевое значение в копилке
        </div>
        <input
          id="rawSum"
          v-model="rawSum"
          v-maska
          :data-maska="localMasks.currency"
          class="form-control"
          type="text"
        />
      </div>
    </template>

    <template #actions>
      <div class="cashbox-modal-actions">
        <PotokButton
          :disabled="isPending"
          text="изменить"
          fullWidthDesktop
          @click="onClickChangeCashboxLimit"
        />
        <PotokButton
          theme="tertiary"
          text="отключить"
          :disabled="isPending"
          class="mt-4"
          fullWidthDesktop
          @click="onClickDisableCashboxLimit"
        />
      </div>
    </template>
  </PotokModal>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { vMaska } from 'maska/vue';
import { PotokButton, PotokModal } from 'potok-uikit';
import masks from '@/utils/masks';
import tracker from '@/tracker';
import server from '@/server/index';
import { formatNumber } from '@/utils/commonUtils/utils';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();
const emit = defineEmits(['close']);
const rawSum = ref(0);
const loadingPostCompaniesCashboxLimitUpdate = ref(false);

const isPending = computed(() => {
  return loadingPostCompaniesCashboxLimitUpdate.value;
});
const localMasks = computed(() => {
  return {
    currency: masks.currency(),
  };
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});

onMounted(() => {
  rawSum.value = Number(selectedCompany.value.cashboxLimitAmount);
  document.body.style.overflow = 'hidden';
});
onUnmounted(() => {
  document.body.style.overflow = null;
});

const close = () => {
  emit('close');
};
const postCompaniesCashboxLimitUpdateAsync = () => {
  if (rawSum.value === '') {
    tracker.queue(
      tracker.commands.SEND,
      'Investor_LK_My_Account_Moneybox_Cancel_Threshold',
    );
  } else {
    if (Number(selectedCompany.value.cashboxLimitAmount) > 0) {
      tracker.queue(
        tracker.commands.SEND,
        'Investor_LK_My_Account_Moneybox_Change_Threshold',
      );
    } else {
      tracker.queue(
        tracker.commands.SEND,
        'Investor_LK_My_Account_Moneybox_Set_Threshold',
      );
    }
  }

  const query = {
    id: selectedCompany.value.id,
    amount: Number(formatNumber(rawSum.value)),
  };
  loadingPostCompaniesCashboxLimitUpdate.value = true;
  return server.postCompaniesCashboxLimitUpdate
    .send(query)
    .pipe(
      onPostCompaniesCashboxLimitUpdateAsyncSuccess.bind(this),
      onPostCompaniesCashboxLimitUpdateAsyncError.bind(this),
    )
    .exec();
};
const onPostCompaniesCashboxLimitUpdateAsyncError = () => {
  loadingPostCompaniesCashboxLimitUpdate.value = false;
};
const onPostCompaniesCashboxLimitUpdateAsyncSuccess = () => {
  loadingPostCompaniesCashboxLimitUpdate.value = false;
  store.fetchBalance({
    companyId: selectedCompany.value.id,
  });
  store.fetchActiveBalance({
    companyId: selectedCompany.value.id,
  });
  store.fetchCompanies();
  close();
};
const onClickDisableCashboxLimit = () => {
  rawSum.value = '';
  postCompaniesCashboxLimitUpdateAsync();
};
const onClickChangeCashboxLimit = () => {
  postCompaniesCashboxLimitUpdateAsync();
};
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.cashbox-modal-content {
  max-width: 380px;
}

.cashbox-modal-actions  {
  width: 100%;
}
</style>
