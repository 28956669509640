<template>
  <div>
    <VueSlider
      v-model="value"
      class="mt-2"
      :dot-size="15"
      :height="7"
      :adsorb="true"
      :silent="true"
      :data="values"
      :marks="marks"
      :min="Math.floor(min)"
      :max="Math.floor(max)"
      :interval="props.interval"
      :disabled="isDisabled"
      tooltip="none"
      @change="changeSliderAmount"
    />

    <div v-if="min && max" class="min-max-markers">
      <span>{{ formatCurrency(min, 0) + ' ₽' }}</span>
      <span>{{ formatCurrency(max, 0) + ' ₽' }}</span>
    </div>
  </div>
</template>

<script setup>
import VueSlider from 'vue-slider-component';
import { computed } from 'vue';
import { formatCurrency } from '@/utils/commonUtils/libUtils.js';

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: null,
    required: false,
  },
  values: {
    type: Array,
    default: null,
  },
  marks: {
    type: Array,
    default: null,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  min: {
    type: [Number, String],
    default: null,
  },
  max: {
    type: [Number, String],
    default: null,
  },
  interval: {
    type: [Number, String],
    default: null,
  },
});

const emit = defineEmits(['update:modelValue', 'change']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const changeSliderAmount = (value) => {
  emit('change', value);
}
</script>

<style lang="scss">
@use '@/scss/variables.scss' as *;

$themeColor: $breakwater !default;
$disabledOpacity: 0.5 !default;
$bgColor: #fff !default;
$railBorderRadius: 9.5px !default;
$dotShadow: none !default;
$dotShadowFocus: none !default;
$dotBgColor: $breakwater !default;
$dotBgColorDisable: #ccc !default;
$dotBorderRadius: 50% !default;
$tooltipBgColor: $themeColor !default;
$tooltipColor: #fff !default;
$tooltipBorderRadius: 5px !default;
$tooltipPadding: 2px 5px !default;
$tooltipMinWidth: 20px !default;
$tooltipArrow: 5px !default;
$tooltipFontSize: 14px !default;
$stepBorderRadius: 50% !default;
$stepBgColor: transparent !default;
$labelFontSize: 14px !default;

.vue-slider {
  box-sizing: content-box;

  /* component style */
  .vue-slider-disabled {
    opacity: $disabledOpacity;
    cursor: not-allowed;
  }

  /* rail style */
  .vue-slider-rail {
    background-color: $bgColor;
    border-radius: $railBorderRadius;
  }

  /* process style */
  .vue-slider-process {
    background-color: $breakwater;
    border-radius: $railBorderRadius;
  }

  /* mark style */
  .vue-slider-mark {
    z-index: 4;

    &:first-child,
    &:last-child {
      .vue-slider-mark-step {
        display: none;
      }
    }

    @at-root &-step {
      width: 100%;
      height: 100%;
      border-radius: $stepBorderRadius;
      background-color: $stepBgColor;
    }

    @at-root &-label {
      font-size: $labelFontSize;
      white-space: nowrap;
    }

    &-label {
      font-size: 14px;
      color: $grey;
    }
  }

  /* dot style */
  .vue-slider-dot {
    @at-root &-handle {
      cursor: pointer;
      width: 100%;
      height: 100%;
      border-radius: $dotBorderRadius;
      background-color: $dotBgColor;
      box-sizing: border-box;
      box-shadow: $dotShadow;

      @at-root &-focus {
        box-shadow: $dotShadowFocus;
      }

      @at-root &-disabled {
        cursor: not-allowed;
        background-color: $dotBgColorDisable;
      }
    }

    @at-root &-tooltip {
      @at-root &-inner {
        font-size: $tooltipFontSize;
        white-space: nowrap;
        padding: $tooltipPadding;
        min-width: $tooltipMinWidth;
        text-align: center;
        color: $tooltipColor;
        border-radius: $tooltipBorderRadius;
        border-color: $tooltipBgColor;
        background-color: $tooltipBgColor;
        box-sizing: content-box;
      }
    }

    @at-root &-tooltip-wrapper {
      opacity: 0;
      transition: all 0.3s;

      @at-root &-show {
        opacity: 1;
      }
    }
  }
}

.min-max-markers {
  display: flex;
  justify-content: space-between;
  color: rgb(129 142 153);
  font-size: 14px;
}
</style>
