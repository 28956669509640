<template>
  <section class="page">
    <template v-if="isLoading">
      <Loader />
    </template>
    <template v-if="!isLoading">
      <div class="page__header potok-text-h1">
        Инвестирование в заем
        <router-link
          v-if="project.id"
          :to="{
            name: 'loan',
            params: { id: project.id },
          }"
          class="title-link"
        >
          {{ project.number }}
        </router-link>
      </div>

      <div v-if="isMobile" class="available-mobile mb-5">
        <div class="available-title potok-text-uppercase">
          Доступно на счете
        </div>
        <div class="available-value potok-text-body-1-bold">
          {{ formatCurrency(getFreeBalance + getCashboxRealAmount, 0) }}&nbsp;₽
        </div>
      </div>

      <div class="content_first content-block content-block--padding mb-7">
        <div class="info">
          <FormField
            :divider="false"
            :name-width="3"
            :value-width="9"
            :name="'Сумма заявки'"
            :value="`${formatCurrency(project.amount, 0)} ₽`"
          />
          <FormField
            :name-width="3"
            :value-width="9"
            :name="'Срок'"
            :value="
              project.term +
              ' ' +
              pluralize(project.term, ['день', 'дня', 'дней'])
            "
          />
          <FormField
            :name-width="3"
            :value-width="9"
            :name="'Ставка'"
            :value="`${formatCurrency(project.rate * 100, 2)}%`"
          />
        </div>
        <div v-if="!isMobile" class="available">
          <div class="available-title potok-text-uppercase">
            Доступно на счете
          </div>
          <MoneyValue
            :value="getFreeBalance + getCashboxRealAmount"
            :rounding="false"
            class="available-value potok-text-body-1-bold"
          />
        </div>
      </div>

      <LoanBanner2kk v-if="isPotokHoldingProject" class="mt-7 mb-7" />

      <div class="content-block content_second content_second--padding mb-7">
        <div class="input-container">
          <label class="label_input mb-5" for="rawSum"
            >сумма инвестирования</label
          >
          <div class="input-wrap">
            <input
              v-if="!store.state.user.isAdminSuper"
              id="rawSum"
              v-model="form.rawSum"
              v-money3="moneyOptions"
              type="text"
              placeholder="0 ₽"
              inputmode="numeric"
              autocomplete="off"
              @keyup="onChangeRawSum"
            />
            <input
              v-if="store.state.user.isAdminSuper"
              id="rawSum"
              v-model="form.rawSum"
              type="number"
              placeholder="0 ₽"
              autocomplete="off"
              @keyup="onChangeRawSum"
            />

            <template v-if="messageForAmountGreaterMaxAmount">
              <div class="mt-2">{{ messageForAmountGreaterMaxAmount }}</div>
            </template>
            <template v-if="layerErrorMinInvestmentAmount.isShown">
              <div
                style="color: red"
                class="mt-2"
                v-html="errorMinInvestmentAmountText"
              />
            </template>

            <div class="input_tips">
              <div class="input_tip">
                <span class="input_tip_title">от </span>
                <PotokButton
                  theme="linkIconUpload"
                  size="link"
                  @click.prevent="setMaxSumInvest(minInvestment)"
                  >{{ formatCurrency(minInvestment, 0) }}
                  ₽
                </PotokButton>
              </div>
              <div v-if="isShowMaxInvestmentAmount" class="input_tip">
                <span class="input_tip_title">до </span>
                <PotokButton
                  theme="linkIconUpload"
                  size="link"
                  @click.prevent="setMaxSumInvest(maxInvestment)"
                >
                  {{
                    formatCurrency(
                      maxInvestment,
                      store.state.user.isAdminSuper ? 2 : 0,
                    ) + ' ₽'
                  }}
                </PotokButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="description">
        <div class="buttons-container mb-5">
          <PotokButton
            size="large"
            :disabled="disableInvestment"
            :loading="loadingPostProjectInvestmentCreate"
            text="инвестировать"
            class="investment-button"
            @click="postProjectInvestmentCreateAsync"
          />
          <PotokButton
            size="large"
            theme="tertiary"
            :disabled="loadingPostProjectInvestmentCreate"
            text="назад в список инвестиций"
            class="return-btn"
            @click="toInvestmentsList"
          />
        </div>
        <div class="bottom-tip potok-text-body-2">
          нажимая «инвестировать», я заверяю о соблюдении
          <PotokTooltip>
            <span class="potok-color-deepdive">лимита инвестирования </span>
            <template #popper>
              Сумма моих инвестиций, сделанных в течение текущего
              календарного года на всех инвестиционных платформах, включая
              данное инвестирование и ручное инвестирование, составит не
              более 600 000 рублей

              <div class="potok-color-grey mt-3">
                Автоинвестирование по стратегиям Без риска, (Альфа)
                Консервативная, Базовая или Агрессивная — не учитывается
              </div>
            </template>
          </PotokTooltip>
          и понимаю, что инвестирование в займы является
          <PotokTooltip>
            <span class="potok-color-deepdive">высокорискованным</span>
            <template #popper>
              Инвестирование в займы позволяет инвесторам получать высокую
              прибыль, однако является высокорискованным финансовым
              инструментом. В частности, существует риск, связанный с
              потерей инвестиций (полностью или частично) и (или)
              невозможностью продажи имущества, в том числе имущественных
              прав, приобретенных в результате инвестирования.
            </template>
          </PotokTooltip>
        </div>
      </div>

      <ModalFull
        v-if="isShowQualificationModal"
        title="достигнут лимит инвестирования"
        class="qualification-modal"
      >
        <template #content>
          <div class="qualification-modal-content">
            <div class="mb-7">
              вы израсходовали лимит в 600 000 рублей, чтобы инвестировать
              больше подтвердите квалификацию
            </div>
            <div class="gray">
              или используйте автоинвестирование по стратегиям Без риска,
              (Альфа) Консервативная, Базовая или Агрессивная — на них
              ограничение не распространяется
            </div>
          </div>
        </template>
        <template #actions>
          <PotokButton
            text="подтвердить квалификацию"
            class="qualification-modal-btn"
            @click="toQualification"
          />
        </template>
      </ModalFull>
      <ModalTwoFactorAuth
        v-if="isShowModalTwoFactorAuth"
        :investment="currentInvestment"
        @close="onCloseModalTwoFactorAuth"
      />
    </template>
  </section>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import MoneyValue from '@/components/_generic/MoneyValue.vue';
import { PotokTooltip, PotokButton } from 'potok-uikit';

import server from '@/server';
import useVuelidate from '@vuelidate/core';
import { Money3Directive as vMoney3 } from 'v-money3';
import validators from '@/validators';
import {
  decimal,
  formatCurrency,
  formatNumber,
  pluralize,
} from '@/utils/commonUtils/utils';
import { Layer } from '@/libs/browser-widgets';
import tracker from '@/tracker';
import storage from '@/utils/localStorage/storage';
import { Loader } from 'potok-uikit';
import FormField from '@/components/_generic/forms/FormField.vue';
import constants from '@/constants';
import ModalTwoFactorAuth from '@/components/_generic/modals/ModalTwoFactorAuth.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

import { useStateStore } from '@/store/stateStore';
import LoanBanner2kk from '@/components/Loan/banners/LoanBanner2kk.vue';
import detectDevice from '@/composables/detectDeviceComposable';

const LIMIT_FOR_NOT_QI = 600000;

const store = useStateStore();
const route = useRoute();
const router = useRouter();
const { isMobile } = detectDevice();

const loadingBalance = ref(false);
const loadingProjectId = ref(false);
const loadingPostProjectInvestmentCreate = ref(false);
const loadingPostProjectInvestmentSign = ref(false);
const project = ref({
  companyFullName: '',
  amount: '0.0000',
  loanRaisedFunds: '0.0000',
  rate: 0,
  term: 0,
  loanManualInvestMaxAmount: 0,
  minInvestmentAmount: 0,
});
const form = ref({
  rawSum: '',
  originalRawSum: null,
});
const layerErrorMinInvestmentAmount = ref(new Layer());
const errorMinInvestmentAmountText = ref('');
const moneyOptions = {
  decimal: '.',
  thousands: ' ',
  prefix: '',
  suffix: '',
  precision: 0,
  masked: true,
  minimumNumberOfCharacters: 0,
  disableNegative: true,
};
const isShowModalTwoFactorAuth = ref(false);
const currentInvestment = ref(null);

const isShowQualificationModal = ref(false);

const validations = {
  form: {
    rawSum: {
      required: validators.required,
      currency: validators.currency,
      minValue: validators.withParams({ type: 'minValue' }, function (v) {
        const n1 = Number(formatNumber(v));
        return isAdmin.value ? n1 > 0 : n1 >= constants.investmentsAmount.MIN;
      }),
      maxValue: validators.withParams({ type: 'maxValue' }, function (v) {
        const n1 = Number(formatNumber(v));
        return n1 <= maxInvestment.value;
      }),
    },
  },
};
const v$ = useVuelidate(validations, { form });

const isPotokHoldingProject = computed(() => {
  return project.value.isPotokHoldingBorrower;
});
const isPotokFinanceProject = computed(() => {
  return project.value.isPotokFinanceBorrower;
});
const isAdmin = computed(() => {
  return store.isAdmin;
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});
const getFreeBalance = computed(() => {
  return store.getFreeBalance;
});
const getCashboxRealAmount = computed(() => {
  return store.getCashboxRealAmount;
});
const isLoading = computed(() => {
  return (
    loadingProjectId.value || loadingBalance.value || !selectedCompany.value
  );
});
const enableInvestment = computed(() => {
  if (hasRestrictionByQualification.value && restrictionBalance.value === 0) {
    return false;
  }
  return true;
});
const isPendingInvestment = computed(() => {
  return loadingPostProjectInvestmentCreate.value;
});
const maxInvestment = computed({
  cache: false,
  get() {
    return project.value && project.value.maxInvestmentAmount
      ? project.value.maxInvestmentAmount
      : 0;
  },
});
const minInvestment = computed(() => {
  if (project.value.minInvestmentAmount) {
    return project.value.minInvestmentAmount;
  } else {
    if (isPotokFinanceProject.value) {
      return 1500000;
    }
    if (isPotokHoldingProject.value) {
      return 1000;
    }
    return 400;
  }
});
const sum = computed(() => {
  const value = String(form.value.originalRawSum).replace(/\s/g, '');
  return Number(value);
});
const hasRestrictionByQualification = computed(() => {
  return (
    selectedCompany.value.typeId === constants.companyTypes.person &&
    selectedCompany.value.qiStatus !== constants.qiStatus.APPROVED
  );
});
const restrictionBalance = computed(() => {
  const companyId = selectedCompany.value.id;
  if (!store.state.balances[companyId]) {
    return;
  }
  const restriction = store.state.balances[companyId].investor.restriction || 0;
  let amount = decimal(LIMIT_FOR_NOT_QI).minus(restriction);

  return amount.toNumber() > 0 ? amount.toNumber() : 0;
});
const investmentGreaterThanRestriction = computed(() => {
  if (hasRestrictionByQualification.value) {
    const amount = Number(formatNumber(form.value.rawSum));
    if (
      !store.state.balances ||
      !store.state.balances[selectedCompany.value.id]
    ) {
      return false;
    }
    const restriction =
      store.state.balances[selectedCompany.value.id].investor.restriction || 0;
    if (amount + restriction <= project.value.maxInvestmentAmount) {
      if (amount + restriction > LIMIT_FOR_NOT_QI) {
        return true;
      }
    }
    return false;
  }
  return false;
});
const isCorrectAmountForPotokHolding = computed(() => {
  const amount = Number(formatNumber(form.value.rawSum));

  return !(amount % 1000);
});
const isCorrectAmountForPotokFinance = computed(() => {
  const amount = Number(formatNumber(form.value.rawSum));

  return !(amount % 1000);
});
const messageForAmountGreaterMaxAmount = computed(() => {
  const amount = Number(formatNumber(form.value.rawSum));
  const availabelForInvestment = decimal(project.value.amount)
    .minus(project.value.loanRaisedFunds)
    .toNumber();

  if (!store.state.balances[selectedCompany.value.id]) {
    return;
  }

  const restriction =
    store.state.balances[selectedCompany.value.id].investor.restriction || 0;

  if (isPotokFinanceProject.value && !isCorrectAmountForPotokFinance.value) {
    return 'сумма инвестирования должна быть кратна 1000 ₽';
  }

  if (isPotokHoldingProject.value && !isCorrectAmountForPotokHolding.value) {
    return 'сумма инвестирования должна быть кратна 1000 ₽';
  }

  if (hasRestrictionByQualification.value) {
    if (amount > LIMIT_FOR_NOT_QI - restriction) {
      return 'Введенная сумма превышает лимит инвестирования для неквалифицированных инвесторов';
    }
  }
  if (amount > availabelForInvestment) {
    return 'Введенная сумма превышает максимально возможную сумму инвестирования';
  }
  return null;
});
const disableInvestment = computed(() => {
  return (
    v$.$invalid ||
    isPendingInvestment.value ||
    form.value.rawSum < minInvestment.value ||
    (isPotokHoldingProject.value && !isCorrectAmountForPotokHolding.value) ||
    (isPotokFinanceProject.value && !isCorrectAmountForPotokFinance.value) ||
    investmentGreaterThanRestriction.value ||
    (hasRestrictionByQualification.value && restrictionBalance.value === 0)
  );
});

const isShowMaxInvestmentAmount = computed(() => {
  return maxInvestment.value >= minInvestment.value;
});

watch(selectedCompany, () => {
  activeBalance();
  getCompanies$IdBalanceAsync();
  getProjectIdAsync();
});
onMounted(() => {
  activeBalance();
  getCompanies$IdBalanceAsync();
  getProjectIdAsync();
});

const makeProjectViewed = () => {
  /**
   * Сохраняем в хранилеще состояние просмотренности займа
   */
  if (!project.value || !project.value.id) {
    return;
  }
  let items = storage.getProjects();
  if (items) {
    items = JSON.parse(items);
  } else {
    items = {};
  }
  items[project.value.id] = true;
  storage.setProjects(items);
};

const setMaxSumInvest = (max) => {
  store.state.user.isAdminSuper
    ? (form.value.rawSum = max)
    : (form.value.rawSum = Math.floor(max));
  form.value.originalRawSum = max;
};
const onChangeRawSum = () => {
  form.value.originalRawSum = form.value.rawSum;
};

const getCompanies$IdBalanceAsync = () => {
  if (selectedCompany.value) {
    const companyId = selectedCompany.value.id;
    loadingBalance.value = true;
    store.fetchBalance({ companyId });
    loadingBalance.value = false;
  }
};
const activeBalance = () => {
  if (selectedCompany.value) {
    loadingBalance.value = true;
    store.fetchActiveBalance({
      companyId: selectedCompany.value.id,
    });
    loadingBalance.value = false;
  }
};
const getProjectIdAsync = () => {
  const params = {
    id: route.params.id,
  };
  let query = null;
  if (selectedCompany.value && selectedCompany.value.id) {
    query = {
      companyId: selectedCompany.value.id,
    };
  }
  loadingProjectId.value = true;
  return server.getProjectIdNew
    .send(query, { params })
    .pipe(onGetProjectIdAsyncSuccess)
    .exec();
};
const onGetProjectIdAsyncSuccess = ({ data }) => {
  loadingProjectId.value = false;
  Object.assign(project.value, data);

  makeProjectViewed();
};
const postProjectInvestmentCreateAsync = () => {
  layerErrorMinInvestmentAmount.value.close();

  if (!enableInvestment.value) {
    isShowQualificationModal.value = true;
    return;
  }

  tracker.queue(tracker.commands.SEND, 'Investor_LK_Manual_Amount');
  const query = {
    projectId: project.value.id,
    companyId: selectedCompany.value.id,
    amount: sum.value,
  };
  loadingPostProjectInvestmentCreate.value = true;
  return server.postProjectInvestmentCreate
    .send(query)
    .pipe(
      onPostProjectInvestmentCreateAsyncSuccess.bind(this, query),
      onPostProjectInvestmentCreateAsyncError,
    )
    .exec();
};
const onPostProjectInvestmentCreateAsyncSuccess = (query, { data }) => {
  loadingPostProjectInvestmentCreate.value = false;
  const { isSuccessful, investmentId } = data;
  if (!isSuccessful) {
    return;
  }
  const investment = {
    id: investmentId,
    amount: query.amount,
    term: project.value.term,
    number: project.value.number,
  };
  store.merge({
    buffer: {
      investment,
    },
  });

  postProjectInvestmentSignAsync({ investment });
};
const onPostProjectInvestmentCreateAsyncError = (error) => {
  loadingPostProjectInvestmentCreate.value = false;
  const { response, data } = error;
  if (response.status === 423) {
    layerErrorMinInvestmentAmount.value.show();
    errorMinInvestmentAmountText.value = data.message;
    return;
  }

  return Promise.reject(error);
};
const postProjectInvestmentSignAsync = ({ investment }) => {
  tracker.queue(tracker.commands.SEND, 'Investor_LK_Manual_Accept');
  const query = {
    investmentId: investment.id,
  };
  loadingPostProjectInvestmentSign.value = true;
  return server.postProjectInvestmentSign
    .send(query)
    .pipe(onPostProjectInvestmentSignAsyncSuccess(this, { investment }), () => {
      loadingPostProjectInvestmentSign.value = false;
    })
    .exec();
};
const onPostProjectInvestmentSignAsyncSuccess = (data, { investment }) => {
  loadingPostProjectInvestmentSign.value = false;
  isShowModalTwoFactorAuth.value = true;
  currentInvestment.value = investment;
};
const onCloseModalTwoFactorAuth = () => {
  isShowModalTwoFactorAuth.value = false;
};
const toInvestmentsList = () => {
  router.push({ name: 'investments' });
};
const toQualification = () => {
  router.push({ name: 'Qualification' });
};

onMounted(() => {
  window.scrollTo(0, 0);
})
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;
@use '../../scss/typography.scss' as *;

.container {
  margin-top: 50px;

  @media (max-width: $size_767) {
    margin-top: 15px;
  }
}

.content-block {
  padding: 20px;
  margin-left: 0;
  margin-right: 0;

  @media (max-width: $size_767) {
    padding: 9px;
  }
}

.content-block--padding {
  padding: 20px;

  @media (max-width: $size_767) {
    padding: 21px 14px 20px;
  }
}

.page__header {
  margin-bottom: 30px;

  @media (max-width: $size_767) {
    @include potok-text-body-1;

    margin-bottom: 20px;
  }
}

.title-link {
  color: $grey;
  text-decoration: none;

  &:hover {
    color: $tiffany;
  }
}

.property-alt {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #5c5c5c;
}

.left-panel {
  padding: 0;
  padding-right: 50px;

  @media (max-width: $size_767) {
    padding-right: initial;
  }
}

.input_tips {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;

  @media (max-width: $size_767) {
    max-width: none;
  }

  .input_tip_title {
    color: #999;
  }
}

.label_input {
  color: $grey;

  @media (max-width: $size_767) {
    margin-bottom: 10px;
  }
}

.input-wrap {
  width: auto;

  input {
    outline: none;
    width: 100%;
    padding: 10px 14px 12px;
    border: none;
    font-size: 20px;

    @include potok-text-body-2;

    background-color: $white;
    border-radius: 8px;
    box-shadow: none;

    @media (max-width: $size_767) {
      width: 100%;

      @include potok-text-body-1;

      border: 1px solid $input;
    }

    &::placeholder {
      color: $grey;
    }
  }

  .input_tip {
    margin-top: 8px;
  }
}

.block_left {
  min-height: 136px;
}

.block_right {
  @media (max-width: $size_767) {
    margin-top: 16px;
    height: 136px;
  }

  .content-block {
    @media (max-width: $size_767) {
      padding: 20px;
    }
  }
}

.content_first {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: $size_767) {
    margin: 0 0 20px;
  }
}

.content_second {
  @media (max-width: $size_767) {
    margin: 0 0 20px;
  }
}

.content_second--padding {
  padding: 20px;

  @media (max-width: $size_767) {
    padding: 20px 14px;
  }
}

.description {
  display: flex;
  flex-direction: column;
}

.buttons-container {
  display: flex;

  @media (max-width: $size_767) {
    flex-direction: column;
  }
}

.expected_income {
  padding: 0;

  @media (max-width: $size_767) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 36px;
  }

  .expected_income_values {
    display: flex;
    align-items: center;
    padding-top: 12px;

    @media (max-width: $size_767) {
      padding-top: initial;
    }

    .expected_income_value {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #313132;

      @media (max-width: $size_767) {
        margin-right: 5px;
      }
    }
  }
}

.content_right_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-wrapper {
  height: 13px;

  @media (max-width: $size_991) {
    height: 20px;
  }

  @media (max-width: $size_767) {
    height: initial;
  }
}

.available {
  height: 100%;
  text-align: right;
  padding: 16px 20px;
  background: $background;
  border-radius: 8px;
  border-right: 3px solid $breakwater;
}

.available-title {
  color: $grey;
}

.info {
  width: calc(100% - 250px);

  @media (max-width: $size_767) {
    width: 100%;
  }
}

.input-container {
  padding: 20px;
  background: $background;
  border-radius: 14px;

  @media (max-width: $size_767) {
    padding: 0;
    background: none;
  }
}

.investment-button {
  margin-right: 20px;

  @media (max-width: $size_767) {
    margin-right: 0;
  }
}

.available-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  background: $dividers;
  border-radius: 8px;
  border-left: 3px solid $breakwater;
}

:deep(.item_value) {
  @media (max-width: $size_767) {
    @include potok-text-h2;
  }
}

:deep(.item_name) {
  @media (max-width: $size_767) {
    @include potok-text-uppercase;
  }
}

.return-btn {
  @media (max-width: $size_767) {
    margin-top: 20px;
  }
}

.bottom-tip {
  color: $grey;
}

.qualification-modal-content {
  text-align: center;
  max-width: 420px;

  @media (max-width: $size_767) {
    max-width: 100%;
    text-align: left;
  }
}

.qualification-modal {
  :deep(.modal_block_title) {
    justify-content: center;
  }

  :deep(.modal_block_container) {
    width: auto;
  }
}

.qualification-modal-btn {
  margin: 0 auto;
}
</style>
