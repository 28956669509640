<template>
  <div class="balance_item">
    <div class="balance_title_wrapper">
      <i class="icon_myinvestment_trendup"></i>
      <div class="balance_tag">
        <div>
          <div style="display: inline-block" @click.prevent="">
            <PotokTooltip>
              <button>
                +
                {{ formatCurrency(promoIncome, 0) }}
                ₽ <span class="text_bonus">бонусы</span>
              </button>
              <template #popper>
                Начисления по акциям, за весь период участия.*
                <br />
                *для инвесторов-физических лиц суммы указаны за вычетом
                НДФЛ.
              </template>
            </PotokTooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="balance_title">Полученный доход</div>
    <template v-if="loadingCompanyPromoIncome">
      <Loader style="margin-top: 10px" />
    </template>
    <template v-if="!loadingCompanyPromoIncome">
      <div class="balance_value">
        <div style="display: inline-block" @click.prevent="">
          <PotokTooltip>
            <MoneyValue
              :value="statIncome"
              :rounding="false"
              color="#04CCD9"
              comma-color="#04CCD9"
              class="potok-text-h2"
            />
            <template #popper>
              Сумма процентов, пени и выплат по цессии, полученная за весь период инвестирования*. Детали вы можете увидеть в
              <router-link :to="{ name: 'analyticsHistoryOperation' }">истории операций</router-link>.
              <br />
              *для инвесторов-физических лиц суммы указаны за вычетом НДФЛ.
            </template>
          </PotokTooltip>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import mixins from '@/mixins';
import { formatCurrency } from '@/utils/commonUtils/libUtils';
import { Loader, PotokTooltip } from 'potok-uikit';
import MoneyValue from '@/components/_generic/MoneyValue.vue';

export default {
  components: {
    MoneyValue,
    Loader,
    PotokTooltip,
  },
  mixins: [mixins.common],
  props: ['statIncome', 'promoIncome', 'loadingCompanyPromoIncome'],
  methods: {
    formatCurrency,
  },
};
</script>
<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.icon_myinvestment_trendup {
  flex-shrink: 0;
  margin-right: 10px;
}

.text_bonus {
  @media (max-width: $size_991) and (min-width: $size_767) {
    display: none;
  }
}

.balance_title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance_value {
  color: $tiffany;
}

.balance_tag {
  background: rgb(4 204 217 / 10%);

  div {
    color: $tiffany;
  }
}
</style>
