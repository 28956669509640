// import Login from '@/pages/Login.vue';
import DashboardPage from '@/pages/DashboardPage.vue';

import AutoInvestPage from '@/pages/AutoInvestPage.vue';
import AutoInvestPersonal from '@/pages/AutoInvest/AutoInvestPersonal.vue';

import SubscriptionPage from '@/pages/SubscriptionPage.vue';
import DocumentsPage from '@/pages/DocumentsPage.vue';
import QualificationPage from '@/pages/QualificationPage.vue';
import NotificationsPage from '@/pages/NotificationsPage.vue';
import MoneyOutPage from '@/pages/MoneyOutPage.vue';
import TransferPage from '@/pages/TransferPage.vue';
import RefillPage from '@/pages/RefillPage.vue';
import InvestorAddPage from '@/pages/InvestorAddPage.vue';
import CallbacksPage from '@/pages/CallbacksPage.vue';
import AlfaCallbackPage from '@/pages/AlfaCallbackPage.vue';
import PotokLoansPage from '@/pages/PotokLoansPage.vue';
import AdminCallback from '@/pages/AdminCallback.vue';
import MoreThenDepositPage from '@/pages/MoreThenDepositPage.vue';
import RegistrationPage from '@/pages/RegistrationPage.vue';
import RecoveryPage from '@/pages/RecoveryPage.vue';
import LoginPage from '@/pages/LoginPage.vue';

export default [
  {
    path: '/',
    name: 'main',
    components: {
      content: DashboardPage,
      unauth: LoginPage,
    },
    meta: {
      title: 'Рабочий стол',
      alwaysAvailable: true,
    },
  },
  {
    path: '/registration',
    name: 'registration',
    components: {
      unauth: RegistrationPage,
    },
    meta: {
      canWhenOnBoarding: true,
      alwaysAvailable: true,
      title: 'Регистрация',
    },
  },
  {
    path: '/recovery',
    name: 'recovery',
    components: {
      unauth: RecoveryPage,
    },
    meta: {
      canWhenOnBoarding: true,
      alwaysAvailable: true,
      title: 'Восстановления пароля',
    },
  },
  {
    path: '/auto-invest',
    name: 'autoInvest',
    components: {
      content: AutoInvestPage,
    },
    meta: {
      title: 'Настройка АИ',
    },
  },
  {
    path: '/auto-invest/personal',
    name: 'autoInvestPersonal',
    components: {
      content: AutoInvestPersonal,
    },
    meta: {
      title: 'Настройка АИ',
    },
  },
  {
    path: '/subscription',
    name: 'subscription',
    components: {
      content: SubscriptionPage,
      unauth: SubscriptionPage,
    },
    meta: {
      alwaysAvailable: true,
    },
  },
  {
    path: '/documents',
    name: 'documents',
    components: {
      content: DocumentsPage,
      unauth: DocumentsPage,
    },
    meta: {
      alwaysAvailable: true,
    },
  },
  {
    path: '/qualification',
    name: 'Qualification',
    components: {
      content: QualificationPage,
    },
    meta: {
      title: 'Квалификация инвестора',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    components: {
      content: NotificationsPage,
    },
    meta: {
      canWhenOnBoarding: true,
      title: 'Уведомления',
    },
  },
  {
    path: '/moneyout',
    name: 'moneyout',
    components: {
      content: MoneyOutPage,
    },
    meta: {
      title: 'Страница вывод средств',
    },
  },
  {
    path: '/transfer',
    name: 'transfer',
    components: {
      content: TransferPage,
    },
    meta: {
      title: 'Перевод между счетами',
    },
  },
  {
    path: '/refill',
    name: 'refill',
    components: {
      content: RefillPage,
    },
    meta: {
      title: 'Пополнение счета',
    },
  },
  {
    path: '/investor-add',
    name: 'investorAdd',
    components: {
      content: InvestorAddPage,
    },
    meta: {
      title: 'Добавление инвестора',
    },
  },
  {
    path: '/callbacks',
    name: 'callbacks',
    components: {
      content: CallbacksPage,
      unauth: CallbacksPage,
    },
    meta: {
      alwaysAvailable: true,
      title: 'Обработка данных',
    },
  },
  {
    path: '/alfa-callback',
    name: 'alfaCallback',
    components: {
      content: AlfaCallbackPage,
      unauth: AlfaCallbackPage,
    },
    meta: {
      alwaysAvailable: true,
      title: 'Обработка данных',
    },
  },
  {
    path: '/admin-callback',
    name: 'adminCallback',
    components: {
      content: AdminCallback,
      unauth: AdminCallback,
    },
    meta: {
      alwaysAvailable: true,
      title: 'Обработка данных',
    },
  },
  {
    path: '/potok-loans',
    name: 'potokLoans',
    components: {
      content: PotokLoansPage,
      unauth: PotokLoansPage,
    },
    meta: {
      alwaysAvailable: true,
      title: 'Займы Поток Холдинг',
    },
  },
  {
    path: '/more-then-deposit',
    name: 'moreThenDeposit',
    components: {
      content: MoreThenDepositPage,
    },
    meta: {
      title: 'Больше, чем депозит',
    },
  },
];
