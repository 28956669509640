<template>
  <PotokPage title="больше, чем депозит">
    <div class="potok-text-body-1 mb-7">
      это форма займов, где инвесторы могут вложить средства под гарантированный процент, 
      выше ключевой ставки, вложенные деньги используются платформой для реинвестирования
    </div>

    <PotokContainer class="content">
      <div class="content-config">
        <MoreThenDepositMoneySlider @change="updateInvestmentAmount"/>
        <MoreThenDepositPeriodSlider @change="updateInvestmentPeriod"/>

        <MoreThenDepositSwitcher @change="switcherHandler"/>
      </div>

      <div class="content-info">
        <MoreThenDepositTerms
          :investmentPeriod="investmentPeriod"
          :investmentAmount="investmentAmount"
          :profit="profit"
          :annualRate="annualRate"
          class="mb-5"
          @invest="doInvest"
        />
        <MoreThenDepositBanner @open-modal="toggleModal(true)"/>
      </div>
    </PotokContainer>

    <div class="potok-text-h1 mt-10">
      активные заявки 
    </div>

    <MoreThenDepositTable
      v-if="isDesktop"
      :applications="applications"
      :total=applications.lenght
      class="mt-7"
      @cancel="console.log('cancel')"
    />

    <MoreThenDepositCardList
      v-if="!isDesktop"
      :applications="applications"
      class="mt-5"
      @cancel="console.log('cancel')"
    />

    <MoreThenDepositModalOffer v-if="isShowModal" @close="toggleModal(false)" />
  </PotokPage>
</template>

<script setup>
import { ref, computed } from 'vue';
import { PotokPage, PotokContainer } from 'potok-uikit';
import MoreThenDepositSwitcher from '@/components/MoreThenDeposit/MoreThenDepositSwitcher.vue';
import MoreThenDepositMoneySlider from '@/components/MoreThenDeposit/MoreThenDepositMoneySlider.vue';
import MoreThenDepositPeriodSlider from '@/components/MoreThenDeposit/MoreThenDepositPeriodSlider.vue';
import MoreThenDepositBanner from '@/components/MoreThenDeposit/MoreThenDepositBanner.vue';
import MoreThenDepositModalOffer from '@/components/MoreThenDeposit/modals/MoreThenDepositModalOffer.vue';
import MoreThenDepositTable from '@/components/MoreThenDeposit/MoreThenDepositTable.vue';
import MoreThenDepositTerms from '@/components/MoreThenDeposit/MoreThenDepositTerms.vue';
import MoreThenDepositCardList from '@/components/MoreThenDeposit/MoreThenDepositCardList.vue';

import detectDevice from '@/composables/detectDeviceComposable';
const { isDesktop } = detectDevice();

const isShowModal = ref(false);

const applications = ref([
  {
    id: 13434,
    loanRefundedAt: '2024-08-07T08:37:20.178Z',
    remainingMainDebtAmount: 1000000,
    returnAmount: 100000,
    profit: 50000,
    irr: 0.12,
  },
  {
    id: 24345,
    loanRefundedAt: '2025-08-07T08:37:20.178Z',
    remainingMainDebtAmount: 4000000,
    returnAmount: 300000,
    profit: 60000,
    irr: 0.12,
  },
]);

const paymentPeriod = ref('1');
const investmentPeriod = ref(3);
const investmentAmount = ref(50000);

const annualRate = ref(0.12);

const profit = computed(() => {
  return (investmentAmount.value * annualRate.value * (investmentPeriod.value * 30)) / 365
})

const toggleModal = (value) => {
  isShowModal.value = value;
}

const updateInvestmentAmount = (newValue) => {
  investmentAmount.value = newValue;
}

const updateInvestmentPeriod = (newValue) => {
  investmentPeriod.value = newValue;
}

const switcherHandler = (newValue) => {
  paymentPeriod.value = newValue;
}

const doInvest = () => {
  console.log('invest');
}
</script>

<style lang="scss" scoped>
 @use '../scss/variables.scss' as *;

 .content {
  display: flex;

  @media (max-width: $size_991) {
    flex-direction: column;
  }
 }

 .content-config {
  width: 75%;

  @media (max-width: $size_991) {
    width: 100%;
  }
 }

.content-info {
  margin-left: 20px;
  width: 25%;

  @media (max-width: $size_991) {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
  }
}
</style>
