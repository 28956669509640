<template>
  <div>
    <ModalFull
      v-if="errorMessage"
      :title="hasDetails ? error.message : 'внимание'"
      class="error_message"
      @close="close"
    >
      <template #content>
        <div
          class="modal-text"
          :class="{ 'text-left': hasDetails }"
          v-html="errorMessage"
        ></div>
      </template>
      <template #actions>
        <div class="button-center">
          <PotokButton
            class="button_ok"
            @click.stop="close"
          >
            {{ 'понятно' }}
          </PotokButton>
        </div>
      </template>
    </ModalFull>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { PotokButton } from 'potok-uikit';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();
const errorMessage = ref(null);

const error = computed(() => store.state.error);

const hasDetails = computed(() => error.value.details && error.value.message);

const errorHandle = () => {
  errorMessage.value = getErrorMessage(error.value);
};

const getErrorMessage = (errorValue) => {
  if (errorValue.status === 401) {
    return '';
  }
  if (errorValue.status === 429) {
    return 'Вы отправляете запросы слишком часто. Подождите несколько секунд и повторите запрос.';
  }
  if (errorValue.status < 500) {
    if (
      errorValue &&
      errorValue.type &&
      errorValue.type === 'business_logic_error'
    ) {
      return null;
    }
    if (hasDetails.value) {
      return error.value.details;
    }
    return errorValue.message;
  }
  if (errorValue.status >= 500) {
    return `К сожалению, произошла техническая ошибка. <br />
          Наша техническая команда уже занимается ее исправлением. <br />
          Приносим свои извинения за доставленные неудобства.`;
  }
};

const close = () => {
  errorMessage.value = '';
  store.setErrorRequest(null);
};

watch(error, () => {
  if (error.value) {
    errorHandle();
  }
});
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.error_message {
  text-transform: none;
}

:deep(.modal_block_container) {
  max-width: 600px;
}

button {
  min-width: 120px;
}

.modal-text {
  text-align: center;
}

.text-left {
  text-align: left;
}

:deep(.modal_title) {
  text-align: center;
  margin: auto;
}

:deep(ul) {
  padding-left: 16px;
}
</style>
