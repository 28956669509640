<template>
  <div class="more-then-deposit-card-list">
    <PotokCardList
      :items="modifiedApplications"
      :list="applicationsCardList"
    >
      <template #bottom="{ item }">
        <PotokButton
          text="отменить"
          theme="tertiary"
          class="mt-5"
          @click="cancel(item)"
        />
      </template>
    </PotokCardList>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';

import { PotokButton, PotokCardList } from 'potok-uikit';

import { createRateText } from '@/utils/strategies/strategiesUtils';
import {
  formatCurrency,
} from '@/utils/commonUtils/utils';

const props = defineProps(['applications']);
const emits = defineEmits(['cancel']);

const applicationsCardList = [
  {
    label: 'ID займа',
    key: 'id',
  },
  {
    label: 'дата',
    key: 'loanRefundedAt',
  },
  {
    label: 'сумма вложения ',
    key: 'investment',
  },
  {
    label: 'сумма возврата',
    key: 'return',
  },
  {
    label: 'чистый доход',
    key: 'profit'
  },
  {
    label: 'ставка',
    key: 'irr',
  },
]

const modifiedApplications = computed(() => {
  return props.applications.map((application) => {
    return {
      id: application.id,
      loanRefundedAt: getDate(application.loanRefundedAt),
      investment: formatCurrency(application.investment),
      return: formatCurrency(application.return),
      profit: formatCurrency(application.profit),
      irr: getRateText(application.irr),
    }
  })
})

const getDate = (date) => {
  if (!date) return '';
  return `${format(date, 'dd.MM.yyyy')}`
};

const getRateText = (rate, irr) => {
  let text = `${createRateText(rate)}`

  if (irr) {
    text += ` / ${createRateText(irr)}`
  }

  text += '%';

  return text;
};

const cancel = (loan) => {
  emits('cancel', loan);
}
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;
</style>
