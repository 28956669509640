<template>
  <div class="investments-menu">
    <PotokTabsSecondary
      :items="tabs"
      @change-tab="changePage"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import detectDevice from '@/composables/detectDeviceComposable';
import { PotokTabsSecondary } from 'potok-uikit';

const { isMobile } = detectDevice();
const router = useRouter();

const tabs = ref([
  {
    id: 0,
    text: isMobile.value ? 'портфель' : 'мой портфель',
    isActive: true,
    to: 'newInvestmentsMyPortfolio',
  },
  {
    id: 1,
    text: 'заявки на продажу',
    isActive: false,
    to: 'newInvestmentsSellList',
  },
])

const changePage = (item) => {
  tabs.value.forEach((tab) => {
    tab.isActive = false;
  });

  let activeItem = tabs.value.find((tab) => tab.id === item.id);
  activeItem.isActive = true;
  router.push({ name: activeItem.to });
};
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;
@use '../../../scss/typography.scss' as *;

.investments-menu-list {
  text-transform: none;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.investments-menu-list-item {
  margin-right: 20px;

  @media (max-width: $size_991) {
    margin-right: 8px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.investments-menu-item-link {
  text-decoration: none;
  color: $grey;

  &:hover {
    color: $deep_dive;
  }

  @media (max-width: $size_991) {
    @include potok-text-h2;
  }
}

.investments-menu-item-active {
  position: relative;
  color: $deep_dive;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -8px;
    left: 0;
    height: 2px;
    background-color: $deep_dive;
  }
}
</style>
