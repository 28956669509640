import { PotokFeedback } from 'frontend-feedback-voc';
import 'frontend-feedback-voc/dist/index.css';

let potokFeedback;
if (import.meta.env.MODE === 'test') {
  potokFeedback = {
    feedbackHandler: () => {},
  };
} else {
  potokFeedback = new PotokFeedback({
    elId: '#potokFeedback',
    platform: 'lki',
    server: import.meta.env.VITE_APP_FEEDBACK_URL,
  });

  potokFeedback.feedbackHandler = (action) => {
    const store = useStateStore();
    setTimeout(async () => {
      await potokFeedback.init({
        userUUID: String(store.currentUser.id),
        userName: store.currentUser.first_name,
        companyId: store.currentUser.company_id,
      });
      if (!action) {
        return;
      }
      potokFeedback.trigger(action);
    }, 5000);
  };
}
export default potokFeedback;
