<template>
  <section class="dashboard_block investor_title">
    <div class="dashboard_block_content block_content">
      <div class="row_items">
        <div class="col-md-11" style="padding: 0">
          <div data-tg-id="1" class="dashboard-title-tour-first-step">
            <div class="short_name" style="margin-right: 10px">
              {{ shortName }}
            </div>
            <div style="display: flex; flex-wrap: wrap; align-items: center">
              <dashboard-statuses />
            </div>
          </div>
        </div>
        <div
          class="col-md-5"
          style="display: flex; justify-content: flex-end; padding: 0"
        >
          <DashboardTitleWelcomeTour v-if="!isMobile" class="mr-7" />
          <div class="menu_more">
            <div
              class="icon_more dashboard-title-menu-icon"
              :class="{
                icon_more_active: isShowMenuMore,
              }"
              @click="isShowMenuMore = !isShowMenuMore"
            ></div>
            <div v-if="isShowMenuMore" v-on-clickaway="onClickClickAway">
              <ul>
                <li @click="onClickAddInvestor">
                  <i class="icon_add_investor" style="margin-top: 4px"></i>
                  добавить инвестора
                </li>
                <li
                  v-if="isShowInvestorQualification"
                  @click="onClickConfirmQualification"
                >
                  <i class="icon_confirm_status"></i>
                  подтвердить&nbsp;квалификацию
                </li>
                <li
                  v-if="isShowWithdrawMoneyBtn"
                  data-tg-id="6"
                  @click="
                    onClickWithdrawMoney({
                      clientType: constants.clientTypes.investor,
                    })
                  "
                >
                  <i class="icon_withdraw_investor"></i>
                  вывести средства
                </li>
                <li @click="onClickReferral">
                  <PotokImg :src="imageConstants.circlePerson" class="mr-2" />
                  пригласить друзей
                </li>
                <li
                  v-if="store.getAccountList.length >= 2"
                  @click="onClickTransfer"
                >
                  <i class="icon_transfer"></i>
                  перевод между счетами
                </li>
                <li
                  v-if="!selectedCompanyPerson && store.isFirstIncomeAt"
                  @click="onClickBecomePartner"
                >
                  <i class="icon_profile_circle"></i>
                  стать партнером
                </li>
                <li
                  v-if="
                    (store.isAdmin ||
                      store.selectedCompany.isPortfolioSaleAvailable) &&
                    !store.isSecondaryMarketOperator
                  "
                  data-tg-id="5"
                  @click="toInvestmentsSellList"
                >
                  <i
                    v-if="!isInvestmentsLoading"
                    class="icon_sell_investments"
                  />
                  <PotokImg
                    v-if="isInvestmentsLoading"
                    loading-icon
                    loading-height="16"
                    loading-width="16"
                    loading-border="3"
                    loading-theme="dark"
                    class="mr-2"
                  />
                  продать инвестиции
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalFull
      v-if="isShowActiveRequestModal"
      @close="isShowActiveRequestModal = false"
    >
      <template #content>
        <div class="text-center potok-text-h2">
          у вас уже есть активная заявка на продажу
        </div>
        <div
          class="investments-active-request-modal-text text-center potok-text-body-1"
        >
          <router-link
            class="investments-active-request-modal-link"
            :to="{ name: 'sellListItem', params: { id: activeRequest.id } }"
          >
            №{{ activeRequest.number }}
          </router-link>
          по ЛС №{{ selectedCompany.investorAccountNumber }} — дождитесь
          результатов обработки или отмените ее
        </div>
      </template>
      <template #actions>
        <div class="button-center">
          <PotokButton
            @click.stop="isShowActiveRequestModal = false"
          >
            понятно
          </PotokButton>
        </div>
      </template>
    </ModalFull>
  </section>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { PotokButton } from 'potok-uikit';

import constants from '@/constants';
import imageConstants from '@/constants/imageConstants';
import { directive as vOnClickaway } from 'vue3-click-away';
import tracker from '@/tracker';
import { useStateStore } from '@/store/stateStore';
import DashboardStatuses from './DashboardTitle/DashboardStatuses.vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import server from '@/server';
import DashboardTitleWelcomeTour from '@/components/Dashboard/DashboardTitle/DashboardTitleWelcomeTour.vue';
import detectDevice from '@/composables/detectDeviceComposable';

const { isMobile } = detectDevice();

const emit = defineEmits(['invokeShowBecomeParnter']);
const props = defineProps({
  isShowMenuFromTour: {
    type: Boolean,
    required: true,
  },
});
const isShowMenuMore = ref(false);

const hasActiveRequest = ref(false);
const activeRequest = ref(null);
const isShowActiveRequestModal = ref(false);

const isInvestmentsLoading = ref(false);

const store = useStateStore();
const router = useRouter();

const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const selectedCompany = computed(() => {
  return store.selectedCompany;
});

const selectedCompanyPerson = computed(() => {
  return store.selectedCompany.typeId === constants.COMPANY_TYPES_PERSON;
});

const firstIncomeAt = computed(() => {
  return store.state.user.firstIncomeAt;
});
const companies = computed(() => {
  if (store.state.dictionaries) {
    return store.state.dictionaries.companies;
  }
  return [];
});

const shortName = computed(() => {
  if (selectedCompany.value) {
    return selectedCompany.value.shortName;
  }
  return null;
});

const isShowWithdrawMoneyBtn = computed(() => {
  return isUserLoaded.value && companies.value && companies.value.length > 0;
});
const isShowInvestorQualification = computed(() => {
  return store.isEnableQualifiaction;
});

watch(isShowMenuMore, () => {
  if (isShowMenuMore.value) {
    disableMobileScroll();
  } else {
    enableScroll();
  }
});

const toInvestmentsSellList = () => {
  getInvestmentsForSale();
};

const getRequestsList = () => {
  const query = {
    sort: 'number',
    order: 'DESC',
    limit: 20,
    offset: 0,
    status: 'confirmed',
  };

  query.companyId = store.selectedCompanyId;

  return server.getInvestmentsSellListNotAdmin
    .send(query)
    .pipe(onFetchInvestmentsSellListDesktopSuccess, () => {})
    .exec();
};
const onFetchInvestmentsSellListDesktopSuccess = (obj) => {
  hasActiveRequest.value = !!obj.data.count;
  if (obj.data?.rows.length) {
    activeRequest.value = obj.data.rows[0];
  }
};

const getInvestmentsForSale = () => {
  isInvestmentsLoading.value = true;

  const query = {
    companyId: store.selectedCompanyId,
  };

  return server.getInvestmentsForSale
    .send(query)
    .pipe(onFetchInvestmentsForSaleSuccess, () => {
      isInvestmentsLoading.value = false;
    })
    .exec();
};

const onFetchInvestmentsForSaleSuccess = () => {
  isInvestmentsLoading.value = false;
  isShowMenuMore.value = false;

  if (hasActiveRequest.value) {
    isShowActiveRequestModal.value = true;
    return;
  }
  router.push({ name: 'newSellRequest' });
};

const init = () => {
  if (selectedCompany.value) {
    getRequestsList();
  }
};
init();

const enableScroll = () => {
  document.body.style.overflow = null;
};

const disableMobileScroll = () => {
  if (document.documentElement.clientWidth <= 767) {
    document.body.style.overflow = 'hidden';
  }
};

const onClickClickAway = () => {
  isShowMenuMore.value = false;
};
const onClickAddInvestor = () => {
  isShowMenuMore.value = false;
  if (!firstIncomeAt.value) {
    return store.pushAlert({
      message: constants.messages.finishReg,
      title: 'Внимание!',
    });
  }
  router.push({
    name: 'investorAdd',
  });
};
const onClickWithdrawMoney = ({ clientType }) => {
  isShowMenuMore.value = false;
  tracker.queue(
    tracker.commands.SEND,
    'Investor_LK_My_Account_Withdraw_Button',
  );

  store.merge({
    preferences: {
      clientType,
    },
  });
  router.push({
    name: 'moneyout',
  });
};
const onClickConfirmQualification = () => {
  isShowMenuMore.value = false;
  router.push({
    name: 'Qualification',
  });
};
const onClickReferral = () => {
  store.state.modals.isShowModalRefferal = true;
};
const onClickTransfer = () => {
  isShowMenuMore.value = false;
  router.push({
    name: 'transfer',
  });
};
const onClickBecomePartner = () => {
  emit('invokeShowBecomeParnter');
};

watch(selectedCompany, () => {
  if (selectedCompany.value) {
    getRequestsList();
  }
});
watch(
  () => props.isShowMenuFromTour,
  () => {
    isShowMenuMore.value = props.isShowMenuFromTour;
  },
);
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.dashboard_block.investor_title {
  .dashboard_block_content {
    padding: 28px 20px 30px;

    @media (max-width: $size_767) {
      padding: 10px 20px;
    }
  }
}

.row_items {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $size_767) {
    align-items: center;
  }
}

.account_number {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999;

  @media (max-width: $size_767) {
    font-size: 10px;
    line-height: 24px;
    margin-bottom: 0;
  }
}

.short_name {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: $deep_dive;
  text-transform: initial;

  @media (max-width: $size_767) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 280px;
  }
}

.icon_more {
  border-radius: 6px;
  cursor: pointer;
}

.icon_more_active {
  border: 1px solid #173b57;
}

.menu_more {
  position: relative;

  ul {
    list-style-type: none;
    position: absolute;
    padding: 0;
    top: 28px;
    right: 0;
    background: #fff;
    box-shadow: 0 2px 22px rgb(13 39 65 / 12%);
    border-radius: 12px;
    z-index: 99;
    min-width: 310px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $deep_dive;

    @media (max-width: $size_767) {
      right: 10px;
      left: 10px;
      position: fixed;
      top: 130px;
    }

    li {
      padding: 10px 20px 10px 10px;
      display: flex;
      align-items: center;
      margin: 10px;
      border-radius: 8px;
      cursor: pointer;
      min-height: 44px;

      &:hover {
        background-color: $background;
      }

      i {
        margin-right: 10px;
      }
    }
  }
}

.text-center {
  text-align: center;
}

.investments-active-request-modal-text {
  max-width: 315px;
  margin: 20px auto 0;
}

.investments-active-request-modal-link {
  color: $tiffany;
}

.dashboard-title-tour-first-step {
  width: 420px;

  @media (max-width: $size_767) {
    width: 100%;
  }
}
</style>
