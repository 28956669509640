<template>
  <PotokContainer background="#F2F4F8" class="more-then-deposit-banner">
    <div class="image-container" />

    <div class="potok-text-h2 potok-color-breakwater mb-8">не нравится<br>ставка?</div>
    <PotokButton
      text='предложить свою →'
      theme='linkIconUpload'
      size='link'
      no-full-width-tablet
      class="banner-btn potok-text-body-2"
      @click="openModal"
    />
  </PotokContainer>
</template>

<script setup lang="js">
import { PotokContainer, PotokButton } from 'potok-uikit';

const emits = defineEmits(['openModal']);

const openModal = () => {
  emits('openModal');
}
</script>

<style lang="scss" scoped>
.more-then-deposit-banner {
  position: relative;
}

.image-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url('@img/banners/more-then-deposit-banner.png');
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}
</style>