<template>
  <div class="modal_overlay" @click.stop="closeOutside">
    <div class="modal_shadow" @click.stop="closeOutside"></div>
    <button
      v-if="!isMobile"
      type="button"
      class="modal_desktop_close"
      @click.stop="close"
    >
      <div class="icon_close_white"></div>
    </button>
    <div
      class="modal_block_container"
      :class="{ 'width-auto': props.widthAuto }"
      @click="$event.stopPropagation()"
    >
      <div class="modal_block_content">
        <div
          v-if="!isMobile"
          class="modal_block_title"
          :class="{ 'title-center': props.titleCenter }"
        >
          <div class="modal_title">
            {{ props.title }}
          </div>
        </div>
        <div
          v-if="isMobile"
          class="modal_mobile_block_title"
          @click.stop="clickaway"
        >
          <button
            v-if="isMobile"
            type="button"
            class="modal_close_back"
            @click.stop="close"
          >
            <div class="icon_back_mob"></div>
            Назад
          </button>
          <div class="modal_title">{{ props.title }}</div>
        </div>
        <slot name="content"></slot>
      </div>
      <div class="modal_block_actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import detectDevice from '@/composables/detectDeviceComposable';

const emit = defineEmits(['close', 'clickaway']);
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  widthAuto: {
    type: Boolean,
    default: false,
  },
  closeClickOutside: {
    type: Boolean,
    default: true,
  },
  titleCenter: {
    type: Boolean,
    default: false,
  },
});
const { isMobile } = detectDevice();

const close = () => {
  return emit('close');
};

const closeOutside = () => {
  if (props.closeClickOutside) {
    return emit('close');
  }
};

const clickaway = () => {
  return emit('clickaway');
};
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.modal_overlay {
  position: fixed;
  inset: 0;
  text-align: center;
  white-space: nowrap;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_shadow {
  position: fixed;
  inset: 0;
  background-color: #10273d;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  outline: 0;
}

.modal_block_container {
  width: 100%;
  position: fixed;
  overflow-y: auto;
  padding: 33px 50px;
  min-width: 200px;
  max-width: 700px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 90%;
  border-radius: 14px;
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  @media (max-width: $size_767) {
    padding: 16px;
    height: 100dvh;
    min-width: 200px;
    max-width: initial;
    max-height: initial;
    border-radius: initial;
  }
}

.modal_block_actions {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.modal_block_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  text-transform: lowercase;
}

.modal_mobile_block_title {
  margin-bottom: 20px;
}

.modal_desktop_close {
  position: absolute;
  top: 43px;
  right: 43px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 28px;
  line-height: 22px;
  color: $grey;
}

.modal_close_back {
  border: 0;
  background: $background;
  border-radius: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $deep_dive;
  padding: 4px 10px;
  display: flex;
  align-items: center;

  .icon_back_mob {
    margin-right: 3px;
  }
}

.modal_title,
:deep(.modal_title) {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: $deep_dive;
  text-transform: none;

  @media (max-width: $size_767) {
    margin-top: 20px;
  }
}

.title-center {
  justify-content: center;
  text-align: center;
}

.width-auto {
  width: auto;

  @media (max-width: $size_767) {
    width: 100%;
  }
}
</style>
