<template>
  <PotokContainer background="#F2F4F8" class="mb-5">
    <div class="potok-color-grey mb-4">
      выберите сумму инвестиций
    </div>

    <CustomParamsInputMoney
      v-model="investmentSum"
      :disabled="false"
      :min="50000"
      :max="10000000"
    />

    <PotokRangeSlider
      v-model="investmentSum"
      min="50000"
      max="10000000"
      :interval="500"
    />
  </PotokContainer>
</template>

<script setup>
import { ref, watch } from 'vue';
import { PotokContainer, CustomParamsInputMoney } from 'potok-uikit';
import PotokRangeSlider from '@/components/_generic/PotokRangeSlider.vue';

const investmentSum = ref(50000);

const emits = defineEmits(['change']);

const changeInvestmentSum = (value) => {
  emits('change', value);
}

watch(investmentSum, (newValue) => {
  changeInvestmentSum(newValue);
})
</script>