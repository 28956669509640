<template>
  <PotokPage title="вывод средств">
    <div class="money-out-content">
      <div class="money-out-form-container">
        <div class="money-out-form block_content">
          <div class="choose mb-10">
            <div class="money-out-subtitle potok-text-h2">откуда</div>
            <div
              v-if="accountNumber"
              class="potok-text-uppercase potok-color-grey"
            >
              ЛС {{ accountNumber }}
            </div>
            <div
              v-if="store.selectedCompany"
              class="money-out-company potok-text-body-2"
            >
              {{ store.selectedCompany.shortName }}
            </div>
            <p v-else>Нужно выбрать инвестора</p>
            <div class="input-container mt-5">
              <input
                v-model="form.rawSum"
                class="money-out-money mb-1"
                placeholder="0 ₽"
                type="text"
                @keyup="v$.form.rawSum.$touch()"
              />
              <PotokImg
                :src="ImageConstants.clearCircle"
                class="input-clear-icon"
                @click="(form.rawSum = null)"
              />

              <div
                v-if="showError"
                class="form-input-money-error potok-text-body-2"
              >
                {{ inputErrorMessage }}
              </div>
            </div>
            <div class="property potok-text-body-2">
              доступно
              <span class="money-out-value" @click="setMoneyForOut">
                <MoneyValue
                  :value="balance"
                  :rounding="false"
                  color="#04CCD9"
                  comma-color="#04CCD9"
                  style="display: inline"
                />
              </span>
            </div>
          </div>

          <div class="money-out-subtitle potok-text-h2">куда</div>
          <div class="money-out-items">
            <div class="money-out-item">
              <div
                class="account-amount potok-text-uppercase potok-color-grey mb-1"
              >
                Номер счета
              </div>
              <div
                :class="{ 'potok-color-red': v$.form.accountNumber.$invalid }"
                class="form-item"
              >
                <input
                  v-model="form.accountNumber"
                  v-maska
                  :data-maska="masks.accountNumber()"
                  type="text"
                  class="money-out-input form-control"
                  placeholder="Номер счета"
                />
              </div>
            </div>
            <div class="money-out-item">
              <div
                class="account-amount potok-text-uppercase potok-color-grey mb-1"
              >
                БИК
              </div>
              <div
                :class="{ 'potok-color-red': v$.form.bic.$invalid }"
                class="form-item"
              >
                <VueSuggestions
                  id="bic"
                  v-model="form.bic"
                  class="money-out-input form-control"
                  :placeholder="'БИК'"
                  :options="suggestionOptions"
                />
              </div>
            </div>
            <div v-if="form.bic" class="money-out-item">
              <div
                class="account-amount potok-text-uppercase potok-color-grey mb-1"
              >
                К/С
              </div>
              <div
                :class="{ 'potok-color-red': v$.form.corrAccount.$invalid }"
                class="form-item"
              >
                <input
                  v-model="form.corrAccount"
                  type="text"
                  readonly
                  class="money-out-input form-control"
                  placeholder="Корреспондентский счёт"
                />
              </div>
            </div>
            <div v-if="form.bic" class="money-out-item">
              <div
                class="account-amount potok-text-uppercase potok-color-grey mb-1"
              >
                Название банка
              </div>
              <div
                :class="{ 'potok-color-red': v$.form.bankName.$invalid }"
                class="form-item"
              >
                <input
                  v-model="form.bankName"
                  type="text"
                  readonly
                  class="money-out-input form-control"
                  placeholder="Название банка"
                />
              </div>
            </div>
          </div>
          <template
            v-if="
              store.selectedCompanyId &&
              getSelectedCompanyObject() &&
              v$.form.accountNumber.$invalid
            "
          >
            <p class="red">
              <span
                v-show="form.accountNumber && form.accountNumber.length > 0"
              >
                Указан некорректный счет.<br />
                Проверьте номер счета и БИК на опечатки.<br />
              </span>
              <template
                v-if="
                  getSelectedCompanyObject().typeId ===
                  constants.companyTypes.company
                "
                >Номер счета ЮЛ должен начинаться на 4070… или 421…</template
              >
              <template
                v-else-if="
                  getSelectedCompanyObject().typeId ===
                  constants.companyTypes.entrepreneur
                "
                >Номер счета ИП должен начинаться на 40802… или 421…</template
              >
              <template
                v-else-if="
                  getSelectedCompanyObject().typeId ===
                  constants.companyTypes.person
                "
              ></template>
            </p>
          </template>
        </div>
        <div class="buttons-container mt-7">
          <PotokButton
            :disabled="
              isPendingData ||
              v$.form.$invalid ||
              isPendingStarting ||
              v$.selectedCompanyId.$invalid
            "
            size="large"
            text="перевести"
            @click="postCompaniesMoneyoutAnyoneAsync"
          />

          <PotokButton
            :disabled="isPendingData || isPendingStarting"
            size="large"
            text="отмена"
            theme="tertiary"
            class="cancel-btn ml-5"
            @click="toDashboard"
          />
        </div>
      </div>

      <MoneyOutNotice class="money-out-notice" />
    </div>

    <ModalConfirmSMS
      v-if="isShowModalConfirm"
      :loading="loadingCompaniesMoneyoutInvestorConfirm"
      :confirm-function="postCompaniesMoneyoutAnyoneConfirmAsync"
      :error-message="confirmErrorMessage"
      :confirm-message="confirmMessage"
      @close="(isShowModalConfirm = false)"
    />
  </PotokPage>
</template>

<script setup>
import { ref, watch, onMounted, computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import constants from '@/constants';
import ImageConstants from '@/constants/imageConstants';

import useVuelidate from '@vuelidate/core';
import { PotokPage } from 'potok-uikit';
import VueSuggestions from '@/libs/suggestions/vue-suggestions.vue';
import TwoFactorAuthentication from '@/components/_generic/TwoFactorAuthentication';
import { formatNumber } from '@/utils/commonUtils/utils';
import ModalConfirmSMS from '@/components/_generic/modals/ModalConfirmSMS.vue';
import validators from '@/validators';
import masks from '@/utils/masks';
import server from '@/server';
import { vMaska } from 'maska/vue';

import { useStateStore } from '@/store/stateStore';
import MoneyOutNotice from '@/components/MoneyOut/MoneyOutNotice.vue';
import PotokImg from '@/components/_generic/PotokImg';
import MoneyValue from '@/components/_generic/MoneyValue';

const store = useStateStore();
const router = useRouter();

const isShowModalConfirm = ref(false);
const loading = ref(false);
const confirmErrorMessage = ref(null);
const confirmMessage = ref(null);

const loadingCompaniesBankAccounts = ref(false);
const loadingActiveBalance = ref(false);
const loadingCompaniesMoneyoutInvestor = ref(false);
const loadingCompaniesMoneyoutInvestorConfirm = ref(false);
const balance = ref(0);
const bankAccounts = ref([]);
const chosenBankAccount = ref(null);
const form = ref({
  companyTypeId: null,
  rawSum: '',
  accountNumber: '',
  bic: '',
  bankName: '',
  corrAccount: '',
  isLiquidated: null,
});

const transactionId = ref(null);
const authentication = new TwoFactorAuthentication();

const accountNumber = computed(() => {
  if (store.selectedCompany) {
    return store.selectedCompany.investorAccountNumber;
  }
  return null;
});

const isPersonalTransferAllowed = computed(() => {
  if (store.selectedCompany) {
    return store.selectedCompany.isPersonalTransferAllowed;
  }
  return null;
});

const isPendingData = computed(() => {
  return loadingActiveBalance.value || loadingCompaniesBankAccounts.value;
});
const isPendingStarting = computed(() => {
  return loadingCompaniesMoneyoutInvestor.value;
});

const steps = computed(() => {
  return TwoFactorAuthentication.steps;
});
const defaultBankAccount = computed(() => {
  return {
    title: 'Другие реквизиты',
    accountNumber: '',
    bic: '',
    bankName: '',
    corrAccount: '',
    isApproved: true,
  };
});
const suggestionOptions = computed(() => {
  return {
    token: import.meta.env.VITE_APP_THIRD_PARTY_SERVICE_DADATA_TOKEN,
    type: 'BANK',
    scrollOnFocus: false,
    triggerSelectOnBlur: false,
    triggerSelectOnEnter: false,
    addon: 'none',
    formatSelected: (selected) => {
      return selected.data.bic;
    },
    onSelect: onDadataSelect,
  };
});

const accountNumberValidation = validators.accountNumber({
  bic: 'bic',
  companyType: 'companyTypeId',
  corrAccount: 'corrAccount',
  isLiquidated: 'isLiquidated',
});

const baseRulesValidations = {
  selectedCompanyId: {
    required: validators.required,
  },
  balance: {
    minValue: validators.minValue(1),
  },
  form: {
    rawSum: {
      required: validators.required,
      currency: validators.currency,
      numeric: validators.numeric,
      minValue: validators.withParams({ type: 'minValue' }, function (v) {
        const n1 = Number(formatNumber(v));
        return n1 >= 0.01;
      }),
      maxValue: validators.withParams({ type: 'maxValue' }, function (v) {
        const n1 = Number(formatNumber(v));
        return n1 <= balance.value;
      }),
    },
    accountNumber: {
      required: validators.required,
    },
    bic: {
      required: validators.required,
      bic: validators.bic,
    },
    bankName: {
      required: validators.required,
      exactLength: validators.minLength(3),
    },
    corrAccount: {
      required: validators.required,
      exactLength: validators.exactLength(20),
    },
  },
  authentication: {
    smsCode: {
      required: validators.required,
      exactLength: validators.exactLength(6),
      numeric: validators.numeric,
    },
  },
};

const extendedRulesValidation = computed(() => {
  const extendedRules = baseRulesValidations;
  extendedRules.form.accountNumber.accountNumber = accountNumberValidation;
  return extendedRules;
});

const currentRulesValidation = computed(() => {
  return isPersonalTransferAllowed.value
    ? baseRulesValidations
    : extendedRulesValidation.value;
});

let v$ = useVuelidate(currentRulesValidation.value, {
  selectedCompanyId: store.selectedCompanyId,
  balance: balance,
  form: form,
  authentication: authentication,
});

const errorsNames = reactive({
  minValue: 'Должно быть больше',
  minValue400: 'Должно быть больше 400₽',
  minValue1000: 'Должно быть больше 1000₽',
  between: 'Не входит в диапазон',
  currency: 'Неверное количество символов после запятой',
  numeric: 'Можно использовать только числа',
  maxValue: 'Недостаточно средств',
  exactLength: 'Неверное количество символов',
  dividedBy: 'Число должно быть кратно 1000₽',
  required: 'Обязательное поле',
});

const showError = computed(() => {
  return v$.value.form.rawSum && v$.value.form.rawSum.$invalid;
});

const inputErrorMessage = computed(() => {
  let errorMessage = null;
  Object.keys(errorsNames).forEach((key) => {
    if (v$.value.form.rawSum[key] && v$.value.form.rawSum[key].$invalid) {
      errorMessage = errorsNames[key];
    }
  });

  return errorMessage;
});

watch(
  () => {
    return store.selectedCompany;
  },
  () => {
    if (!store.selectedCompany) {
      return;
    }
    updateAsync();
  },
);

watch(isPersonalTransferAllowed, () => {
  window.location.reload();
});

onMounted(() => {
  updateAsync();
});

const onDadataSelect = (suggestion) => {
  form.value.bic = suggestion.data.bic;
  form.value.corrAccount = suggestion.data.correspondent_account;
  form.value.bankName = suggestion.data.name.payment;
};

const setMoneyForOut = () => {
  form.value.rawSum = Math.floor(balance.value * 100) / 100;
};

const getCompaniesBankAccountsAsync = () => {
  form.value.companyTypeId = getSelectedCompanyObject()
    ? getSelectedCompanyObject().typeId
    : null;
  form.value.isLiquidated = getSelectedCompanyObject()
    ? getSelectedCompanyObject().isLiquidated
    : false;
  const query = {
    companyId: store.selectedCompanyId,
  };
  loadingCompaniesBankAccounts.value = true;
  return server.getCompaniesBankAccounts
    .send(query)
    .pipe(onGetCompaniesBankAccountsAsyncSuccess)
    .exec();
};

const onGetCompaniesBankAccountsAsyncSuccess = ({ data }) => {
  loadingCompaniesBankAccounts.value = false;
  bankAccounts.value = [
    ...data.map(attachTitleToBankAccount),
    defaultBankAccount.value,
  ];
  selectBankAccount(bankAccounts.value[0]);
};

const getCompaniesActiveBalanceAsync = () => {
  const params = {
    id: store.selectedCompanyId,
  };
  loadingActiveBalance.value = true;
  return server.getActiveBalance
    .send(null, { params })
    .pipe(onGetActiveBalanceAsyncSuccess, () => {
      loadingActiveBalance.value = false;
    })
    .exec();
};

const onGetActiveBalanceAsyncSuccess = (obj) => {
  loadingActiveBalance.value = false;
  if (!obj || !obj.data) {
    return;
  }
  balance.value = obj.data.free;
};

const updateAsync = () => {
  if (!store.selectedCompanyId) {
    return;
  }
  getCompaniesBankAccountsAsync();
  getCompaniesActiveBalanceAsync();
};

const selectBankAccount = (item) => {
  const { accountNumber, bic, bankName, corrAccount } = item;
  Object.assign(form.value, { accountNumber, bic, bankName, corrAccount });
  chosenBankAccount.value = item;
};

const postCompaniesMoneyoutAnyoneAsync = () => {
  const { accountNumber, bic, rawSum, bankName, corrAccount } = form.value;
  confirmErrorMessage.value = null;
  const query = {
    id: store.selectedCompanyId,
    amount: Number(formatNumber(rawSum)),
    accountNumber,
    bic,
    bankName,
    corrAccount,
  };
  loadingCompaniesMoneyoutInvestor.value = true;
  loading.value = true;
  const action = server.postCompaniesMoneyoutInvestor;
  return action
    .send(query)
    .pipe(onPostCompaniesMoneyoutAnyoneAsyncSuccess)
    .exec();
};

const onPostCompaniesMoneyoutAnyoneAsyncSuccess = ({ data }) => {
  loading.value = false;
  loadingCompaniesMoneyoutInvestor.value = false;
  transactionId.value = data.transactionId;
  isShowModalConfirm.value = true;
  authentication.setStep(steps.value.ACTIVE);
};

const postCompaniesMoneyoutAnyoneConfirmAsync = ({ code }) => {
  const query = {
    id: transactionId.value,
    code: code,
  };
  loadingCompaniesMoneyoutInvestorConfirm.value = true;
  loading.value = true;
  const action = server.postCompaniesMoneyoutInvestorConfirm;
  return action
    .send(query)
    .pipe(onPostCompaniesMoneyoutAnyoneConfirmAsyncSuccess, () => {
      loadingCompaniesMoneyoutInvestorConfirm.value = false;
      loading.value = false;
    })
    .exec();
};

const onPostCompaniesMoneyoutAnyoneConfirmAsyncSuccess = () => {
  loading.value = false;
  loadingCompaniesMoneyoutInvestorConfirm.value = false;
  authentication.setStep(steps.value.DONE);
  isShowModalConfirm.value = false;
  store.fetchBalance({
    companyId: store.selectedCompanyId,
  });

  store.pushTip({
    message:
      'мы отправили средства по указанными вами реквизитами, время зачисления зависит от банка-получателя',
    type: 'success',
  });

  router.push({
    name: 'main',
  });
};

const attachTitleToBankAccount = (item) => {
  const { accountNumber, bic, bankName, corrAccount, isApproved } = item;
  return {
    title: accountNumber,
    accountNumber,
    bic,
    bankName,
    corrAccount,
    isApproved,
  };
};

const getSelectedCompanyObject = () => {
  return store.state.dictionaries.companies.find(
    (item) => item.id === store.selectedCompanyId,
  );
};

const toDashboard = () => {
  router.push({ name: 'main' });
};
</script>
<style lang="scss" scoped>
@use '../scss/variables.scss' as *;
@use '../scss/typography.scss' as *;

.block_content {
  padding: 20px;

  @media (max-width: $size_767) {
    padding: 20px 14px;
  }
}

.money-out-content {
  display: flex;

  @media (max-width: $size_991) {
    flex-direction: column;
  }
}

.money-out-notice {
  width: 30%;

  @media (max-width: $size_991) {
    width: 100%;
    margin-top: 20px;
  }
}

.money-out-form-container {
  width: 70%;
  margin-right: 30px;

  @media (max-width: $size_991) {
    width: 100%;
    margin-right: 0;
  }
}

.money-out-subtitle {
  margin-bottom: 20px;

  @media (max-width: $size_767) {
    margin-bottom: 12px;
  }
}

.money-out-company {
  text-transform: initial;
}

input {
  width: auto;
}

.money-out-items {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $size_991) {
    flex-direction: column;
  }

  .form-item {
    margin-bottom: 0;
  }

  input {
    width: 100%;
  }
}

.money-out-value {
  color: $tiffany;
  cursor: pointer;
}

.no-padding {
  padding-right: 0;
}

.money-out-item {
  padding-right: 20px;
  width: 50%;
  margin-bottom: 20px;

  &:nth-child(2n) {
    padding-right: 0;
  }

  @media (max-width: $size_991) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 16px;
  }
}

.clear-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.button-before-auth {
  text-align: left;
  margin-top: 30px;

  .button {
    padding: 10px 18px;
  }
}

.money-out-input {
  padding: 18px 14px;
  height: auto;
  border: 1px solid $input;
  border-radius: 8px;

  @include potok-text-body-2;
}

.input-container {
  position: relative;
}

.money-out-money {
  padding: 18px 14px;
  height: auto;
  width: 100%;
  border: none;
  color: $deep_dive;
  background: $background;
  border-radius: 8px;

  @include potok-text-body-1-bold;

  &::placeholder {
    color: $deep_dive;
  }
}

.input-clear-icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.property-value {
  background: none;

  &:hover {
    cursor: pointer;
  }
}

p {
  margin: 0;
}

.form-input-money-error {
  width: 300px;
  text-transform: lowercase;
  color: $red;
}

.buttons-container {
  display: flex;

  @media (max-width: $size_767) {
    flex-direction: column;
    margin-top: 20px;
  }
}

.cancel-btn {
  @media (max-width: $size_767) {
    margin-left: 0;
    margin-top: 12px;
  }
}
</style>
