<template>
  <div>
    <div class="step_title">подтвердите личность</div>
    <p class="step_description description" v-html="descText" />
    <div class="additional_actions">
      <template v-if="store.isAlfaCustomer">
        <PotokButton
          class="button_alfa"
          size="large"
          @click="onClickContinue(false)"
        >
          подтвердить через Альфа-Инвестиции
        </PotokButton>
      </template>
      <PotokButton
        :size="isMobile ? 'medium' : 'large'"
        theme="secondary"
        full-width-desktop
        text="подтвердить через Госуслуги"
        class="text-no-transform mt-2"
        @click="toGosuslugi"
      >
        <template #icon>
          <PotokImg
            v-if="!isMobile"
            :src="imageConstants.gosuslugiIcon"
            width="24"
            height="26"
            class="mr-2"
          />
        </template>
      </PotokButton>

      <div class="additional_actions_divider">
        <div class="additional_actions_divider_line"></div>
        <div class="additional_actions_divider_text">или загрузите фото</div>
        <div class="additional_actions_divider_line"></div>
      </div>
    </div>
    <div class="block_images">
      <ImageUpload
        ref="passportPageMain"
        class="first_image"
        :file-id="user.passportPageMainDocumentId"
        :placeholder="'разворот <br>первой страницы'"
        :disabled="loading"
        @selected-file="selectedMainDocumentId"
      />

      <ImageUpload
        ref="passportPageRegistration"
        :file-id="user.passportPageRegistrationDocumentId"
        :placeholder="'страница <br>с пропиской'"
        :disabled="loading"
        @selected-file="selectedRegistrationDocumentId"
      />
    </div>
    <PotokDivider margin-y="20" />
    <div>
      <div class="form-error potok-color-red">
        {{ messageError }}
      </div>
      <PotokButton
        class="button-continue"
        size="large"
        :disabled="loading"
        @click="onClickContinue(true)"
      >
        Продолжить
      </PotokButton>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useStateStore } from '@/store/stateStore';

import server from '@/server';
import ImageUpload from '@/components/_generic/ImageUpload.vue';
import tracker from '@/tracker';
import PotokImg from '@/components/_generic/PotokImg.vue';
import imageConstants from '@/constants/imageConstants';
import detectDevice from '@/composables/detectDeviceComposable';
import { PotokButton } from 'potok-uikit';

const store = useStateStore();
const { isMobile } = detectDevice();
const emit = defineEmits(['updateStep']);
const messageError = ref(null);

const gosuslugiLink = ref('');
const descText = ref('');

const loading = ref(false);
const filenamePassportUserIdentity = ref(null);
const filenamePassportUserRegistration = ref(null);
const passportPageMain = ref();

const user = computed(() => {
  return store.state.user;
});

const toGosuslugi = () => {
  tracker.queue(
    tracker.commands.SEND,
    'Adg_Investory_Shag6_Sbor_dannyh_zagruzil_pasport',
  );
  window.open(gosuslugiLink.value, '_self');
};

const isValidContinue = () => {
  messageError.value = null;
  if (
    !user.value.passportPageMainDocumentId &&
    !filenamePassportUserIdentity.value
  ) {
    messageError.value = 'выберите файл для загрузки первой страницы';
    return false;
  }
  if (
    user.value.passportPageMainDocumentId &&
    !filenamePassportUserIdentity.value
  ) {
    messageError.value = 'выберите файл для новой загрузки первой страницы';
    return false;
  }
  if (
    !user.value.passportPageRegistrationDocumentId &&
    !filenamePassportUserRegistration.value
  ) {
    messageError.value = 'выберите файл для загрузки страницы с пропиской';
    return false;
  }
  if (
    user.value.passportPageRegistrationDocumentId &&
    !filenamePassportUserRegistration.value
  ) {
    messageError.value =
      'выберите файл для новой загрузки страницы с пропиской';
    return false;
  }
  return true;
};

const selectedMainDocumentId = (file) => {
  filenamePassportUserIdentity.value = file;
  messageError.value = null;
};
const selectedRegistrationDocumentId = (file) => {
  filenamePassportUserRegistration.value = file;
  messageError.value = null;
};
const onClickContinue = (isValidate) => {
  if (isValidate && !isValidContinue()) {
    return;
  }
  putOnboardPassports();
};
const putOnboardPassports = () => {
  const query = {};
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (
    filenamePassportUserIdentity.value &&
    filenamePassportUserRegistration.value
  ) {
    query.filenamePassportUserIdentity = filenamePassportUserIdentity.value;
    query.filenamePassportUserRegistration =
      filenamePassportUserRegistration.value;
    headers = {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
    };
  }
  loading.value = true;
  return server.putUserOnboardPassport
    .send(query, {
      headers,
    })
    .pipe(onFetchOnboardStatusSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onFetchOnboardStatusSuccess = (obj) => {
  tracker.queue(
    tracker.commands.SEND,
    'Investor_LK_Onboard_Step_1_UploadPasspord',
  );

  loading.value = false;
  const data = obj.data || {};
  if (data.isSuccessful) {
    tracker.queue(
      tracker.commands.SEND,
      'Adg_Investory_Shag6_Sbor_dannyh_zagruzil_pasport',
    );
    emit('updateStep', {
      step: data.onboardingStatus,
      profileData: data.data,
    });
  }
};

const getEsiaLink = () => {
  loading.value = true;
  return server.getEsiaAuthLink
    .send()
    .pipe(onFetchGetEsiaLinkSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onFetchGetEsiaLinkSuccess = (obj) => {
  gosuslugiLink.value = obj.data.link;
  loading.value = false;
};

const getTranslations = () => {
  loading.value = true;
  const query = {
    source: 'onboarding',
  };
  return server.getTranslations
    .send(query)
    .pipe(onFetchGetTranslationsSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onFetchGetTranslationsSuccess = (obj) => {
  descText.value = obj.data.onboarding.checkAndJoinRules;
  loading.value = false;
};

const init = () => {
  getTranslations();
  getEsiaLink();
};
init();
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.block_content .description {
  text-transform: none;
}

.additional_actions {
  width: 420px;
  margin-top: 30px;

  @media (max-width: $size_767) {
    width: 100%;
  }
}

.button_alfa {
  color: #ee3d48;
  border: 1px solid #ee3d48;
  width: 420px;

  @media (max-width: $size_767) {
    width: 100%;
  }

  &:hover,
  &:active {
    background-color: #ee3d48;
    border-color: #ee3d48;
    color: white;
  }
}

.additional_actions_divider {
  margin: 20px 0;
  display: flex;
  max-width: 420px;
  justify-content: space-between;

  @media (max-width: $size_767) {
    width: 100%;
    max-width: 100%;
  }
}

.additional_actions_divider_line {
  border-bottom: 1px solid #818e99;
  width: 100%;
  padding-bottom: 13px;
  height: 1px;
}

.additional_actions_divider_text {
  margin: 0 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #818e99;
  min-width: 135px;
}

.block_images {
  display: flex;
  padding: 20px;
  background: $background;
  border-radius: 14px;
  width: max-content;
  align-items: center;

  @media (max-width: $size_767) {
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}

.first_image {
  margin-right: 20px;

  @media (max-width: $size_767) {
    margin-right: initial;
    margin-bottom: 20px;
  }
}

.form-error {
  margin-top: -10px;
  margin-bottom: 10px;
}

.button-continue {
  margin-top: 10px;

  @media (max-width: $size_767) {
    max-width: 126px;
  }
}
</style>
