<template>
  <div class="more-then-deposit-terms">
    <div class="potok-text-h2 potok-color-breakwater mb-5">условия</div>
    <div class="terms-list mb-7">
      <div
        v-for="item in modifiedTermsList"
        :key="item.label"
        class="terms-item mb-4"
      >
        <div class="terms-item-label potok-color-grey">{{ item.label }}</div>
        <div class="terms-item-value potok-text-body-2-bold" :class="{ 'potok-color-tiffany': item.isTiffany }">{{ item.value }}</div>
      </div>
    </div>

    <PotokButton
      text="инвестировать"
      fullWidthDesktop
      @click="emits('invest')"
    />
  </div>
</template>

<script setup lang="js">
import { PotokButton } from 'potok-uikit';
import { computed } from 'vue';
import { formatCurrency, pluralize } from '@/utils/commonUtils/libUtils.js';
import { createRateText } from '@/utils/strategies/strategiesUtils';

const emits = defineEmits(['invest']);

const props = defineProps({
  investmentPeriod: {
    type: [Number, null],
    default: 3
  },
  investmentAmount: {
    type: [Number, null],
    required: true,
  },
  profit: {
    type: [Number, null],
    default: null
  },
  annualRate: {
    type: [Number, null],
    default: null
  },
});

const termsList = [
  {
    label: 'срок инвестиции',
    props: 'investmentPeriod',
    formatter: (value) => {
      return `${value} ` + pluralize(value, ['месяц', 'месяца', 'месяцев'])
    },
  },
  {
    label: 'дата погашения',
    props: 'investmentPeriod',
    formatter: (value) => {
      const date = new Date();

      date.setMonth(date.getMonth() + value);

      return date.toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    }
  },
  {
    label: 'сумма вложения',
    props: 'investmentAmount',
    formatter: (value) => {
      return formatCurrency(value, 0) + ' ₽'
    },
  },
  {
    label: 'сумма к возврату',
    props: 'investmentAmount',
    formatter: (value) => {
      return formatCurrency(value + props.profit, 0) + ' ₽'
    },
  },
  {
    label: 'чистый доход',
    props: 'profit',
    formatter: (value) => {
      return formatCurrency(value, 0) + ' ₽'
    },
  },
  {
    label: 'ставка годовых',
    props: 'annualRate',
    isTiffany: true,
    formatter: (value) => {
      return createRateText(value) + '%'
    },
  },
]

const modifiedTermsList = computed(() => {
  return termsList.map((item) => {
    return {
      ...item,
      value: item.formatter(props[item.props])
    }
  })
})
</script>

<style scoped lang="scss">
.terms-item {
  display: flex;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>