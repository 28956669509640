S
<template>
  <div v-if="!loading">
    <div class="dashboard-survey-banner">
      <div class="dashboard-survey-banner-left">
        <div
          class="dashboard-survey-banner-title potok-text-h2"
          v-html="'пройдите опрос, чтобы помочь нам'"
        ></div>

        <div class="dashboard-survey-banner-text mt-2 potok-text-body-1">
          купи акцию компании, получи высокий потенциальный доход
        </div>

        <PotokButton
          :text="'пройти опрос →'"
          size="link"
          theme="link"
          no-full-width-mobile
          class="dashboard-survey-banner-btn mt-8"
          @click="toLink"
        />
      </div>
      <div class="dashboard-survey-banner-right">
        <div class="dashboard-survey-banner-background"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { PotokButton } from 'potok-uikit';
import server from '@/server';

const loading = ref(true);
const texts = ref({
  cardTitle: '',
  cardDescription: '',
  investButtonLabel: '',
  investLink: '',
});

const toLink = () => {
  window.open(
    'https://docs.google.com/forms/d/e/1FAIpQLScq2dNYNRKfIB4mUYNkB8Eg7IJP_7fUE5PldoDSNkMK9es8sQ/viewform',
    '_blank',
  );
};

const getExclusivePotokLoansBanner = () => {
  server.getExclusivePotokLoansBanner
    .send()
    .pipe(
      (data) => {
        texts.value = data.data;
        loading.value = false;
      },
      () => (loading.value = false),
    )
    .exec();
};

const init = () => {
  getExclusivePotokLoansBanner();
};
init();
</script>

<style scoped lang="scss">
@use '../../../scss/variables.scss' as *;
@use '../../../scss/typography.scss' as *;

.dashboard-survey-banner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 15px;
  padding: 30px;
  background-color: #e5e9ee;
  height: 230px;
  text-transform: initial;

  @media (max-width: $size_767) {
    height: initial;
  }
}

.dashboard-survey-banner-left {
  flex: 2;
}

.dashboard-survey-banner-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.dashboard-survey-banner-background {
  width: 151px;
  height: 151px;
  background-image: url('@img/banners/survey-banner.svg');
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: $size_991) {
    width: 78px;
    height: 78px;
  }
}

.dashboard-survey-banner-title {
  @media (max-width: $size_991) {
    @include potok-text-body-2;

    font-weight: 500;
    letter-spacing: -0.01em;
  }
}

.dashboard-survey-banner-text {
  color: $grey;

  @media (max-width: $size_991) {
    @include potok-text-body-2;
  }
}

.dashboard-survey-banner-btn {
  flex-shrink: 0;
  color: $deep_dive !important;
  padding: 0;

  @media (max-width: $size_991) {
    margin-left: 0;
    margin-top: 20px;
  }
}
</style>
