<template>
  <PotokPage>
    <NewInvestmentsNewRequestDesktopProgress
      v-if="!isTablet"
      :current-step-id="currentStepId"
    />
    <WizardMobile v-else :current-step-status="currentStepId" :steps="steps" />

    <NewInvestmentsNewRequestStep1
      v-if="currentStepId === 1"
      class="new-investments-new-request-page-container"
      @change-step="changeStep"
      @show-tour="showTour"
    />

    <NewInvestmentsNewRequestStep2
      v-if="currentStepId === 2"
      class="new-investments-new-request-page-container"
      @back="back"
    />
  </PotokPage>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import WizardMobile from '@/components/_generic/stepWizard/WizardMobile.vue';
import NewInvestmentsNewRequestDesktopProgress from '@/components/Investments/NewInvestmentsNewRequest/NewInvestmentsNewRequestDesktopProgress.vue';
import detectDevice from '@/composables/detectDeviceComposable';
import NewInvestmentsNewRequestStep1 from '@/pages/NewInvestments/NewInvestmentsNewRequest/NewInvestmentsNewRequestStep1.vue';
import NewInvestmentsNewRequestStep2 from '@/pages/NewInvestments/NewInvestmentsNewRequest/NewInvestmentsNewRequestStep2.vue';
import { useRouter } from 'vue-router';
import { useStateStore } from '@/store/stateStore';
import server from '@/server';
import { PotokPage } from 'potok-uikit';
import { TourGuideClient } from "@sjmc11/tourguidejs"

const { isTablet } = detectDevice();
const router = useRouter();
const store = useStateStore();

const tourTexts = ref(null);

// Экземпляр тура
let tg;

const tourSteps = [
  {
    content: '<div class="new-request-tour-1-content"></div>',
    title: '',
    order: 1,
    beforeEnter: () => {
      tg.setOptions({
        dialogClass: 'new-request-tour-1',
      });
      const el = document.querySelector('.tg-dialog');
      if (el?.classList?.contains('new-request-tour-2')) {
        el?.classList?.remove('new-request-tour-2');
      }
      if (el?.classList?.contains('new-request-tour-3')) {
        el?.classList?.remove('new-request-tour-3');
      }
      if (el?.classList?.contains('new-request-tour-4')) {
        el?.classList?.remove('new-request-tour-4');
      }
      if (el?.classList?.contains('new-request-tour-5')) {
        el?.classList?.remove('new-request-tour-5');
      }
      setTimeout(() => {
        tg.refreshDialog();
        tg.activeStep = 0;
      }, 0);
    },
  },
  {
    content: '',
    title: '',
    order: 2,
    beforeEnter: () => {
      tg.setOptions({
        dialogClass: 'new-request-tour-2',
      });
      const el = document.querySelector('.tg-dialog');
      if (el?.classList?.contains('new-request-tour-1')) {
        el?.classList?.remove('new-request-tour-1');
      }
      if (el?.classList?.contains('new-request-tour-3')) {
        el?.classList?.remove('new-request-tour-3');
      }
      setTimeout(() => {
        tg.refreshDialog();
        tg.activeStep = 1;
      }, 0);
    },
  },
  {
    content: '',
    title: '',
    order: 3,
    beforeEnter: () => {
      tg.setOptions({
        dialogClass: 'new-request-tour-3',
      });
      const el = document.querySelector('.tg-dialog');
      if (el.classList.contains('new-request-tour-2')) {
        el.classList.remove('new-request-tour-2');
      }
      if (el.classList.contains('new-request-tour-4')) {
        el.classList.remove('new-request-tour-4');
      }
      setTimeout(() => {
        tg.refreshDialog();
        tg.activeStep = 2;
      }, 0);
    },
  },
  {
    content: '',
    title: '',
    order: 4,
    beforeEnter: () => {
      tg.setOptions({
        dialogClass: 'new-request-tour-4',
      });
      const el = document.querySelector('.tg-dialog');
      if (el.classList.contains('new-request-tour-3')) {
        el.classList.remove('new-request-tour-3');
      }
      if (el.classList.contains('new-request-tour-5')) {
        el.classList.remove('new-request-tour-5');
      }
      setTimeout(() => {
        tg.updatePositions();
      }, 300);
    },
  },
  {
    content: '<div class="new-request-tour-5-content"></div>',
    title: '',
    order: 5,
    beforeEnter: () => {
      tg.setOptions({
        dialogClass: 'new-request-tour-5',
      });
      const el = document.querySelector('.tg-dialog');
      if (el.classList.contains('new-request-tour-4')) {
        el.classList.remove('new-request-tour-4');
      }

      setTimeout(() => {
        tg.refreshDialog();
        tg.activeStep = 4;
      }, 0);
    },
  },
];

const currentStepId = ref(1);
const steps = ref([
  {
    id: 1,
    step: 1,
    titleId: 1,
    title: 'список Инвестиции для продажи',
    nextStepId: 2,
    visiblePassedStepsIds: [],
    visibleNextStepsIds: [2],
  },
  {
    id: 2,
    step: 2,
    titleId: 2,
    title: 'выбор условий',
    nextStepId: 3,
    visiblePassedStepsIds: [1],
  },
]);

const isLoading = ref(true);

const isTourCompleted = computed(() => {
  return store.state.tours.isBuybacksTourCompleted;
});

const getTourStatus = () => {
  isLoading.value = true;

  return server.getInvestmentsTourStatus
    .send()
    .pipe(
      ({ data }) => {
        store.state.tours.isBuybacksTourCompleted =
          data?.data?.investorBuybacks?.isViewed;
        isLoading.value = false;
      },
      () => {
        isLoading.value = false;
      },
    )
    .exec();
};

const postTourStatus = () => {
  const query = {};

  return server.postInvestmentsBuybacksTour
    .send(query)
    .pipe(({ data }) => {
      if (data.isSuccessful) {
        store.state.tours.isBuybacksTourCompleted = true;
      }
    })
    .exec();
};

const getFeaturesBuybacks = () => {
  return server.getFeaturesBuybacks
    .send()
    .pipe(onFetchGetFeaturesBuybacksSuccess, () => {
      isLoading.value = false;
    })
    .exec();
};

const onFetchGetFeaturesBuybacksSuccess = (obj) => {
  isLoading.value = false;
  tourTexts.value = obj.data.onboarding;

  fillTourContentFromServer();
};

const fillTourContentFromServer = () => {
  tourSteps[0].title =
    tourTexts.value?.sellInvestmentsWithoutDelay.fullDescriptionTitle;

  tourSteps[1].title = tourTexts.value?.sellWithLimit.fullDescriptionTitle;
  tourSteps[1].content =
    tourTexts.value?.sellWithLimit.fullDescription +
    '<div class="new-request-tour-2-image-input"></div>' +
    '<div class="new-request-tour-2-image-key" />';

  tourSteps[2].title =
    tourTexts.value?.investmentsSellWithDiscount.fullDescriptionTitle;
  tourSteps[2].content =
    tourTexts.value?.investmentsSellWithDiscount.fullDescription +
    '<div class="new-request-tour-3-percents"></div>' +
    '<div class="new-request-tour-3-current" />';

  tourSteps[3].title =
    tourTexts.value?.applicationReviewDuration.fullDescriptionTitle;
  tourSteps[3].content =
    tourTexts.value?.applicationReviewDuration.fullDescription;

  tourSteps[4].title =
    tourTexts.value?.keepEarnedFeesAfterSale.fullDescriptionTitle;
  tourSteps[4].content =
    tourTexts.value?.keepEarnedFeesAfterSale.fullDescription;
};

const startTour = () => {
  tg = new TourGuideClient({
    targetPadding: 30,
    dialogMaxWidth: '464',
    nextLabel: 'далее',
    prevLabel: 'назад',
    finishLabel: 'понятно',
    steps: tourSteps,
  });
  tg.onAfterExit(() => {
    postTourStatus();
  });
  tg.start();
};

const changeStep = (stepId) => {
  currentStepId.value = stepId;
};

const showTour = () => {
  if (tg) {
    tg.refresh();
    tg.visitStep(0);
  } else {
    startTour();
  }
};

const back = () => {
  currentStepId.value -= 1;
};

watch(
  isLoading,
  () => {
    if (!isLoading.value) {
      if (!isTourCompleted.value && tourTexts.value) {
        setTimeout(() => {
          const el = document.querySelector('.tg-dialog');
          if (!el || el?.style.display !== 'block') {
            startTour();
          }
        }, 1500);
      }
    }
  },
  { immediate: true },
);

const init = () => {
  if (!store.isAdmin && !store.selectedCompany.isPortfolioSaleAvailable) {
    router.push({ name: 'investments' });
  }

  getTourStatus();
  getFeaturesBuybacks();
};
init();
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.new-investments-new-request-page-container {
  padding-top: 30px;

  @media (max-width: $size_991) {
    padding-top: 10px;
  }
}
</style>
