<template>
  <div>
    <div class="login_input_wrapper mt-10 mb-6">
      <PotokInputLarge
        id="phone"
        v-model="localRawPhone"
        v-maska
        :data-maska="localMasks.phone"
        type="tel"
        name="phone"
        placeholder="+7 (999) 999-99-99"
        data-test="login-phone-input"
        @keyup="onKeyUpPhone"
        @keyup.enter="onClickContinue"
        @focus="clearErrors"
      />
      <div v-if="v$.localRawPhone && isShowError" class="text-danger mt-2">
        <div>введите корректный телефон</div>
      </div>
      <PotokInputPassword
        v-if="!v$.localRawPhone.$invalid"
        id="password"
        v-model="localRawPassword"
        :readonly="loading"
        placeholder="пароль"
        class="mt-3"
        data-test="login-password-input-no-otp"
        @keyup="onKeyUpPassword"
        @keyup.enter="onClickContinue()"
        @focus="clearErrors"
      />
      <div v-if="localErrorMessagePassword" class="text-danger mt-2">
        {{ localErrorMessagePassword }}
      </div>
    </div>
    <div class="actions-wrapper mt-4">
      <PotokButton
        :disabled="props.loading"
        size="large"
        text="продолжить"
        class="button-login w-100"
        data-test="login-phone-button"
        @click="onClickContinue"
      />
      <PotokButton
        :disabled="props.loading"
        theme="link"
        text="регистрация"
        class="w-100 mt-6"
        data-test="login-phone-button"
        @click="onClickGoToRegister"
      />
      <PotokButton
        :disabled="props.loading"
        theme="link"
        text="забыли пароль?"
        class="w-100"
        data-test="login-phone-button"
        @click="onClickGoToRecovery"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

import { PotokButton, PotokInputLarge, PotokInputPassword } from 'potok-uikit';
import { vMaska } from 'maska/vue';
import masks from '@/utils/masks';
import tracker from '@/tracker';

const router = useRouter();
const props = defineProps({
  rawPhone: {
    type: String,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  passwordErrorMessage: {
    type: String,
    default: '',
  },
});
const emits = defineEmits(['start-login']);

const localRawPhone = ref(null);
const localRawPassword = ref(null);
const isShowError = ref(false);
const localErrorMessagePassword = ref(null);

const isPhone = helpers.regex(
  /^(\+7[ ]\([0-9]{3}\)[ ])([0-9]{3}-)([0-9]{2}-)[0-9]{2}$/,
);
const validations = {
  localRawPhone: {
    required,
    valid: isPhone,
  },
  localRawPassword: {
    required,
  },
};
const v$ = useVuelidate(validations, { localRawPhone, localRawPassword });

const localMasks = computed(() => {
  return {
    phone: masks.phone(),
  };
});

const onKeyUpPassword = () => {
  v$.value.localRawPassword.$dirty = true;
  localErrorMessagePassword.value = null;
};
const onKeyUpPhone = () => {
  v$.value.localRawPhone.$dirty = true;
  isShowError.value = false;
};

const onClickContinue = () => {
  if (v$.value.localRawPhone.$invalid) {
    isShowError.value = true;
    return;
  }
  if (v$.value.localRawPassword.$invalid) {
    localErrorMessagePassword.value = 'Введите пароль';
    return;
  }

  tracker.queue(
    tracker.commands.SEND,
    'Adg_Investory_Shag2_Avtorizacija_vvel_telefon',
  );
  tracker.generalPostClick({ value: 'a5' });

  return emits('start-login', {
    rawPhone: localRawPhone.value,
    rawPassword: localRawPassword.value,
  });
};

const clearErrors = () => {
  isShowError.value = false;
  localErrorMessagePassword.value = null;
};
const onClickGoToRegister = () => {
  return router.push({ name: 'registration' });
};
const onClickGoToRecovery = () => {
  return router.push({ name: 'recovery' });
};

watch(
  () => {
    return props.passwordErrorMessage;
  },
  () => {
    localErrorMessagePassword.value = props.passwordErrorMessage;
  },
);
watch(
  () => {
    return props.rawPhone;
  },
  () => {
    localRawPhone.value = props.rawPhone;
  },
);
</script>
<style lang="scss" scoped></style>
