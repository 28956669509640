<template>
  <div class="wrapper-app">
    <DebugTeleport v-if="isRunTeleport" />

    <!-- <component :is="currentComponent" /> -->
    <AuthenticatedApp v-if="store.isAuthenticated" />
    <router-view v-if="!store.isAuthenticated" name="unauth" />

    <ErrorHandler />
    <AlertHandler />
    <ConfirmHandler />
  </div>
</template>
<script setup>
/* eslint-disable */
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { isAfter } from 'date-fns';
import { useStateStore } from '@/store/stateStore';
import storage from '@/utils/localStorage/storage';
import { getCookie, deleteCookie } from '@/utils/cookie/cookie';
import server from '@/server/index';
import LoginPage from '@/pages/LoginPage.vue';
import AuthenticatedApp from '@/components/AuthenticatedApp.vue';

import ErrorHandler from '@/components/_generic/modals/ErrorHandler.vue';
import AlertHandler from '@/components/_generic/modals/AlertHandler.vue';
import ConfirmHandler from '@/components/_generic/modals/ConfirmHandler.vue';
import DebugTeleport from '@/components/Teleport/Teleport.vue';

const store = useStateStore();
const route = useRoute();

const isRunTeleport = computed(() => {
  return !!(import.meta.env.MODE === 'mock');
});

const currentComponent = computed(() => {
  if (store.isAuthenticated) {
    return AuthenticatedApp;
  }
  return LoginPage;
});

store.initialStore();
store.$onAction(({ store, after }) => {
  after(() => {
    try {
      storage.setItem(store.state);
    } catch (e) {
      console.error(e);
    }
  });
});

onMounted(() => {
  try {
    // кука, которую мы получаем с сервера при получении html странички, если пользователь пришел с альфа приложения
    const cookieName = 'authorization_cookie';
    let authorizationToken = getCookie(cookieName);
    if (authorizationToken) {
      if (authorizationToken.indexOf('Bearer ') === 0) {
        authorizationToken = authorizationToken.replace('Bearer ', '');
      }
      store.state.session.token = authorizationToken;
      store.state.session.startSession = new Date().getTime();
      deleteCookie(cookieName);
      if (store.state.session.token) {
        server.options.headers.Authorization = `Bearer ${store.state.session.token}`;
      }
    }

    if (!storage.getNamedItem('isShowedNewBonusModal')) {
      // Проверка на то что 23.02.2024 10 утра наступило
      const currentDate = new Date();
      const comparedDate = new Date('2024-02-23T10:00:00');
      if (isAfter(currentDate, comparedDate)) {
        store.state.modals.isShowBonusFromBalanceModal = true;
      }
    }
  } catch (e) {
    console.error(e);
  }

  server.onAfterComplete = (error) => {
    if (error instanceof Error) {
      // Исключения, по которым мы не отображаем модалку с ошибкой из-за 400 или 500 ошибок
      if (route.name === 'callbacks') {
        return;
      }
      store.setErrorRequest(error);
    }
  };

  // если запрос содержит символ # удаляем его и редиректим куда был запрос
  // для сохранения совместимости со страыми внешними линками содержащими #
  if (window.location.hash) {
    window.location.replace(window.location.hash.replace('#', ''));
  }
});
</script>
<style lang="scss">
@use '../scss/variables.scss' as *;

// @use '../../node_modules/font-awesome/css/font-awesome.css';
// @use '@/scss/bootstrap.scss';
// @import '@/scss/main.scss';
// @use '@/libs/vue-img-inputer/style/index.scss';

.wrapper-app {
  flex-grow: 1;
}

.wrapper__header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
}

.wrapper__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;

  .header__container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    width: 100%;
  }

  .header {
    flex: 0 0 auto;
    min-height: 80px;
    padding: 12px 15px 0;
    background: #fff;
    font-size: 15px;

    @media (max-width: $size_767) {
      min-height: 44px;
      padding: 0 16px;
    }
  }

  .wrapper__header {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    .wrapper__header__left {
      flex: 1;
      background-color: #fff;
      margin-right: -1px;

      @media (max-width: $size_767) {
        display: none;
      }
    }

    .wrapper__header__right {
      flex: 1;
      background-color: #fff;
      margin-left: -1px;

      @media (max-width: $size_767) {
        display: none;
      }
    }
  }

  main {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .wrapper__footer {
    width: 100%;
    background-color: #fff;
  }

  .login {
    padding-top: 0;
    background-color: #f2f4f8;
  }
}

.wrapper__content__has_background {
  background-color: #f2f4f8;
}

.wrapper-content-unauth {
  height: 100%;
}
</style>
