<template>
  <div>
    <div class="login_input_wrapper mt-10 mb-6 text-center potok-text-body-1">
      {{ props.otpMessage }}
    </div>

    <PotokInputPassword
      id="otpCode"
      v-model="rawOtpCode"
      v-maska
      :readonly="props.loading"
      placeholder="проверочный код"
      class="mt-3"
      :data-maska="localMasks.smsCode"
      data-test="login-password-input-with-otp"
      @keyup="onKeyUpCode"
      @keyup.enter="onClickEnterOtpCode()"
      @focus="clearErrors"
    />
    <div
      v-if="localErrorMessageOtp"
      class="potok-color-red"
      data-test="login-password-error-message-with-otp"
    >
      {{ localErrorMessageOtp }}
    </div>

    <PotokButton
      :disabled="loading"
      size="large"
      text="продолжить"
      class="button-login w-100 mt-3"
      data-test="login-password-continue-with-otp"
      @click="onClickEnterOtpCode"
    />
    <PotokButton
      theme="link"
      class="w-100 mt-3"
      data-test="login-password-back-btn-with-otp"
      @click="onClickBack"
    >
      назад
    </PotokButton>
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { PotokButton, PotokInputPassword } from 'potok-uikit';
import { vMaska } from 'maska/vue';
import masks from '@/utils/masks';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  rawPhone: {
    type: String,
    default: null,
  },
  rawPassword: {
    type: String,
    default: null,
  },
  otpMessage: {
    type: String,
  },
  otpErrorMessage: {
    type: String,
  },
});

const emits = defineEmits(['input-otp-code', 'otp-back', 'clear-errors']);

const rawOtpCode = ref(null);
const localErrorMessageOtp = ref(null);

const validations = {
  rawOtpCode: {
    required,
  },
};
const v$ = useVuelidate(validations, { rawOtpCode });

const localMasks = computed(() => {
  return {
    phone: masks.phone(),
  };
});

const clearErrors = () => {
  localErrorMessageOtp.value = null;
  emits('clear-errors');
};

const isValidOtpCode = () => {
  if (!rawOtpCode.value) {
    localErrorMessageOtp.value = 'Код не может быть пустым';
    return false;
  }
  return true;
};

const onKeyUpCode = () => {
  v$.value.rawOtpCode.$dirty = true;
  localErrorMessageOtp.value = null;
};

const onClickEnterOtpCode = () => {
  if (!isValidOtpCode()) {
    return;
  }

  emits('input-otp-code', {
    rawPhone: props.rawPhone,
    rawPassword: props.rawPassword,
    otpCode: rawOtpCode.value,
  });
};

const onClickBack = () => {
  emits('otp-back');
};

watch(
  () => {
    return props.otpErrorMessage;
  },
  () => {
    localErrorMessageOtp.value = props.otpErrorMessage;
  },
);
</script>
<style lang="scss" scoped></style>
