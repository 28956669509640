<template>
  <div class="page">
    <nav class="nav-bar">
      <div class="nav-bar-left">
        <a href="/" class="nav-bar-logo mr-6" aria-label="Home"></a>
      </div>
    </nav>
    <div class="form w-100 m-auto">
      <div class="potok-text-h1 text-center title">вход</div>

      <LoginInputPhonePassword
        v-if="!isOtpMode"
        :loading="loading"
        :raw-phone="phone"
        :password-error-message="passwordErrorMessage"
        @start-login="startLogin"
      />
      <LoginInputOtpCode
        v-if="isOtpMode"
        :loading="loading"
        :otp-message="otpMessage"
        :otp-error-message="otpErrorMessage"
        :raw-phone="phone"
        :raw-password="password"
        @clear-errors="otpErrorMessage = null"
        @input-otp-code="onInputOtpCode"
        @otp-back="onOtpBack"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { filter, map, merge, unique } from '@/utils/commonUtils/utils';
import tracker from '@/tracker';
import LoginInputOtpCode from '@/components/Login/LoginInputOtpCode.vue';
import LoginInputPhonePassword from '@/components/Login/LoginInputPhonePassword.vue';
import { convertRawPhoneToLogin } from '@/utils/phone/formatPhone';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();
const route = useRoute();
const router = useRouter();

const otpMessage = ref(null);
const otpErrorMessage = ref(null);
const passwordErrorMessage = ref(null);

const loading = ref(false);

const isOtpMode = computed(() => {
  return !!otpMessage.value;
});

const phone = ref(null);
const password = ref(null);

const startSession = async ({ login, token }) => {
  otpMessage.value = null;
  otpErrorMessage.value = null;
  passwordErrorMessage.value = null;
  store.merge({
    dictionaries: {
      companies: [],
    },
    session: {
      login,
      token,
      startSession: new Date().getTime(),
    },
  });

  getUsersMeAsync();
};

const getUsersMeAsync = () => {
  return server.getUsersMe
    .send()
    .pipe(onGetUsersMeAsyncSuccess, () => {})
    .exec();
};
const onGetUsersMeAsyncSuccess = ({ data: user }) => {
  store.merge({ user });
  redirectAfterLogin();
};

const startLogin = ({ rawPhone, rawPassword }) => {
  phone.value = rawPhone;
  password.value = rawPassword;

  const query = {
    login: convertRawPhoneToLogin(rawPhone),
    password: rawPassword,
  };
  loading.value = true;
  return server.postUsersLogin
    .send(query)
    .pipe(onLoginAsyncSuccess.bind(this), onLoginAsyncError.bind(this))
    .exec();
};

const onLoginAsyncSuccess = (obj) => {
  if (!obj) {
    return;
  }
  const data = obj.data;
  loading.value = false;
  const { token, error } = data;
  const localOtpMessage = data.otpMessage;

  if (error) {
    otpMessage.value = '';
    otpErrorMessage.value = '';
    passwordErrorMessage.value = error;
    return;
  } else if (localOtpMessage) {
    // Switch to otp mode
    otpMessage.value = localOtpMessage;
    return;
  }

  startSession({ login: phone.value, token });
};

const onLoginAsyncError = (obj) => {
  if (!obj) {
    return;
  }
  const data = obj.data;
  loading.value = false;
  if (!data.message) {
    return;
  }
  if (data.message === 'Internal Server Error') {
    passwordErrorMessage.value = 'Ошибка. Попробуйте позже';
  } else {
    passwordErrorMessage.value = data.message;
  }
};

const redirectAfterLogin = () => {
  const objRedirect = {
    params: {},
  };

  if (route.query.url) {
    let { url } = route.query;
    if (url.startsWith('/')) {
      url = url.substring(1);
    }
    objRedirect.path = url;
    delete route.query.url;
  } else {
    objRedirect.name = 'main';
  }

  if (route.query.id) {
    objRedirect.params.id = route.query.id;
    delete route.query.id;
  }

  tracker.queue(
    tracker.commands.SEND,
    'Adg_Investory_Shag3_Avtorizacija_uspeshno',
  );

  try {
    objRedirect.query = route.query;
    router.push(objRedirect);
  } catch (e) {
    console.error(e);
    router.push({
      name: 'main',
      params: {},
    });
  }
};

const onInputOtpCode = ({ rawPhone, rawPassword, otpCode }) => {
  const query = {
    login: convertRawPhoneToLogin(rawPhone),
    password: rawPassword,
    otp: otpCode,
  };

  loading.value = true;
  return server.postUsersLoginOtp
    .send(query)
    .pipe(onLoginOtpAsyncSuccess.bind(this), (error) => {
      loading.value = false;
      if (error?.data?.message) {
        otpErrorMessage.value = error.data.message;
      }
    })
    .exec();
};

const onLoginOtpAsyncSuccess = (obj) => {
  if (!obj) {
    return;
  }
  const data = obj.data;
  loading.value = false;
  const { token, error } = data;
  if (error) {
    otpErrorMessage.value = error;
    return;
  }
  startSession({ login: phone.value, token });
};

const onOtpBack = () => {
  otpMessage.value = null;
};

const grabAndMergeAndCommitUtms = () => {
  const objNewUtms = filter(route.query, (_, key) => key.startsWith('utm_'));
  const arrNewUtms = map(objNewUtms, (v, k) => [k, v]);
  const arrOldUtms = store.state.user.utm;
  const utm = unique(merge(arrOldUtms, arrNewUtms));
  const user = { ...store.state.user, utm };
  store.set({ user });
};

const init = () => {
  grabAndMergeAndCommitUtms();
  tracker.queue(tracker.commands.SEND, 'PageView');
  const login = route.query.login || store.state.session.login;
  if (login) {
    phone.value = login;
  }
};

onMounted(async () => {
  // Роутинг отделен от всего остально и выполянется ассинхронно
  // так как нам нужно поймать все utm метки поэтому ждем загрузки роутинга и только потом
  // продолжаем выполнение логики дальше
  await router.isReady();
  init();
});
</script>
<style lang="scss" scoped>
.title {
  margin-top: 100px;
}

.form {
  max-width: 340px;
}
</style>
