<template>
  <div class="container container_wrapper" data-test="login-register-step1">
    <div class="login_title">
      <div>регистрация</div>
    </div>
    <div class="login_actions">
      <div class="login_input_wrapper">
        <PotokInputLarge
          id="phone"
          v-model="localRawPhone"
          v-maska
          :data-maska="localMasks.phone"
          type="tel"
          name="phone"
          placeholder="+7 (999) 999-99-99"
          data-test="login-phone-input"
        />

        <PotokInputPassword
          v-if="!v$.localRawPhone.$invalid"
          id="password"
          v-model="localRawPassword"
          class="mt-3"
          placeholder="пароль"
          data-test="login-register-step1-input"
          @update:model-value="onClickPressKeyPassword"
          @keyup.enter="onClickEnterPassword()"
        />

        <div
          v-if="errorMessagePassword"
          class="potok-text-body-1 potok-color-deepdive text_tips potok-color-red error_message"
          data-test="login-register-step1-error-message"
        >
          {{ errorMessagePassword }}
        </div>
      </div>
      <PotokButton
        text="продолжить"
        full-width-desktop
        size="large"
        class="mt-6"
        data-test="login-register-step1-continue"
        @click="onClickEnterPassword"
      />

      <PotokButton
        theme="link"
        class="mt-3"
        full-width-desktop
        size="link"
        text="назад"
        data-test="login-register-step1-back"
        @click="onClickBack"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { PotokButton, PotokInputLarge, PotokInputPassword } from 'potok-uikit';
import validators from '@/validators';
import { helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import tracker from '@/tracker';
import { vMaska } from 'maska/vue';
import masks from '@/utils/masks';

const props = defineProps({
  rawPhone: {
    type: String,
    default: '',
  },
  rawPassword: {
    type: String,
    default: '',
  },
  loadingRegister: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['changeStep', 'changePassword']);
const router = useRouter();

const localRawPassword = ref(null);
const localRawPhone = ref(null);
const errorMessagePassword = ref(null);

const isPhone = helpers.regex(
  /^(\+7[ ]\([0-9]{3}\)[ ])([0-9]{3}-)([0-9]{2}-)[0-9]{2}$/,
);
const validations = {
  localRawPhone: {
    required: validators.required,
    valid: isPhone,
  },
  localRawPassword: {
    required: validators.required,
  },
};

const v$ = useVuelidate(validations, { localRawPhone, localRawPassword });

const localMasks = computed(() => {
  return {
    phone: masks.phone(),
  };
});

const isPasswordValid = () => {
  if (!localRawPassword.value || localRawPassword.value.length < 6) {
    errorMessagePassword.value = 'Минимальная длина пароля 6 символов';
    return false;
  }
  if (localRawPassword.value.length > 32) {
    errorMessagePassword.value = 'Максимальная длина пароля 32 символов';
    return false;
  }
  if (!validators.passwordAlpha.$validator(localRawPassword.value)) {
    errorMessagePassword.value =
      'Пароль должен состоять из строчных и/или заглавных букв латинского алфавита (A—Z), цифр и знаков препинания.';
    return false;
  }
  return true;
};

const onClickPressKeyPassword = () => {
  errorMessagePassword.value = null;
  emit('changePassword', localRawPassword.value);
};

const onClickEnterPassword = () => {
  if (!isPasswordValid()) {
    return;
  }
  tracker.queue(
    tracker.commands.SEND,
    'Adg_Investory_Shag2_Registracija_vvel_nomer',
  );
  tracker.queue(
    tracker.commands.SEND,
    'Adg_Investory_Shag3_Registracija_sozdal_parol',
  );
  emit('changeStep', {
    phone: localRawPhone.value,
    password: localRawPassword.value,
    nextStep: 'inputEmail',
  });
};
const onClickBack = () => {
  return router.push({ name: 'main' });
};
watch(
  () => {
    return props.rawPhone;
  },
  () => {
    localRawPassword.value = props.rawPhone;
  },
);
</script>
<style lang="scss" scoped>
.container_wrapper {
  justify-content: flex-start !important;
  height: initial !important;
}

.login_subtitle_restricted {
  max-width: 265px;
}

.button-back {
  margin-top: 30px;
}
</style>
