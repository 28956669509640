<template>
  <PotokPage class="login-register" data-test="login-register">
    <nav class="nav-bar">
      <div class="nav-bar-left">
        <a href="/" class="nav-bar-logo mr-6" aria-label="Home"></a>
      </div>
    </nav>
    <component
      :is="currentComponent"
      :raw-phone="rawPhone"
      :raw-password="rawPassword"
      :raw-email="rawEmail"
      :sms-id="smsId"
      @change-step="changeStep"
    />
  </PotokPage>
</template>
<script setup>
import { ref, computed } from 'vue';
import { PotokPage } from 'potok-uikit';

import LoginInputRegisterStep1 from '@/components/Login/LoginRegister/LoginRegisterStep1.vue';
import LoginInputRegisterStep2 from '@/components/Login/LoginRegister/LoginRegisterStep2.vue';
import LoginInputRegisterStep3 from '@/components/Login/LoginRegister/LoginRegisterStep3.vue';

const rawPhone = ref(null);
const rawPassword = ref(null);
const rawEmail = ref(null);

const currentStep = ref('inputPassword');
const smsId = ref(null);

const currentComponent = computed(() => {
  if (currentStep.value === 'inputPassword') {
    return LoginInputRegisterStep1;
  }
  if (currentStep.value === 'inputEmail') {
    return LoginInputRegisterStep2;
  }
  if (currentStep.value === 'inputSMSCode') {
    return LoginInputRegisterStep3;
  }
  return null;
});

const changeStep = (obj) => {
  if (obj.nextStep) {
    currentStep.value = obj.nextStep;
  }
  if (obj.phone) {
    rawPhone.value = obj.phone;
  }
  if (obj.password) {
    rawPassword.value = obj.password;
  }

  if (obj.smsId) {
    smsId.value = obj.smsId;
  }
};
</script>
<style lang="scss" scoped>
@use '@/scss/variables.scss' as *;

.login_wrapper .login-register {
  padding-top: 72px;
  
}

.login-register {
  height: 100%;
}

:deep(.container_wrapper) {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

:deep(.login-first-wrapper) {
  flex: 2;
}

:deep(.login_title) {
  margin-top: 100px;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: $deep_dive;
  width: 100%;
  display: flex;
  justify-content: center;
  letter-spacing: -0.01em;

  div {
    max-width: 688px;
  }

  @media (max-width: $size_991) {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.01em;

    div {
      max-width: 208px;
    }
  }
}

:deep(.login_subtitle_restricted) {
  max-width: 379px;
  margin: 0 auto;
}

:deep(.login_input_wrapper) {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;

  @media (max-width: $size_991) {
    margin-right: initial;
  }

  input {
    width: 340px;
    padding-left: 20px;
    color: $deep_dive;
    height: 60px;
    border: 0;
    background: #f2f4f8;
    border-radius: 8px;

    @media (max-width: $size_991) {
      min-width: 288px;
      width: 100%;
    }
  }

  #passwordConfirm {
    margin-top: 20px;
  }
}

:deep(.login_actions) {
  width: 340px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  margin: auto;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

:deep(.button-continue) {
  margin-top: 24px;
  width: 340px;
  height: 60px;
}

:deep(.button-back) {
  color: $grey;
  margin-top: 20px;
}

:deep(.text_tips) {
  color: $grey;
  margin-top: 10px;
  margin-bottom: 0;
}

:deep(.footer_message) {
  color: $grey;
  max-width: 536px;
  text-align: center;
  margin: 0 auto;

  a {
    color: $deep_dive;
  }
}

:deep(.error_message) {
  margin-top: 10px;
}
</style>
