<template>
  <div class="loan-about-borrower">
    <PotokMessage
      v-if="
        data.item &&
        data.item.lastTransactionDate &&
        !isBorrowerPotokHolding &&
        !isBorrowerPotokFinance
      "
    >
      <div>
        Данные о заемщике представлены на дату формирования заявки на кредит —
        {{ formatDate(data.item.lastTransactionDate) }}
      </div>
    </PotokMessage>

    <div class="content">
      <template v-if="loadingBorrower">
        <Loader class="loader" />
      </template>

      <template v-if="!loadingBorrower && data.item">
        <FormFieldTitle :divider="false" :title="'основная информация'" />

        <FormField
          :divider="false"
          :name="'название'"
          :name-width="3"
          :value-width="9"
        >
          <template #value>
            <span v-if="data.item.companyShortName" class="shortname">{{
              data.item.companyShortName
            }}</span>
            <span v-if="data.item.shortName" class="shortname">{{
              data.item.shortName
            }}</span>
            <div>
              <a
                :href="'http://www.rusprofile.ru/search?query=' + data.item.inn"
                class="link"
                target="_blank"
                >подробная информация</a
              >
            </div>
          </template>
        </FormField>
        <FormField
          :name="'ИНН/ОГРН(ИП)'"
          :value="`${data.item.inn} / ${data.item.ogrn}`"
          :name-width="3"
          :value-width="9"
        />
        <FormField
          v-if="data.item.formalAddress"
          :name="'адрес'"
          :value="data.item.formalAddress"
          :name-width="3"
          :value-width="9"
        />
        <FormField :name="'сайт'" :name-width="3" :value-width="9">
          <template #value>
            <template v-if="data.item.websiteUri.length !== 0">
              <a :href="getHttpString(data.item.websiteUri)" target="_blank">{{
                data.item.websiteUri
              }}</a>
            </template>
            <template v-else> отсутствует </template>
          </template>
        </FormField>
        <FormField :name="'срок деятельности'" :name-width="3" :value-width="9">
          <template #value>
            <span>
              {{ periodOfActivity(data.item.registerDateAt) }}
            </span>
            (с
            <span> {{ formatDate(data.item.registerDateAt) }} </span>)
          </template>
        </FormField>
        <FormField
          v-if="
            Array.isArray(data.item.industryCategories) &&
            data.item.industryCategories.length > 0
          "
          :name="'отрасль деятельности'"
          :name-width="3"
          :value-width="9"
        >
          <template #value>
            <span
              v-for="(category, index) in data.item.industryCategories"
              :key="index"
            >
              {{ category }}
              <span v-if="index + 1 < data.item.industryCategories.length"
                >,
              </span>
            </span>
          </template>
        </FormField>
        <FormField
          v-if="data.item.okvedMain"
          :name="'основной ОКВЭД'"
          :value="data.item.okvedMain"
          :name-width="3"
          :value-width="9"
        />
        <FormField
          v-if="data.item.marketingDescription"
          :name="'описание деятельности'"
          :value="data.item.marketingDescription"
          :name-width="3"
          :value-width="9"
        />

        <FormField
          v-if="!isCompanyEntrepreneur && data.item.directorFio"
          :name="'руководитель'"
          :value="data.item.directorFio"
          :name-width="3"
          :value-width="9"
        />
        <FormField
          v-if="data.item.founders && data.item.founders.length > 0"
          :name="'учредители'"
          :name-width="3"
          :value-width="9"
        >
          <template #value>
            <p v-for="(founder, index) in data.item.founders" :key="index">
              <span v-if="founder.fio" class="mr-1">
                {{ founder.fio }}
              </span>
              <span v-if="founder.age" class="founder-age mr-1">
                ({{ founder.age }} {{ pluralAge(founder.age) }})
              </span>
              <span v-if="founder.share" class="founder-share mr-1">
                — {{ founder.share }}%
              </span>
            </p>
          </template>
        </FormField>

        <LoanAboutBorrowerRating
          v-if="props.project.borrower.modelBucket"
          :borrower="props.project.borrower"
        />

        <FormFieldTitle
          v-if="isShowAnalysisBankStatements"
          :title="'анализ выписок по банковским счетам'"
        />

        <FormField
          v-if="data.item.incomeForLast12M"
          :value="`${formatCurrency(data.item.incomeForLast12M, 0)}  ₽`"
          :name-width="3"
          :value-width="9"
        >
          <template #name>
            <div>
              чистый кредитовый оборот за 12 месяцев
              <QuestionPopover>чистый кредитовый оборот</QuestionPopover>
            </div>
          </template>
        </FormField>
        <FormField
          v-if="
            data.item.avgTurnoverFor3M ||
            data.item.avgTurnoverFor6M ||
            data.item.avgTurnoverFor12M ||
            isShowMonthGraph
          "
          :name-width="3"
          :value-width="9"
        >
          <template #name>
            <div>
              ср. месячный чистый кредитовый оборот
              <QuestionPopover>чистый кредитовый оборот</QuestionPopover>
            </div>
          </template>
          <template #value>
            <p>
              за 3 мес {{ formatCurrency(data.item.avgTurnoverFor3M, 0) }} ₽
            </p>
            <p>
              за 6 мес {{ formatCurrency(data.item.avgTurnoverFor6M, 0) }} ₽
            </p>
            <p>
              за 12 мес {{ formatCurrency(data.item.avgTurnoverFor12M, 0) }} ₽
            </p>
            <p
              v-if="isShowMonthGraph"
              class="month-graph"
              @click="onClickMonthGraph"
            >
              график по месяцам
            </p>
          </template>
        </FormField>
        <FormField
          v-if="data.item.avgOfIncomeTransactionsIn6M"
          :value="data.item.avgOfIncomeTransactionsIn6M"
          :name-width="3"
          :value-width="9"
        >
          <template #name>
            ср. месячное кол-во транзакций
            <QuestionPopover
              >среднее количество транзакций за последние 6 месяцев – по чистым
              кредитовым оборотам</QuestionPopover
            >
          </template>
        </FormField>
        <FormField
          v-if="data.item.avgCustomerCountFor6M"
          :value="data.item.avgCustomerCountFor6M"
          :name-width="3"
          :value-width="9"
        >
          <template #name>
            ср. месячное кол-во покупателей
            <QuestionPopover
              >среднее количество покупателей за последние 6 месяцев – по чистым
              кредитовым оборотам</QuestionPopover
            >
          </template>
        </FormField>
        <FormField
          v-if="data.item.lastTransactionDate && !isBorrowerPotokHolding"
          :name="'дата загрузки выписки'"
          :value="formatDate(data.item.lastTransactionDate)"
          :name-width="3"
          :value-width="9"
          class="mt-5"
        >
        </FormField>

        <FormFieldTitle
          v-if="isShowFinancialReportingTaxes"
          :title="'финансовая отчетность и налоги'"
        />

        <FormField
          v-if="data.item.reportingPeriod"
          :name="'отчетный период'"
          :value="`${data.item.reportingPeriod} год`"
          :name-width="3"
          :value-width="9"
        />
        <FormField
          v-if="data.item.balance"
          :name="'баланс'"
          :value="`${formatCurrency(data.item.balance, 0)} ₽`"
          :name-width="3"
          :value-width="9"
        />
        <FormField
          v-if="data.item.income"
          :name="'выручка'"
          :value="`${formatCurrency(data.item.income, 0)} ₽`"
          :name-width="3"
          :value-width="9"
        />
        <FormField
          v-if="data.item.allTaxesAndFees"
          :name="'все налоги и сборы'"
          :value="`${formatCurrency(data.item.allTaxesAndFees, 0)} ₽`"
          :name-width="3"
          :value-width="9"
        />

        <FormFieldTitle
          v-if="isShowCreditBurden"
          :title="'кредитная нагрузка компании и учредителей'"
        />

        <FormField
          v-if="data.item.balanceOfDebt || data.item.balanceOfDebtInPotok"
          :name-width="3"
          :value-width="9"
        >
          <template #name>
            <div>
              остаток долга вне Потока
              <QuestionPopover
                >остаток основного долга по всем кредитам на момент подачи
                заявки</QuestionPopover
              >
            </div>
          </template>
          <template #value>
            {{ formatCurrency(data.item.balanceOfDebt, 0) }} ₽
          </template>
        </FormField>

        <FormFieldTitle
          v-if="isShowCreditHistory"
          :title="'кредитная история компании и учредителей'"
        />

        <FormField
          v-if="data.item.maxExistingProjectAmount"
          :value="`${formatCurrency(data.item.maxExistingProjectAmount, 0)} ₽`"
          :name-width="3"
          :value-width="9"
        >
          <template #name>
            <div>
              макс. сумма обслуживаемого займа
              <QuestionPopover
                >сумма максимального обслуживаемого займа – погашенного без
                просрочек более 30 дней последних 12 месяцев</QuestionPopover
              >
            </div>
          </template>
        </FormField>
        <FormField
          v-if="data.item.countOfOverduesUpTo30DaysIn12M"
          :name="'число просрочек до 30 дн. за 12 мес'"
          :value="data.item.countOfOverduesUpTo30DaysIn12M"
          :name-width="3"
          :value-width="9"
        />
        <FormField
          v-if="data.item.countOfOverduesOver30DaysIn12M"
          :name="'число просрочек более 30 дн. за 12 мес'"
          :value="data.item.countOfOverduesOver30DaysIn12M"
          :name-width="3"
          :value-width="9"
        />

        <FormFieldTitle
          v-if="isShowCourts"
          :title="'суды и исполнительные производства'"
        />

        <FormField
          v-if="data.item.lawsuitsOpenedAmount"
          :value="`${formatCurrency(data.item.lawsuitsOpenedAmount, 0)} ₽`"
          :name-width="3"
          :value-width="9"
        >
          <template #name>
            <div>
              сумма открытых арбитражных дел
              <QuestionPopover
                >оценка исковой суммы по делам в качестве ответчика в процессе
                рассмотрения за 12 месяцев</QuestionPopover
              >
            </div>
          </template>
        </FormField>
        <FormField
          v-if="data.item.amountOfEnforcementProceedingsIn12M"
          :value="`${formatCurrency(
            data.item.amountOfEnforcementProceedingsIn12M,
            0,
          )} ₽`"
          :name-width="3"
          :value-width="9"
        >
          <template #name>
            <div>
              сумма открытых исполнительных производств
              <QuestionPopover
                >сумма найденных исполнительных производств за последние 12
                месяцев</QuestionPopover
              >
            </div>
          </template>
        </FormField>

        <template v-if="data.item.reports">
          <div class="reports-container">
            <FormFieldTitle
              class="reports-title"
              :divider="false"
              :title="'бухгалтерская финансовая отчетность'"
            />

            <PotokButton
              theme="linkIcon"
              size="link"
              class="show-reports-wrapper"
              @click="isShowReports = !isShowReports"
            >
              <PotokImg
                :src="
                  isShowReports
                    ? imageConstants.arrowUp
                    : imageConstants.arrowDown
                "
                height="5"
                width="10"
              />
            </PotokButton>
          </div>

          <div v-if="isShowReports" class="report-wrapper">
            <div v-if="data.item?.reports?.form1" class="table-report-wrapper">
              <div class="potok-text-body-1-bold text-center mb-3">
                форма 1 {{ data.item.reports.year }} год
              </div>
              <table class="project-block-table">
                <thead>
                  <tr>
                    <th>код</th>
                    <th>имя</th>
                    <th>значение на начало периода</th>
                    <th>значение на конец периода</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in data.item.reports.form1"
                    :key="index"
                  >
                    <td>{{ item.code }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.startValue }}</td>
                    <td>{{ item.endValue }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="data.item?.reports?.form2"
              class="mt-6 table-report-wrapper"
            >
              <div class="potok-text-body-1-bold text-center mb-3">
                форма 2 {{ data.item.reports.year }} год
              </div>
              <table class="project-block-table">
                <thead>
                  <tr>
                    <th>код</th>
                    <th>имя</th>
                    <th>значение на начало периода</th>
                    <th>значение на конец периода</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in data.item.reports.form2"
                    :key="index"
                  >
                    <td>{{ item.code }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.startValue }}</td>
                    <td>{{ item.endValue }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </template>

      <LoanAboutBorrowerFactoring
        v-if="data.item && isFactoring"
        :project="props.project"
      />
    </div>
    <LoanMonthGraphModal
      v-if="showMonthGraph && isShowMonthGraph"
      :turnover-by-month="data.item.turnoverByMonth"
      @close="showMonthGraph = false"
    >
    </LoanMonthGraphModal>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue';

import { parseISO, sub, differenceInYears, differenceInMonths } from 'date-fns';
import {
  pluralize,
  formatCurrency,
  formatDate,
} from '@/utils/commonUtils/utils';
import server from '@/server';
import { Loader } from 'potok-uikit';
import QuestionPopover from '@/components/_generic/QuestionPopover.vue';
import LoanAboutBorrowerFactoring from '../../components/Loan/LoanAboutBorrower/LoanAboutBorrowerFactoring.vue';
import LoanMonthGraphModal from '../../components/Loan/LoanAboutBorrower/LoanMonthGraphModal.vue';
import FormField from '@/components/_generic/forms/FormField.vue';
import FormFieldTitle from '@/components/_generic/forms/FormFieldTitle.vue';
import constants from '@/constants';
import PotokMessage from '@/components/_generic/PotokMessage.vue';
import LoanAboutBorrowerRating from '@/components/Loan/LoanAboutBorrower/LoanAboutBorrowerRating.vue';
import imageConstants from '@/constants/imageConstants';

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
});

const loadingBorrower = ref(false);
const data = reactive({
  item: null,
});
const showMonthGraph = ref(false);
const isShowReports = ref(false);

const isFactoring = computed(() => {
  if (!props.project) {
    return false;
  }
  if (props.project.type === 'factoring') {
    return true;
  }
  return false;
});
const isShowMonthGraph = computed(() => {
  return data.item && data.item.turnoverByMonth.length > 0;
});
const isCompanyEntrepreneur = computed(() => {
  if (data.item.typeId === constants.COMPANY_TYPES_ENTREPRENEUR) {
    return true;
  }
  return false;
});
const isShowAnalysisBankStatements = computed(() => {
  return !!(
    data.item.incomeForLast12M ||
    data.item.avgTurnoverFor3M ||
    data.item.avgTurnoverFor6M ||
    data.item.avgTurnoverFor12M ||
    isShowMonthGraph.value ||
    data.item.avgOfIncomeTransactionsIn6M ||
    data.item.avgCustomerCountFor6M
  );
});
const isBorrowerPotokHolding = computed(() => {
  return props.project.isPotokHoldingBorrower;
});
const isBorrowerPotokFinance = computed(() => {
  return props.project.isPotokFinanceBorrower;
});
const isShowFinancialReportingTaxes = computed(() => {
  return !!(
    data.item.reportingPeriod ||
    data.item.balance ||
    data.item.income ||
    data.item.allTaxesAndFees
  );
});
const isShowCreditBurden = computed(() => {
  return !!(data.item.balanceOfDebt || data.item.balanceOfDebtInPotok);
});
const isShowCreditHistory = computed(() => {
  return !!(
    data.item.maxExistingProjectAmount ||
    (data.item.nbkiRating && data.item.nbkiRating != '0') ||
    data.item.countOfOverduesUpTo30DaysIn12M ||
    data.item.countOfOverduesOver30DaysIn12M
  );
});
const isShowCourts = computed(() => {
  return !!(
    data.item.lawsuitsOpenedAmount ||
    data.item.amountOfEnforcementProceedingsIn12M
  );
});

onMounted(() => {
  getBorrowerAsync();
});
const pluralAge = (number) => {
  const titles = ['год', 'года', 'лет'];
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};
const getBorrowerAsync = () => {
  if (!props.project || !props.project.borrower || !props.project.borrower.id) {
    return;
  }
  const query = {
    projectId: props.project.id,
  };
  loadingBorrower.value = true;
  server.getBorrower
    .send(query, { params: { borrowerId: props.project.borrower.id } })
    .pipe(onGetBorrowerAsyncSuccess, () => {
      loadingBorrower.value = false;
    })
    .exec();
};
const onGetBorrowerAsyncSuccess = ({ data: _data }) => {
  loadingBorrower.value = false;
  data.item = _data;
};
const getHttpString = (str) => {
  return str.includes('http') ? str : 'http://' + str;
};
const periodOfActivity = (registerDateAt) => {
  const years = differenceInYears(new Date(), parseISO(registerDateAt));
  const month = differenceInMonths(
    sub(new Date(), { years: years }),
    parseISO(registerDateAt),
  );
  return (
    years +
    ' ' +
    pluralize(years, ['год', 'года', 'лет']) +
    ' ' +
    month +
    ' ' +
    pluralize(month, ['месяц', 'месяца', 'месяцев'])
  );
};
const onClickMonthGraph = () => {
  showMonthGraph.value = true;
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.show-reports-wrapper {
  height: 40px;
  width: 40px;
  margin-right: 30px !important;
  border-radius: 50% !important;
  margin-left: auto !important;
  background-color: #f2f4f8 !important;

  @media (max-width: $size_991) {
    height: 24px !important;
    width: 24px !important;
    margin-right: 5px !important;
  }
}

.table-report-wrapper {
  overflow: auto;
}

.reports-title {
  flex: 2;
}

.reports-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.report-wrapper {
  padding: 10px;
  text-align: left;
}

.loan-about-borrower {
  .content {
    width: 100%;
    background: $white;
    box-shadow: 0 1px 4px #e5e9f2;
    border-radius: 6px;
    margin: 30px 0;
    padding: 20px;

    @media (max-width: $size_767) {
      padding: 14px;
    }
  }

  .loader {
    margin: 20px;
  }

  .month-graph {
    color: $tiffany;
    cursor: pointer;
  }

  .shortname {
    margin-right: 10px;
  }

  .link {
    color: $tiffany;
  }

  .founder-age,
  .founder-share {
    color: $grey-second;
  }
}
</style>
