<template>
  <div class="loans-table-container">
    <PotokCardListExtended v-if="isMobile" :items="props.rows">
      <template #body="props">
        <LoansBorrowerTypeIcon
          class="mb-2"
          :type="getBorrowerType(props.item.borrower.typeId)"
        />

        <LoansItemRating
          v-if="props.item.borrower.modelBucket"
          class="mb-2"
          :rating="props.item.borrower.modelBucket"
        />

        <div class="potok-color-grey mb-1">Заемщик</div>
        <div class="text-no-transform mb-2">
          {{ props.item.borrower.shortName }}
        </div>

        <div class="potok-color-grey mb-1">Остаток ОД</div>
        <div class="text-no-transform mb-2">
          {{ formatCurrency(props.item.remainingMainDebtAmount, 0) }} ₽
        </div>

        <div class="potok-color-grey mb-1">Срок погашения</div>
        <div class="text-no-transform mb-2">
          {{ getDate(props.item.loanRefundedAt, 0) }}
        </div>

        <div class="potok-color-grey mb-1">Ставка</div>
        <div class="text-no-transform">
          {{ getRateText(props.item.loanRate, props.item.irr) }}
        </div>
      </template>
    </PotokCardListExtended>

    <PotokTable v-if="!isMobile" :columns="columns" :rows="props.rows">
      <template #body="props">
        <PotokTableRow
          hover
          class="loans-first-table-row"
          @click="toLoanPage(props.row.id)"
        >
          <PotokTableCell>
            <div class="table-info-container">
              <LoansBorrowerTypeIcon
                :type="getBorrowerType(props.row.borrower.typeId)"
              />

              <div class="loans-first-table-info ml-4">
                <div class="loans-first-table-info-top mb-1">
                  <LoansItemRating
                    v-if="props.row.borrower.modelBucket"
                    :rating="props.row.borrower.modelBucket"
                  />
                </div>

                <span class="text-no-transform">{{
                  props.row.borrower.shortName
                }}</span>
              </div>
            </div>
          </PotokTableCell>

          <PotokTableCell>
            {{ formatCurrency(props.row.remainingMainDebtAmount, 0) }} ₽
          </PotokTableCell>

          <PotokTableCell>
            {{ getDate(props.row.loanRefundedAt, 0) }}
          </PotokTableCell>

          <PotokTableCell>
            {{ getRateText(props.row.loanRate, props.row.irr) }}
          </PotokTableCell>
        </PotokTableRow>
      </template>
    </PotokTable>
  </div>

  <PaginationDesktop
    v-if="!isTablet"
    v-model:current-page="currentPage"
    v-model:selected-page-range="selectedPageRange"
    :total-items-count="props.total"
    :items-pluralize-array="['займа', 'займов', 'займов']"
  />
  <PaginationMobile
    v-if="isTablet"
    v-model:current-page="currentPage"
    v-model:selected-page-range="selectedPageRange"
    :total-items-count="props.total"
    :items-pluralize-array="['займа', 'займов', 'займов']"
    class="mt-5"
  />
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import {
  PotokTable,
  PotokTableRow,
  PotokTableCell,
  PotokCardListExtended,
} from 'potok-uikit';

import PaginationDesktop from '@/components/_generic/pagination/paginationDesktop.vue';
import PaginationMobile from '@/components/_generic/pagination/paginationMobile.vue';

import { createRateText } from '@/utils/strategies/strategiesUtils';
import { formatCurrency } from '@/utils/commonUtils/utils';

import constants from '@/constants';
import LoansBorrowerTypeIcon from '@/components/Loans/LoansBorrowerTypeIcon.vue';
import LoansItemRating from '@/components/Loans/LoansItemRating.vue';
import detectDevice from '@/composables/detectDeviceComposable';

const router = useRouter();
const { isTablet, isMobile } = detectDevice();

const props = defineProps([
  'rows',
  'total',
  'currentPage',
  'selectedPageRange',
]);
const emits = defineEmits(['fetchList', 'mobileFetchList']);

const selectedPageRange = ref(props.selectedPageRange);
const currentPage = ref(props.currentPage);

const columns = [
  {
    label: 'заемщик',
  },
  {
    label: 'Остаток ОД',
  },
  {
    label: 'Срок погашения',
  },
  {
    label: 'ставка',
    dropdownMessage: 'номинальная / эффективная',
  },
];

const getDate = (date) => {
  if (!date) return '';
  return `${format(date, 'dd.MM.yyyy')}`;
};

const getRateText = (rate, irr) => {
  let text = `${createRateText(rate)}`;

  if (irr) {
    text += ` / ${createRateText(irr)}`;
  }

  text += '%';

  return text;
};

const getBorrowerType = (typeId) => {
  if (typeId === constants.COMPANY_TYPES_ENTREPRENEUR) return 'ENTREPRENEUR';
  return 'COMPANY';
};

const toLoanPage = (id) => {
  router.push({ name: 'loan', params: { id } });
};

const fetchList = () => {
  emits('fetchList', {
    selectedPageRange: selectedPageRange.value,
    offset: selectedPageRange.value * (currentPage.value - 1),
  });
};

const mobileFetchList = () => {
  emits('mobileFetchList', {
    selectedPageRange: selectedPageRange.value,
    offset: selectedPageRange.value * (currentPage.value - 1),
    isMobile: true,
  });
};

const fetchHandler = () => {
  if (isTablet.value) {
    mobileFetchList();
    return;
  }

  if (selectedPageRange.value * (currentPage.value - 1) >= props.total) {
    currentPage.value = currentPage.value / 2;
    fetchList();

    return;
  }

  fetchList();
};

watch(currentPage, fetchHandler);
watch(selectedPageRange, fetchHandler);
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.loans-table-container {
  @media (max-width: $size_991) {
    width: 100%;
    overflow-x: auto;
  }
}

.table-info-container {
  display: flex;
  align-items: center;
}

.loans-first-table-info {
  display: flex;
  flex-direction: column;
}

.loans-first-table-info-top {
  display: flex;
  align-items: center;
}

.loans-first-table-goal {
  display: flex;
  align-items: center;
}

.loans-first-table-goal-text {
  display: initial;
  height: fit-content;
}

.loans-first-table-goal .loans-first-table-btn {
  opacity: 0;
}

.loans-first-table-row {
  &:hover {
    .loans-first-table-goal-text {
      display: none;
    }

    .loans-first-table-btn {
      opacity: 1;
    }
  }
}
</style>
