<template>
  <PotokTable :columns="columns" :rows="props.applications">
    <template #body="{ row }">
      <PotokTableRow>
        <PotokTableCell>
          {{ row.id }}
        </PotokTableCell>

        <PotokTableCell>
          {{ getDate(row.loanRefundedAt, 0) }}
        </PotokTableCell>

        <PotokTableCell>
          {{ formatCurrency(row.remainingMainDebtAmount, 0) }} ₽
        </PotokTableCell>

        <PotokTableCell>
          {{ formatCurrency(row.returnAmount, 0) }} ₽
        </PotokTableCell>

        <PotokTableCell>
          {{ formatCurrency(row.profit, 0) }} ₽
        </PotokTableCell>

        <PotokTableCell>
          {{ getRateText(row.irr) }}
        </PotokTableCell>

        <PotokTableCell class="table-cell-btn">
          <PotokButton
            text="отменить"
            theme="tertiary"
            @click="cancel(row.id)"
          />
        </PotokTableCell>
      </PotokTableRow>
    </template>
  </PotokTable>
</template>

<script setup>
import { format } from 'date-fns';

import { PotokButton, PotokTable, PotokTableCell, PotokTableRow } from 'potok-uikit';

import { createRateText } from '@/utils/strategies/strategiesUtils';
import {
  formatCurrency,
} from '@/utils/commonUtils/utils';

const props = defineProps(['applications', 'total']);
const emits = defineEmits(['cancel']);

const columns = [
  {
    label: 'ID займа',
  },
  {
    label: 'дата',
  },
  {
    label: 'сумма вложения ',
  },
  {
    label: 'сумма возврата',
  },
  {
    label: 'чистый доход',
  },
  {
    label: 'ставка',
  },
  {
    label: '',
  },
];

const getDate = (date) => {
  if (!date) return '';
  return `${format(date, 'dd.MM.yyyy')}`
};

const getRateText = (rate, irr) => {
  let text = `${createRateText(rate)}`

  if (irr) {
    text += ` / ${createRateText(irr)}`
  }

  text += '%';

  return text;
};

const cancel = (loan) => {
  emits('cancel', loan);
}
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.table-cell-btn {
  width: 13%;
}
</style>
