<template>
  <PotokContainer background="#F2F4F8">
    <div class="potok-text-body-1 potok-color-grey mb-9">
      выберите период выплаты процентов
    </div>
    <PotokSwitcher
      v-if="!isTablet"
      v-model="activeValue"
      :items="items"
    />
  </PotokContainer>
</template>

<script setup>
import { ref, watch } from 'vue';
import { PotokSwitcher, PotokContainer } from 'potok-uikit';
import detectDevice from '@/composables/detectDeviceComposable';

const emits = defineEmits(['change']);

const { isTablet } = detectDevice();

const activeValue = ref('1');

const items = [
  {
    title: 'в конце срока',
    value: '1',
    width: '50%',
  },
  {
    title: 'ежемесячная',
    value: '2',
    width: '50%',
  },
]

watch(activeValue, (newValue) => {
  emits('change', newValue);
})
</script>

<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.switcher-container {
  padding: 20px;
  border-radius: 14px;
  background: $background;
}
</style>
