<template>
  <div class="new-investments-sell-item-panel-actions">
    <!-- кнопки для оператора вторичного рынка -->
    <template v-if="props.isSecondMarket">
      <PotokButton
        :disabled="props.isLoadingOperation"
        class="mr-2"
        @click="handleOperation({ status: 'approved', commentValue: null })"
      >
        <template v-if="props.isLoadingOperation">
          <Loader />
        </template>
        <template v-if="!props.isLoadingOperation">
          подтвердить заявку
        </template>
      </PotokButton>

      <PotokButton
        theme="tertiary"
        :disabled="props.isLoadingOperation"
        class="panel-actions-reject"
        @click="handleReject"
      >
        <template v-if="props.isLoadingOperation">
          <Loader />
        </template>
        <template v-if="!props.isLoadingOperation"> отклонить </template>
      </PotokButton>
    </template>
    <!-- кнопки для обычного инвестора -->
    <template v-if="!props.isSecondMarket">
      <PotokButton
        theme="tertiary"
        :disabled="props.isLoadingOperation"
        class="panel-actions-cancel mr-2"
        @click="handleOperation({ status: 'cancel', commentValue: null })"
      >
        <template v-if="props.isLoadingOperation">
          <Loader />
        </template>
        <template v-if="!props.isLoadingOperation"> отменить заявку </template>
      </PotokButton>
    </template>
    <!-- модалка при отклонении заявки -->
    <ModalFull
      v-if="isShowRejectModal"
      title="отклонение заявки на продажу"
      class="reject-request-modal"
      @close="closeModalReject"
    >
      <template #content>
        <div
          class="investments-reject-request-modal-text potok-text-body-2 mb-8"
        >
          заявка {{ props.item.number }} будет отклонена, напишите <br />
          причину, чтобы инвестору было понятно
        </div>

        <textarea
          v-model="comment"
          maxlength="500"
          placeholder="причина отклонения"
          class="investments-reject-request-modal-textarea"
          rows="4"
        />
      </template>
      <template #actions>
        <div class="modal-reject-actions button-center">
          <PotokButton
            size="large"
            :disabled="props.isLoadingOperation"
            class="mb-5"
            @click.stop="confirmReject"
          >
            <template v-if="!props.isLoadingOperation"> отклонить </template>
            <template v-if="props.isLoadingOperation">
              <Loader />
            </template>
          </PotokButton>
          <PotokButton
            theme="link"
            @click.stop="closeModalReject"
          >
            отмена
          </PotokButton>
        </div>
      </template>
    </ModalFull>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import { Loader, PotokButton } from 'potok-uikit';

const props = defineProps(['isSecondMarket', 'isLoadingOperation', 'item']);
const emits = defineEmits(['handleOperation']);

const isShowRejectModal = ref(false);
const comment = ref('');

const handleOperation = ({ status, commentValue }) => {
  emits('handleOperation', { status, comment: commentValue });
};

const handleReject = () => {
  isShowRejectModal.value = true;
};

const closeModalReject = () => {
  comment.value = '';
  isShowRejectModal.value = false;
};

const confirmReject = () => {
  handleOperation({
    status: 'rejected',
    commentValue: comment.value,
  });
};
</script>

<style scoped lang="scss">
@use '../../../../scss/variables.scss' as *;

.new-investments-sell-item-panel-actions {
  display: flex;
  margin-left: auto;

  @media (max-width: $size_991) {
    flex-direction: column;
    margin-left: initial;
  }
}

.panel-actions-reject {
  width: 117px;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

.panel-actions-cancel {
  width: 167px;

  @media (max-width: $size_991) {
    width: 100%;
  }
}

.reject-request-modal {
  :deep(.modal_block_container) {
    max-width: 440px;
  }

  :deep(.modal_block_title) {
    justify-content: center;
  }

  :deep(.modal_block_actions) {
    margin-top: 20px;
  }
}

.investments-reject-request-modal-text {
  text-align: center;
}

.investments-reject-request-modal-textarea {
  width: 100%;
  resize: none;
  padding: 14px 20px;
  border: none;
  border-radius: 8px;
  background: $background;

  &::placeholder {
    color: $grey;
  }
}

.modal-reject-actions {
  display: flex;
  flex-direction: column;
}

.modal-reject-actions-approve {
  height: 60px;
}
</style>
