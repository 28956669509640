<template>
  <section class="content-block">
    <div>
      <SettingsField
        name="Телефон"
        :value="`${formattedPhone}`"
        :divider="false"
      />
      <FieldEmail :user="user" />
      <template v-if="isUserRegistrated">
        <FieldPostAddress :user="user" />
        <FieldTelegram :user="user" />
        <SettingsField :name="'ФИО'" :value="userFullName" />

        <SettingsField :name="'Статус'">
          <template #value>
            <div
              v-if="
                user.passportProcessingStatus ===
                constants.passportStatus.APPROVED
              "
              class="status-confirm"
            >
              документы подтверждены
            </div>
            <div
              v-if="
                user.passportProcessingStatus ===
                constants.passportStatus.PENDING
              "
              class="status-pending"
            >
              документы на рассмотрении
            </div>
            <div
              v-if="
                user.passportProcessingStatus === constants.passportStatus.EMPTY
              "
              class="status-empty"
            >
              документы не загружены
            </div>
            <div
              v-if="
                user.passportProcessingStatus ===
                constants.passportStatus.REJECTED
              "
              class="status-rejected"
            >
              документы отклонены
            </div>
          </template>
        </SettingsField>
        <SettingsField
          v-if="user.passportProcessingComment"
          name="Комментарий"
          :value="user.passportProcessingComment"
        />
        <SettingsField name="ИНН" :value="user.inn" />
        <SettingsField
          name="Паспорт"
          :value="`${user.passportSeries} ${user.passportNumber}`"
        />
        <SettingsField
          name="адрес регистрации"
          :value="user.registrationAddress"
        />
        <SettingsField
          clickable-mode
          name="Скан паспорта"
          @field-click="openPassportModal"
        >
          <template #value>
            <div class="passports-container">
              <ImagePreview
                v-if="
                  isShowImagePreview && user && user.passportPageMainDocumentId
                "
                :image-id="user.passportPageMainDocumentId"
                class="passport mr-5"
                title="Разворот первой страницы"
              />
              <ImagePreview
                v-if="
                  isShowImagePreview &&
                  user &&
                  user.passportPageRegistrationDocumentId
                "
                :image-id="user.passportPageRegistrationDocumentId"
                class="passport"
                title="Страница с пропиской"
              />
            </div>
          </template>
        </SettingsField>
      </template>
    </div>

    <PotokButton
      v-if="isUserLoaded && !isUserRegistrated"
      @click="onClickRegistrate"
    >
      Завершить регистрацию
    </PotokButton>

    <ModalUploadPassport
      v-if="isShowModalUploadPassport"
      @close="closeModalUploadPassport"
    />
  </section>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { PotokButton } from 'potok-uikit';

import constants from '@/constants';
import ModalUploadPassport from '@/components/_generic/modals/ModalUploadPassport.vue';
import { formatLoginToPhoneNumber } from '@/utils/commonUtils/utils';
import ImagePreview from '../../components/Settings/ImagePreview.vue';
import FieldPostAddress from '../../components/Settings/FieldPostAddress.vue';
import FieldEmail from '../../components/Settings/FieldEmail.vue';

import { useStateStore } from '@/store/stateStore';
import SettingsField from '@/components/Settings/SettingsField';
import FieldTelegram from '@/components/Settings/FieldTelegram.vue';

const router = useRouter();
const store = useStateStore();

const isShowModalUploadPassport = ref(false);
const isShowImagePreview = ref(true);

const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});
const userFullName = computed(() => {
  return store.userFullName;
});
const isUserRegistrated = computed(() => {
  return store.isUserRegistrated;
});
const user = computed(() => {
  return store.state.user;
});
const formattedPhone = computed(() => {
  return formatLoginToPhoneNumber(store.state.user.login);
});
const openPassportModal = () => {
  isShowModalUploadPassport.value = true;
};
const onClickRegistrate = () => {
  router.push({
    name: 'main',
  });
};
const closeModalUploadPassport = (obj) => {
  isShowModalUploadPassport.value = false;
  if (obj.reinitImages) {
    isShowImagePreview.value = false;
    setTimeout(() => {
      isShowImagePreview.value = true;
    }, 500);
  }
};
</script>
<style lang="scss" scoped>
@use '../../scss/variables.scss' as *;

.block-title {
  @media (max-width: $size_767) {
    display: none;
  }
}

.content-block {
  padding: 0 10px 30px;

  @media (max-width: $size_767) {
    margin: 0;
    padding: 0 14px 16px;
  }
}

.passports-container {
  display: flex;
}

.passport {
  @media (max-width: $size_767) {
    max-width: 40%;
  }
}

.status-confirm {
  color: $tiffany;
}

.status-pending {
  color: $yellow;
}

.status-empty {
  color: $yellow;
}

.status-rejected {
  color: #ff7a00;
}
</style>
