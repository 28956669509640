<template>
  <div class="container container_wrapper" data-test="login-recover-step1">
    <div class="login_title">
      <div>восстановление пароля</div>
    </div>
    <div class="login_subtitle login_subtitle_restricted">
      введите ваш номер телефона
    </div>
    <div>
      <div class="login_actions">
        <div class="login_input_wrapper">
          <PotokInputLarge
            id="phone"
            v-model="localRawPhone"
            v-maska
            :data-maska="localMasks.phone"
            type="tel"
            name="phone"
            placeholder="+7 (999) 999-99-99"
            data-test="login-phone-input"
          />

          <template v-if="!v$.localRawPhone.$invalid">
            <PotokInputPassword
              id="password"
              v-model="localRawPassword"
              class="mt-3"
              placeholder="новый пароль"
              data-test="login-recover-step1-input"
              @update:model-value="onClickPressKeyPassword"
              @keyup.enter="onClickEnterPassword()"
            />
            <p
              v-if="!errorMessage"
              class="potok-text-body-1 potok-color-deepdive text_tips"
              data-test="login-recover-step1-length"
            >
              минимум 6 символов
            </p>
          </template>
          <div
            v-if="errorMessage"
            class="potok-text-body-1 potok-color-deepdive text_tips potok-color-red error_message"
            data-test="login-recover-step1-error-message"
          >
            {{ errorMessage }}
          </div>
        </div>
        <div class="mt-6">
          <PotokButton
            size="large"
            class="w-100"
            data-test="login-recover-step1-continue"
            @click="onClickEnterPassword()"
          >
            продолжить
          </PotokButton>

          <PotokButton
            theme="link"
            size="link"
            text="назад"
            class="w-100 mt-7 login-recover-back"
            data-test="login-recover-step1-back"
            @click="onClickBack"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, nextTick, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';

import { focusDomElementById } from '@/utils/commonUtils/utils';
import { convertRawPhoneToLogin } from '@/utils/phone/formatPhone';
import server from '@/server';
import validators from '@/validators';
import { PotokButton, PotokInputPassword, PotokInputLarge } from 'potok-uikit';
import { helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { vMaska } from 'maska/vue';
import masks from '@/utils/masks';

const emit = defineEmits(['changeStep']);

const router = useRouter();
const loading = ref(false);
const localRawPassword = ref(null);
const localRawPhone = ref(null);
const errorMessage = ref(null);

const isProduction = import.meta.env.MODE === 'production';

const localMasks = computed(() => {
  return {
    phone: masks.phone(),
  };
});

const isPhone = helpers.regex(
  /^(\+7[ ]\([0-9]{3}\)[ ])([0-9]{3}-)([0-9]{2}-)[0-9]{2}$/,
);
const validations = {
  localRawPhone: {
    required: validators.required,
    valid: isPhone,
  },
  localRawPassword: {
    required: validators.required,
  },
};

const v$ = useVuelidate(validations, { localRawPhone, localRawPassword });

const isValidPassword = () => {
  if (!localRawPassword.value) {
    errorMessage.value = 'Обязательное поле';
    return false;
  }
  if (localRawPassword.value.length < 6) {
    errorMessage.value = 'Минимальная длина пароля 6 символов';
    return false;
  }
  if (localRawPassword.value.length > 32) {
    errorMessage.value = 'Максимальная длина пароля 32 символов';
    return false;
  }
  if (!validators.passwordAlpha.$validator(localRawPassword.value)) {
    errorMessage.value =
      'Пароль должен состоять из строчных и/или заглавных букв латинского алфавита (A—Z), цифр и знаков препинания.';
    return false;
  }
  return true;
};

const onClickPressKeyPassword = () => {
  errorMessage.value = null;
};

const onClickEnterPassword = () => {
  if (!isValidPassword()) {
    return;
  }

  if (isProduction) {
    window.smartCaptcha.execute();
    return;
  }

  requestRecoveringAsync();
};

const onClickBack = () => {
  return router.push({ name: 'main' });
};

const smartcaptchaRender = () => {
  if (!window.smartCaptcha) {
    return;
  }

  window.smartCaptcha.render('captcha-container', {
    sitekey: `${import.meta.env.VITE_APP_SMART_CAPTCHA_KEY}`,
    invisible: true, // Сделать капчу невидимой
    hideShield: true,
    callback: smartCaptchaCallback,
  });
};

function smartCaptchaCallback(captchaToken) {
  requestRecoveringAsync(captchaToken);
}

const requestRecoveringAsync = (captchaToken) => {
  const query = {
    phone: convertRawPhoneToLogin(localRawPhone.value),
  };
  if (isProduction) {
    query['smart-token'] = captchaToken;
  }
  loading.value = true;
  return server.postUsersRecoverPassRequest
    .send(query)
    .pipe(onRequestRecoveringAsyncSuccess.bind(this, query), (error) => {
      loading.value = false;
      if (error?.data.message) {
        errorMessage.value = error.data.message;
      }
    })
    .exec();
};

const onRequestRecoveringAsyncSuccess = () => {
  if (isProduction) {
    window.smartCaptcha.reset();
  }
  loading.value = true;
  emit('changeStep', {
    nextStep: 'inputSMSCode',
    phone: localRawPhone.value,
    password: localRawPassword.value,
  });
};

onMounted(() => {
  nextTick(() => {
    focusDomElementById('password');
  });
  if (isProduction) {
    smartcaptchaRender();
  }
});
</script>
<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.login_subtitle_restricted {
  max-width: 340px;
  margin: 0 auto;
  margin-top: 12px;
}

.button-password {
  margin-top: 12px;
}

.login-recover-back {
  @media (max-width: $size_767) {
    margin-top: 20px;
  }
}
</style>
