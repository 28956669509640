<template>
  <PotokPage title="список эталонных займов">
    <template v-if="!isUserLoaded">
      <Loader />
    </template>

    <template v-if="isUserLoaded">
      <notification-message />
      <NeedJoinEula v-if="needJoinEula" />
      <RejectedPassport
        v-if="isShowRejectedPassportModal"
        @close="isShowRejectedPassportModal = false"
      />
      <UserMessage
        @open-rejected-passport="isShowRejectedPassportModal = true"
      />

      <div class="reference-loans-content">
        <Loader v-if="loadingLoans" />

        <LoansFiltersDesktop
          v-show="!loadingLoans"
          :filters="filters"
          :visible-filters="['rating']"
          class="mb-6"
          @change-filters="referenceLoansHandleChangeFilters"
        />

        <ReferenceLoansTable
          v-if="!loadingLoans && loans.length"
          :rows="loans"
          :total="totalLoans"
          :current-page="currentPage"
          :selected-page-range="selectedPageRange"
          @fetch-list="changeItemsPage($event.selectedPageRange, $event.offset)"
          @mobile-fetch-list="changeItemsPage($event.selectedPageRange, $event.offset, $event.isMobile)"
        />

        <p v-if="!loadingLoans && !loans.length">
          Новые кредитные заявки поступают к нам каждый день и после тщательной
          проверки появятся здесь.
        </p>
      </div>
    </template>
  </PotokPage>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStateStore } from '@/store/stateStore';
import constants from '@/constants';
import server from '@/server';

import { Loader, PotokPage } from 'potok-uikit';
import NotificationMessage from '@/components/NotificationMessage/NotificationMessage.vue';
import NeedJoinEula from '@/components/_generic/messages/NeedJoinEula.vue';
import RejectedPassport from '@/components/_modals/RejectedPassport.vue';
import UserMessage from '@/components/_generic/messages/UserMessage.vue';
import ReferenceLoansTable from '@/components/ReferenceLoans/ReferenceLoansTable.vue';
import LoansFiltersDesktop from '@/components/Loans/LoansFilters/LoansFiltersDesktop.vue';

import LoansFiltersComposable from '@/composables/loansFiltersComposable';

const { filters, onChangeFilters } = LoansFiltersComposable();

const store = useStateStore();

const isShowRejectedPassportModal = ref(false);

const isUserLoaded = computed(() => {
  return store.isUserLoaded;
});

const loans = ref([]);
const totalLoans = ref(0);
const loadingLoans = ref(true);

const currentPage = ref(1);
const selectedPageRange = ref(20);

const selectedCompanyId = computed(() => {
  return store.selectedCompanyId;
});

const needJoinEula = computed(() => {
  if (
    store.selectedCompany &&
    store.selectedCompany.typeId !== constants.companyTypes.person
  ) {
    return (
      store.selectedCompany.isEulaApproved &&
      !store.selectedCompany.isAgreeRules
    );
  }
  return false;
});

const referenceLoansHandleChangeFilters = (filters) => {
  onChangeFilters(filters);

  getReferenceLoansAsync()
}

const changeItemsPage = (childSelectedPageRange, childOffset, isMobile) => {
  selectedPageRange.value = childSelectedPageRange;
  currentPage.value = Math.ceil(childOffset / childSelectedPageRange) + 1;
  getReferenceLoansAsync(childSelectedPageRange, childOffset, isMobile);
}
const getReferenceLoansAsync = (limit = 20, offset = 0, isMobile = false) => {
  if (!selectedCompanyId.value) {
    return;
  }

  loadingLoans.value = true;

  const query = {
    companyId: selectedCompanyId.value,
    limit,
    offset,
  };

  const ratingActiveFiltersList = filters.value.ratingType.filter((el) => el.value);

  if (ratingActiveFiltersList.length) {
    const simpleFilterValuesList = ratingActiveFiltersList.map((el) => el.slug);
    query.modelBucket = simpleFilterValuesList;
  }

  return server.getProjectsReferenceList
    .send(query, {})
    .pipe(onGetReferenceLoansAsyncSuccess.bind(this, isMobile), () => {
      loadingLoans.value = false;
    })
    .exec();
};
const onGetReferenceLoansAsyncSuccess = (noClearItems, obj) => {
  if (!obj || !obj.data) {
    return;
  }


  if (!noClearItems) {
    loans.value = [];
  }
  
  loans.value.push(...obj.data.rows);
  totalLoans.value = obj.data.count;
  loadingLoans.value = false;
};

watch(selectedCompanyId, async () => {
  getReferenceLoansAsync();
});
</script>
