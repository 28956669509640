<template>
  <div class="banner-rotation-mobile">
    <div class="banner-wrapper">
      <slot v-if="currentBanner === 1" name="banner1"></slot>
      <slot v-if="currentBanner === 2" name="banner2"></slot>
    </div>
    <div class="markers">
      <div
        class="marker"
        :class="{ 'marker-active': currentBanner === 1 }"
        @click="selectBanner(1)"
      ></div>
      <div
        class="marker"
        :class="{ 'marker-active': currentBanner === 2 }"
        @click="selectBanner(2)"
      ></div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const currentBanner = ref(1);

const selectBanner = (banner) => {
  currentBanner.value = banner;
};
</script>
<style scoped lang="scss">
@use '../../../scss/variables.scss' as *;

.banner-rotation-mobile {
  margin-bottom: 30px;
}

.banner-wrapper {
  min-height: 200px;
}

.markers {
  display: flex;
  justify-content: center;
  align-items: end;
  margin-top: 14px;
}

.marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e5e9ee;
  margin: 0 10px;
  color: $input;
  cursor: pointer;
}

.marker-active {
  background-color: $breakwater;
}
</style>
