<template>
  <div class="container container_wrapper">
    <div class="login-first-wrapper">
      <div class="login_title">
        <div>регистрация</div>
      </div>
      <div class="login_subtitle">
        <div
          class="login_subtitle_restricted potok-text-body-1 mt-3 text-center"
        >
          для подтверждения мы отправили код на номер
          <span>{{ props.rawPhone }}</span>
        </div>
      </div>
      <div class="login_actions">
        <div class="login_input_wrapper">
          <input
            id="idSMSConfirm"
            v-model="localRawCode"
            class="form-control"
            type="text"
            :readonly="loading"
            placeholder="код из смс"
            @input="onClickPressKeyCode()"
            @keyup.enter="onClickEnterSMSConfirm()"
          />
          <div v-if="errorMessageSMSCode" class="potok-color-red error_message">
            {{ errorMessageSMSCode }}
          </div>
        </div>
        <PotokButton
          size="large"
          class="button-continue"
          :disabled="loading"
          @click="onClickEnterSMSConfirm()"
        >
          зарегистрироваться
        </PotokButton>
        <template v-if="leftTime > 0">
          <div
            class="countdown potok-text-body-1 potok-color-deepdive text-center mt-3"
          >
            <div class="countdown_span">
              отправить ещё через
              <span>{{ leftTime }} c</span>
            </div>
          </div>
        </template>
        <template v-if="leftTime === 0">
          <PotokButton
            theme="link"
            size="link"
            class="mt-3"
            :disabled="loading"
            @click="onClickRequestRetryCode()"
          >
            выслать СМС повторно
          </PotokButton>
        </template>

        <PotokButton
          theme="link"
          class="mt-3"
          size="link"
          @click="onClickBack()"
        >
          назад
        </PotokButton>
      </div>
    </div>
    <div class="login-second-wrapper">
      <div class="potok-text-body-3 footer_message">
        нажимая «Продолжить», я соглашаюсь на обработку персональных данных в
        соответствии с
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Politika_obrabotka_pers_dannyh_aktualnaja_redakcija.docx"
          target="_blank"
          >политикой</a
        >, ознакомлен с
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Правила Инвестиционной платформы Поток.pdf"
          target="_blank"
          >Правилами платформы</a
        >, и согласен с использованием
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Соглашение участников электронного взаимодействия_Инвестплатформа Поток.pdf "
          target="_blank"
        >
          простой электронной подписи
        </a>
      </div>
    </div>
  </div>
  <ModalFull
    v-if="errorMessage"
    :title="'внимание'"
    class="error_message"
    @close="onCloseErrorHandler"
  >
    <template #content>
      <div class="modal-text" v-html="errorMessage"></div>
    </template>
    <template #actions>
      <div class="button-center">
        <PotokButton @click.stop="onCloseErrorHandler">
          {{ 'понятно' }}
        </PotokButton>
      </div>
    </template>
  </ModalFull>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import server from '@/server';
import tracker from '@/tracker';
import { convertRawPhoneToLogin } from '@/utils/phone/formatPhone';
import countdownComposable from '@/composables/countdownComposable';
import { useStateStore } from '@/store/stateStore';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const props = defineProps({
  rawPhone: {
    type: String,
    default: '',
  },
  rawPassword: {
    type: String,
    default: '',
  },
  rawEmail: {
    type: String,
    default: '',
  },
  smsId: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['changeStep']);

const { leftTime, countdownReset, countdown } = countdownComposable();

const localRawCode = ref(null);
const errorMessageSMSCode = ref(null);
const loading = ref(null);
const errorMessage = ref(null);

const store = useStateStore();
const route = useRoute();
const router = useRouter();

const isValidSmsCode = () => {
  if (!localRawCode.value) {
    errorMessageSMSCode.value = 'Обязательное поле';
    return false;
  }
  return true;
};

const onClickPressKeyCode = () => {
  errorMessageSMSCode.value = null;
};

const onClickEnterSMSConfirm = () => {
  if (loading.value || !isValidSmsCode()) {
    return;
  }
  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
    smsCode: localRawCode.value,
    smsId: props.smsId,
  };
  if (route.query.ref) {
    query.refCode = route.query.ref;
  }
  loading.value = true;
  return server.postUsersRegisterConfirm
    .send(query)
    .pipe(
      onConfirmRegisterAsyncSuccess.bind(this, query),
      onConfirmRegisterAsyncError.bind(this),
    )
    .exec();
};
const onConfirmRegisterAsyncSuccess = (query, { data }) => {
  loading.value = false;

  if (data.isSuccessful) {
    tracker.queue(tracker.commands.SEND, 'Investor_LK_Insert_Code_Success');
    tracker.queue(
      tracker.commands.SEND,
      'Adg_Investory_Shag5_Registracija_podtverdil_po_sms',
    );
    tracker.generalPostClick({ value: 'a7' });
    countdown.stop();
    store.merge({
      session: {
        login: query.phone,
        token: data.token,
      },
    });
    return router.push({
      name: 'main',
    });
  }
};

const onClickRequestRetryCode = () => {
  loading.value = true;
  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
  };
  return server.postUsersRegisterRequestRetry
    .send(query)
    .pipe(
      onRequestRegisterAsyncSuccess.bind(this, query),
      onConfirmRegisterAsyncError.bind(this),
    )
    .exec();
};

const onRequestRegisterAsyncSuccess = () => {
  countdownReset();
  loading.value = false;
};

const onClickBack = () => {
  emit('changeStep', {
    nextStep: 'inputPassword',
  });
};

const onCloseErrorHandler = () => {
  errorMessage.value = null;
  emit('changeStep', {
    nextStep: 'inputPassword',
  });
};

const onConfirmRegisterAsyncError = ({ data }) => {
  loading.value = false;
  if (data?.type === 'business_logic_error' && data?.message) {
    errorMessage.value = data.message;
  }
};

onMounted(() => {
  countdownReset();
});
</script>
<style lang="scss" scoped>
.content_wrapper {
  min-height: 500px;
}

.login_actions {
  margin-bottom: 50px;
}

.footer_message {
  padding: 0 10px;
  margin-bottom: 30px;
}
</style>
