<template>
  <div>
    <div class="row row_first">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.search"
          class="form-control filter_item"
          placeholder="поиск по заемщику, id займа"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.purchaseDeals"
          v-maska
          :data-maska="localMasks.purchaseDeals"
          class="form-control filter_item"
          placeholder="ставка, %"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <vue-datepicker
          v-model="form.loanFundedAt"
          class="form-control filter_item"
          :locale="ru"
          :input-format="'dd.MM.yyyy'"
          placeholder="дата инвестирования"
        ></vue-datepicker>
        <button type="button" class="clear" @click="form.loanFundedAt = null">
          <span>&times;</span>
        </button>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <vue-datepicker
          v-model="form.loanRefundedAt"
          class="form-control filter_item"
          :locale="ru"
          :input-format="'dd.MM.yyyy'"
          placeholder="дата погашения"
        ></vue-datepicker>
        <button type="button" class="clear" @click="form.loanRefundedAt = null">
          <span>&times;</span>
        </button>
      </div>
    </div>
    <div class="row row_second">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentAmount"
          v-maska
          :data-maska="localMasks.digits"
          class="form-control filter_item"
          placeholder="сумма инвестиции"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentRepayment"
          v-maska
          :data-maska="localMasks.digits"
          class="form-control filter_item"
          placeholder="погашено"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentIncome"
          v-maska
          :data-maska="localMasks.digits"
          class="form-control filter_item"
          placeholder="процентный доход"
          @keyup="updateFilters"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, computed, watch } from 'vue';
import VueDatepicker from 'vue3-datepicker';
import ru from 'date-fns/locale/ru';
import { vMaska } from 'maska/vue';
import masks from '@/utils/masks';

const emit = defineEmits(['updateFilters']);

const form = reactive({
  search: null,
  purchaseDeals: null,
  loanFundedAt: null,
  loanRefundedAt: null,
  myInvestmentAmount: null,
  myInvestmentRepayment: null,
  myInvestmentIncome: null,
});

const localMasks = computed(() => ({
  purchaseDeals: masks.digits(2),
  digits: masks.digits(15),
}));

const updateFilters = () => {
  emit('updateFilters', form);
};

watch(() => form.loanFundedAt, updateFilters);
watch(() => form.loanDue, updateFilters);
</script>
<style lang="scss" scoped>
@use '../../../../../scss/variables.scss' as *;

.row {
  @media (max-width: $size_767) {
    margin: 0;
  }
}

.row_second {
  margin-top: 20px;

  @media (max-width: $size_767) {
    margin-top: 0;
  }
}

.col-xs-12 {
  @media (max-width: $size_767) {
    padding: 0;
  }
}
</style>
