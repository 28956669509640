<template>
  <section class="page callbacks">
    <PotokImg loading-icon loading-theme="dark" class="mb-3" />

    данные обрабатываются
  </section>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import PotokImg from '@/components/_generic/PotokImg.vue';
import server from '@/server';

const route = useRoute();

const stateQuery = computed(() => {
  return route.query.state || '';
});

const stateCode = computed(() => {
  return route.query.code || '';
});

const postEsiaAuth = async () => {
  const query = {
    state: stateQuery.value,
    code: stateCode.value,
  };

  return server.postEsiaAuth
    .send(query)
    .pipe(onFetchEsiaAuthSuccess, onFetchEsiaAuthError)
    .exec();
};
const onFetchEsiaAuthSuccess = () => {
  window.location.assign('/?publicService=true');
};
const onFetchEsiaAuthError = (error) => {
  if (error.response.status === 422) {
    window.location.assign(
      `/?publicService=true&error=true&message=${error.data.message}`,
    );
  } else {
    window.location.assign('/');
  }
};

const init = () => {
  postEsiaAuth();
};

onMounted(() => {
  init();
});
</script>

<style scoped lang="scss">
.callbacks {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
