<template>
  <div class="confirm">
    <ModalFull v-if="confirm.message" :title="confirm.title" @close="close">
      <template #content>
        <div class="modal-text" v-html="confirm.message"></div>
      </template>
      <template #actions>
        <PotokButton
          class="button_ok"
          :disabled="loading"
          @click.stop="onClickOk"
        >
          {{ confirm.action_text ? confirm.action_text : 'Ок' }}
        </PotokButton>
        <PotokButton
          theme="secondary"
          :disabled="loading"
          @click.stop="close"
        >
          Отмена
        </PotokButton>
      </template>
    </ModalFull>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { PotokButton } from 'potok-uikit';
import { useStateStore } from '@/store/stateStore';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';

const store = useStateStore();
const loading = ref(false);

const confirm = computed(() => {
  return store.state.confirm;
});
const message = computed(() => {
  return store.state.confirm.message;
});
watch(message, () => {
  loading.value = false;
});

const close = () => {
  store.removeConfirm();
  loading.value = false;
};
const onClickOk = () => {
  if (store.state.confirm.callback) {
    loading.value = true;
    store.state.confirm.callback(() => {
      loading.value = false;
      store.removeConfirm();
    });
  }
};
</script>

<style lang="scss" scoped>
@use '../../../scss/variables.scss' as *;

.confirm {
  :deep(.modal_block_container) {
    max-width: 500px;
  }
}

.modal-text {
  text-align: center;
}

.button_ok {
  margin-right: 20px;

  @media (max-width: $size_767) {
    margin-right: initial;
    margin-bottom: 10px;
  }
}
</style>
