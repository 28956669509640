export default `
<div style='text-transform:none' class='future-rate-modal'>
  <div class="future-rate-normal-text">
    Эффективная ставка отражает реальный доход с учетом капитализации, то есть прибавления процентов к общей сумме депозита и начисления процентов в следующем периоде на увеличенную сумму.
  </div>
  <div class='future-rate-margin-top'><b class='future-rate-normal-text'>Формула расчета эффективной ставки:</b></div>
  <div class="future-rate-formule-desc">
    <b class='future-rate-normal-text'>ЭС</b> = ((1+НС/100 * П) П * Г — 1) * 100 Г<br>
    Где <b class='future-rate-normal-text'>ЭС</b> — эффективная ставка;<br>
    <b class='future-rate-normal-text'>НС</b> — номинальная ставка в процентах;<br>
    <b class='future-rate-normal-text'>П</b> — число периодов капитализации вклада. Для ежедневной капитализации П = 365 или 366, ежеквартальной — П = 4, ежемесячной — П = 12;<br>
    <b class='future-rate-normal-text'>Г</b> — срок вклада в годах.
  </div>
</div>
`;
