<template>
  <PotokContainer background="#F2F4F8" class="period-slider-container mb-5">
    <div class="potok-color-grey mb-4">
      выберите срок инвестирования
    </div>

    <div class="potok-text-h2-light">
      {{ investmentPeriod }} месяц{{
        pluralize(investmentPeriod, [
          '',
          'а',
          'ев',
        ])
      }}
    </div>

    <PotokRangeSlider
      v-model="investmentPeriod"
      :values="[3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
      :marks="[3, 6, 9, 12]"
      @change="changeInvestmendPeriod"
    />
  </PotokContainer>
</template>

<script setup>
import { ref } from 'vue';
import { PotokContainer } from 'potok-uikit';
import { pluralize } from '@/utils/commonUtils/libUtils';
import PotokRangeSlider from '@/components/_generic/PotokRangeSlider.vue';

const investmentPeriod = ref(3);

const emits = defineEmits(['change']);

const changeInvestmendPeriod = (value) => {
  emits('change', value);
}
</script>

<style lang="scss" scoped>
.potok-container .period-slider-container {
  padding-bottom: 40px;
}
</style>