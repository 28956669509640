<template>
  <div class="total_wrapper">
    <div class="icon_receive_square"></div>
    <div class="content_title">отчет</div>
    <PotokButton
      theme="link"
      size="link"
      class="button_email"
      @click="onClickSendEmail"
    >
      выслать на почту
      <span class="icon_arrow_long_right"></span>
    </PotokButton>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

const emit = defineEmits(['sendEmail']);
const onClickSendEmail = () => {
  emit('sendEmail');
};
</script>
<style lang="scss" scoped>
.button_email {
  margin-top: 8px;
  font-size: 20px;
  line-height: 26px;
}
</style>
