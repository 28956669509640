<template>
  <div class="wrapper__content wrapper__content__has_background">
    <div class="wrapper__header">
      <div class="wrapper__header__left" />
      <header class="header header__container">
        <component :is="headerComponentName" />
      </header>
      <div class="wrapper__header__right" />
    </div>

    <div class="global-messages">
      <PotokMessage v-if="isShowInActiveUser">
        <div class="inactive-user-message">
          <span>
            {{ inactiveUserMessageText }}
          </span>
          <PotokButton
            theme="linkIconUpload"
            size="link"
            no-full-width-tablet
            :text="inactiveUserButtonText"
            class="messages-button"
            @click="toRefillPage"
          />
        </div>
      </PotokMessage>
    </div>

    <main>
      <router-view name="content" />
    </main>

    <Footer />

    <tips-handler />

    <DebugTeleport v-if="isRunTeleport" />

    <UiNotification
      :timer="notificationData.timer"
      :title="notificationData.title"
      :message="notificationData.message"
      :is-error="notificationData.isError"
      :btn-callback="notificationData.btnCallback"
      :btn-text="notificationData.btnText"
    />

    <ConsentModal
      v-if="isConsentModalActive"
      :description="consentsCreditDescription"
      :button-text="consentsCreditButtonText"
      :reject-button-text="consentsCreditRejectButtonText"
    />
    <ModalRefferalMessage v-if="isShowModalRefferal" @close="closeRefferalModalHandler"/>
    <BonusForDepositModal
      v-if="isShowBonusForDepositModal"
      :title="modalData.title"
      :content="modalData.content"
      @close="closeBonusModal"
    />
    <TelegramDialog v-if="isShowTelegramDialog" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import server from '@/server';
import { useStateStore } from '@/store/stateStore';
import { usePromotions } from '@/components/Dashboard/usePromotions';

import potokFeedback from '@/feedback.js';
import PotokMessage from '@/components/_generic/PotokMessage.vue';
import TipsHandler from '@/components/_generic/modals/TipsHandler.vue';
import ModalRefferalMessage from '@/components/_generic/modals/ModalRefferalMessage.vue';
import ConsentModal from '@/components/_modals/ConsentModal.vue';
import UiNotification from '@/components/_generic/notification/UiNotification.vue';
import BonusForDepositModal from '@/components/_modals/BonusForDepositModal';
import Footer from '@/components/Footer/Footer.vue';
import HeaderUnauth from '@/components/Header/HeaderUnauth.vue';
import Header from '@/components/Header/Header.vue';
import DebugTeleport from '@/components/Teleport/Teleport.vue';
import TelegramDialog from '@/components/dialogs/TelegramDialog.vue';

const store = useStateStore();
const router = useRouter();
const route = useRoute();

const inactiveUserMessageText = ref('');
const inactiveUserButtonText = ref('');

const consentsCreditButtonText = ref('');
const consentsCreditRejectButtonText = ref('');
const consentsCreditDescription = ref('');
const isConsentsCreditAvailable = ref(false);

const { modalData, isShowBonusForDepositModal, loadTypicalPromotion } =
  usePromotions();

const isRunTeleport = ref(import.meta.env.MODE === 'mock');

const headerComponentName = computed(() =>
  store.isUnauthenticated ? HeaderUnauth : Header,
);
const isShowModalRefferal = computed(
  () => store.state.modals.isShowModalRefferal,
);
const notificationData = computed(() => store.state.notification);

const isShowTelegramDialog = computed(
  () => !store.state.loaders.isUserLoading && store.state.user.isShowTgBanner,
);
const isShowInActiveUser = computed(
  () =>
    store.state.features.general.inactiveUser?.enabled &&
    inactiveUserMessageText.value,
);
const isConsentModalActive = computed(() => {
  return store.state.features.general.consentForPotokCredit?.enabled &&
    isConsentsCreditAvailable.value
})

const toRefillPage = () => {
  router.push({ name: 'refill' });
};

const closeBonusModal = () => {
  isShowBonusForDepositModal.value = false;
};

const getFeaturesAsync = () => {
  return server.getFeatures
    .send()
    .pipe(onGetFeaturesAsyncSuccess, () => {
      store.state.loaders.isFeaturesLoading = false;
    })
    .exec();
};

const onGetFeaturesAsyncSuccess = ({ data }) => {
  if (!store.state.features) {
    store.state.features = { general: {} };
  }
  store.state.features.general = data;
  store.state.loaders.isFeaturesLoading = false;

  if (store.state.features.general.inactiveUser?.enabled) {
    getInactiveUserFeature();
  }

  if (store.state.features.general?.typicalPromotion?.enabled) {
    loadTypicalPromotion();
  }

  if (store.state.features.general?.consentForPotokCredit?.enabled) {
    getConsentsCreditFeature();
  }
};

const getConsentsCreditFeature = () => {
  return server.getFeaturesConsentsCredit
    .send()
    .pipe(onGetFeaturesConsentsCreditSuccess, () => {})
    .exec();
};

const onGetFeaturesConsentsCreditSuccess = ({ data, response }) => {
  // У юзера в данный момент фича не доступна
  if (response.status === 204) {
    return;
  }

  consentsCreditButtonText.value = data.agreeButtonLabel;
  consentsCreditRejectButtonText.value = data.remindLaterButtonLabel;
  consentsCreditDescription.value = data.bannerDescription;
  isConsentsCreditAvailable.value = true;
};

const getInactiveUserFeature = () => {
  return server.getFeaturesInactiveUser
    .send()
    .pipe(onGetFeaturesInactiveUserSuccess, () => {})
    .exec();
};

const onGetFeaturesInactiveUserSuccess = ({ data }) => {
  inactiveUserButtonText.value = data.buttonLabel;
  inactiveUserMessageText.value = data.cardDescription;
};

const firstInvestmentTrigger = async () => {
  const lastFisitDate = new Date(store.state.user.lastVisitedAt);
  const firstIncomeDate = new Date(store.state.user.firstIncomeAt);

  if (lastFisitDate < firstIncomeDate) {
    potokFeedback.trigger('first_account_investment');
  }
}

const closeRefferalModalHandler = () => {
  let currentQuery = route.query;

  if (currentQuery.refferalmodal) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('refferalmodal');
      const newQuery = urlParams.toString();
      const newPath = route.path + (newQuery ? '?' + newQuery : '');
      router.replace(newPath);
  }
}

setTimeout(() => {
  if (route.query.refferalmodal) {
    store.state.modals.isShowModalRefferal = true;
  }
}, 1000)

onMounted(async () => {
  getFeaturesAsync();

  // запускаем триггеры VOC при инициализации приложения
  await potokFeedback.init({
    userUUID: String(store.state.user.id),
    userName: store.state.user.name,
  });
  if (store.state.user.lastVisitedAt && store.state.user.firstIncomeAt) {
    firstInvestmentTrigger();
  }
  potokFeedback.trigger('account_replenishment_process');
});
</script>

<style lang="scss" scoped>
@use '../scss/variables.scss' as *;

.global-messages {
  width: 1180px;
  margin: 0 auto;
  padding: 80px 15px 0;

  @media (max-width: $size_1200) {
    width: 970px;
  }

  @media (max-width: $size_991) {
    width: 750px;
  }

  @media (max-width: $size_767) {
    width: 100%;
    padding: 44px 15px 0;
  }
}

.inactive-user-message {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: $size_767) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.messages-button {
  @media (max-width: $size_767) {
    margin-top: 20px;
  }
}
</style>
