<template>
  <section class="balances">
    <PotokMessage>
      <div>
        <div>
          <div style="color: black; line-height: 20px; font-weight: 500">
            В связи со вступлением в силу Федерального закона №259 мы обновили
            <a
              href="https://drive.google.com/file/d/1Ud1KsbnTLGF7Xxcyz_TUW_XqMR4N0v3-/view"
              target="_blank"
              >Правила</a
            >. Пожалуйста, ознакомьтесь с новыми документами и проверьте
            персональные данные, чтобы продолжить инвестирование на платформе.
          </div>
        </div>
        <div>
          <PotokButton
            style="margin-top: 10px"
            @click="redirectToSignRules()"
          >
            Ознакомиться
          </PotokButton>
        </div>
      </div>
    </PotokMessage>
  </section>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { PotokButton } from 'potok-uikit';
import PotokMessage from '@/components/_generic/PotokMessage.vue';
import constants from '@/constants';
import { useStateStore } from '@/store/stateStore';

const store = useStateStore();
const router = useRouter();

const redirectToSignRules = () => {
  store.merge({
    buffer: {
      depositOperationId: constants.depositOperations.signingEula,
    },
  });

  router.push({
    name: 'investorSignRulesCheckPersonalData',
  });
};
</script>
