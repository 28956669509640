<template>
  <div>
    <div class="step_description">
      <div v-html="props.data?.text"></div>
    </div>
    <div class="fields">
      <div class="col-md-8 col-sm-7">
        <div v-for="(item, i) in props.fields" :key="i" class="field-item">
          <FormField
            :divider="i > 0"
            :name="item.name"
            :value="item.value"
            name-width="5"
            value-width="7"
            class="field-item-container"
          />
          <i
            v-if="!store.isAlfaCustomer"
            class="icon_clipboard"
            :class="{ icon_clipboard_first: i === 0 }"
            @click="onClickCopy(item)"
          />
          <div v-if="item.copied" class="copied">👍 скопировано</div>
        </div>
      </div>
      <div class="col-md-4 col-sm-5 p-0">
        <div class="qr-wrapper-mobile">
          <vue-qr-code-payment
            id="qrcode"
            ref="qrcode"
            class="code_payment"
            :payee-name="props.paymentValues.payeeName"
            :payee-inn="props.paymentValues.payeeInn"
            :payee-kpp="props.paymentValues.payeeKpp.value"
            :payee-personal-acc="props.paymentValues.payeePersonalAcc"
            :payee-bank-name="props.paymentValues.payeeBankName"
            :payee-bank-bic="props.paymentValues.payeeBankBic"
            :payee-bank-corresp-acc="props.paymentValues.payeeBankCorrespAcc"
            :purpose="props.paymentValues.purpose"
          >
          </vue-qr-code-payment>
          <div class="qr-wrapper-mobile-text">
            отсканируйте qr код в мобильном приложении банка
          </div>
          <PotokButton
            theme="link"
            size="link"
            @click="isShowMessageHelp = true"
          >
            как отсканировать из галлереи?
          </PotokButton>
          <button
            type="button"
            class="button-download"
            @click="onClickDownloadQRCode"
          >
            <i class="icon-download" />
            скачать QR-код
          </button>
        </div>
      </div>
    </div>
    <ModalFull
      v-if="isShowMessageHelp"
      title="как отсканировать QR-код из галереи?"
      class="step-balance-qr-modal"
      @close="isShowMessageHelp = false"
    >
      <template #content>
        <div v-html="messageHelp"></div>
      </template>
    </ModalFull>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { PotokButton } from 'potok-uikit';

import FormField from '@/components/_generic/forms/FormField.vue';
import VueQrCodePayment from '@/components/_generic/VueQrCodePayment';
import ModalFull from '@/components/_generic/modals/ModalFull.vue';
import messageHelp from '@/components/Refill/messageHelp.js';
import { useStateStore } from '@/store/stateStore';

const props = defineProps({
  fields: {
    type: Array,
  },
  paymentValues: {
    type: Object,
  },
  data: {
    type: Object,
    required: false,
  },
});

const store = useStateStore();

const isShowMessageHelp = ref(false);

const onClickDownloadQRCode = () => {
  const canvas = document.querySelector('#qrcode');
  const dataURL = canvas.toDataURL('image/png', 1.0);
  const uri = dataURL;
  const link = document.createElement('a');
  link.href = uri;
  link.download = 'potok_qrcode_payment';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const onClickCopy = (item) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(item.value).then(function () {
      item.copied = true;
      setTimeout(() => {
        item.copied = false;
      }, 2000);
    });
  }
};
</script>
<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.fields {
  margin-top: 20px;
}

.field-item .icon_clipboard_first {
  @media (max-width: $size_767) {
    margin-top: 0;
  }
}

.field-item-container {
  width: 100%;
}

.code_payment {
  width: 100% !important;
  height: 100% !important;
  margin: 0 auto;
  max-width: 292px;
  max-height: 292px;
}

.step_description {
  max-width: 448px;
  margin-top: 20px;
  padding: 0 15px;
}

.qr-text-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $grey;
  margin: auto;
  margin-bottom: 20px;
}

.qr-wrapper-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;

  .qr-wrapper-mobile-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-transform: lowercase;
    margin-top: 13px;
  }
}

.field-item {
  position: relative;

  @media (max-width: $size_767) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  i {
    display: none;

    @media (max-width: $size_767) {
      display: block;
      margin-top: 30px;
      position: absolute;
      right: 0;
    }
  }
}

.button-download {
  height: 44px;
  width: 177px;
  margin-left: 14px;
  text-transform: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $deep_dive;
  background: #fff;
  border: 1px solid $input;
  margin-top: 20px;
}

.copied {
  position: absolute;
  right: 20px;
  top: 30px;
}

:deep(.item_value) {
  max-width: 240px;
}
</style>
