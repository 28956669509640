<template>
  <div class="container container_wrapper" data-test="login-register-step2">
    <div class="login-first-wrapper">
      <div class="login_title text-no-transform">
        <div>укажите почту и ник в Telegram</div>
      </div>
      <div class="login_subtitle mt-3">
        <div class="login_subtitle_restricted">
          почта потребуется для восстановления пароля, а мессенджер мы
          используем для оперативной связи по важным вопросам
        </div>
      </div>
      <div class="login_actions mt-3">
        <div class="login_input_wrapper">
          <input
            id="email"
            v-model="localRawEmail"
            class="form-control"
            type="email"
            :readonly="loading"
            placeholder="почта"
            data-test="login-register-step2-input-email"
            @input="onClickPressKeyEmail"
            @keyup.enter="onClickContinue"
          />
          <div
            v-if="errorMessageEmail"
            class="potok-color-red error_message"
            data-test="login-register-step2-error-message-email"
          >
            {{ errorMessageEmail }}
          </div>
        </div>
        <div class="login_input_wrapper mt-3">
          <input
            id="telegram"
            v-model="localRawTelegram"
            class="form-control"
            type="text"
            :readonly="loading"
            placeholder="ник в Telegram"
            data-test="login-register-step2-input-telegram"
            @keyup.enter="onClickContinue"
          />
          <div
            v-if="errorMessageTelegram"
            class="potok-color-red error_message"
            data-test="login-register-step2-error-message-telegram"
          >
            {{ errorMessageTelegram }}
          </div>
        </div>
        <PotokButton
          text="продолжить"
          size="large"
          class="mt-6"
          data-test="login-register-step2-continue"
          @click="onClickContinue"
        />

        <PotokButton
          theme="link"
          class="mt-3"
          size="link"
          text="назад"
          data-test="login-register-step2-back"
          @click="onClickBack"
        />
      </div>
    </div>
    <div class="login-second-wrapper">
      <div
        class="potok-text-body-3 footer_message"
        data-test="login-register-step2-rules"
      >
        нажимая «Продолжить», я соглашаюсь на обработку персональных данных в
        соответствии с
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Politika_obrabotka_pers_dannyh_aktualnaja_redakcija.docx"
          target="_blank"
          >политикой</a
        >, ознакомлен с
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Правила Инвестиционной платформы Поток.pdf"
          target="_blank"
          >Правилами платформы</a
        >, и согласен с использованием
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Соглашение участников электронного взаимодействия_Инвестплатформа Поток.pdf"
          target="_blank"
        >
          простой электронной подписи
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue';
import validators from '@/validators';
import tracker from '@/tracker';
import server from '@/server';
import { convertRawPhoneToLogin } from '@/utils/phone/formatPhone';
import convertUtmsToQueryString from '@/utils/utm/convertUtm';
import { useStateStore } from '@/store/stateStore';
import { PotokButton } from 'potok-uikit';

const props = defineProps({
  rawPhone: {
    type: String,
    default: '',
  },
  rawPassword: {
    type: String,
    default: '',
  },
  loadingRegister: {
    type: Boolean,
    default: false,
  },
  errorMessageRegister: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['changeStep']);

const store = useStateStore();
const localRawEmail = ref(null);
const localRawTelegram = ref(null);

const errorMessageEmail = ref(null);
const errorMessageTelegram = ref(null);

const loading = ref(null);

const isProduction = import.meta.env.MODE === 'production';

const isEmailValid = () => {
  if (!localRawEmail.value) {
    errorMessageEmail.value = 'Обязательное поле';
    return false;
  }
  if (!validators.email(localRawEmail.value)) {
    errorMessageEmail.value = 'Неверный формат Email';
    return false;
  }
  return true;
};

const isTelegramValid = () => {
  if (!localRawTelegram.value) {
    return true;
  }
  if (localRawTelegram.value.length > 32) {
    errorMessageTelegram.value =
      'ник Telegram должен содержать максимум 32 символа';
    return false;
  }
  if (localRawTelegram.value.length && localRawTelegram.value.length < 4) {
    errorMessageTelegram.value =
      'ник Telegram должен содержать минимум 4 символа';
    return false;
  }
  if (
    localRawTelegram.value.length &&
    /[а-яА-ЯёЁ']+/.test(localRawTelegram.value)
  ) {
    errorMessageTelegram.value = 'в нике не может быть русских букв';
    return false;
  }

  return true;
};

const onClickPressKeyEmail = () => {
  errorMessageEmail.value = null;
};

const onClickContinue = () => {
  if (!isEmailValid() || !isTelegramValid()) {
    return;
  }
  if (isProduction) {
    window.smartCaptcha.execute();
    return;
  }

  requestRegisterAsync();
};

const smartcaptchaRender = () => {
  if (!window.smartCaptcha) {
    return;
  }

  window.smartCaptcha.render('captcha-container', {
    sitekey: `${import.meta.env.VITE_APP_SMART_CAPTCHA_KEY}`,
    invisible: true, // Сделать капчу невидимой
    hideShield: true,
    callback: smartCaptchaCallback,
  });
};

function smartCaptchaCallback(captchaToken) {
  requestRegisterAsync(captchaToken);
}

const onClickBack = () => {
  emit('changeStep', {
    nextStep: 'inputPassword',
  });
};

const requestRegisterAsync = (captchaToken) => {
  tracker.queue(tracker.commands.SEND, 'Investor_LK_Register_Get_Code');

  let yandexClientID;
  if (window && window.yaCounter52664983) {
    yandexClientID = window.yaCounter52664983.getClientID();
  }
  const query = {
    phone: convertRawPhoneToLogin(props.rawPhone),
    password: props.rawPassword,
    utm: convertUtmsToQueryString(store.state.user.utm),
    email: localRawEmail.value,
    isPersonalDataProcessing: true,
    isAgreementWithRules: true,
  };

  if (localRawTelegram.value) {
    query.tgNickname = localRawTelegram.value;
  }

  if (isProduction) {
    query['smart-token'] = captchaToken;
  }

  if (yandexClientID) {
    query.yandexClientID = yandexClientID;
  }
  loading.value = true;
  return server.postUsersRegisterRequest
    .send(query)
    .pipe(onRequestRegisterAsyncSuccess.bind(this, query), ({ data }) => {
      loading.value = false;
      if (data?.message) {
        errorMessageTelegram.value = data.message;
      }
      if (isProduction) {
        window.smartCaptcha.reset();
      }
    })
    .exec();
};
const onRequestRegisterAsyncSuccess = (query, { data }) => {
  if (isProduction) {
    window.smartCaptcha.reset();
  }
  loading.value = false;
  if (data.isSuccessful) {
    tracker.queue(
      tracker.commands.SEND,
      'Adg_Investory_Shag4_Registracija_vvel_e_mail',
    );
    emit('changeStep', {
      nextStep: 'inputSMSCode',
      smsId: data.smsId,
    });
  }
};

watch(
  () => {
    return props.rawEmail;
  },
  () => {
    localRawEmail.value = props.rawEmail;
  },
);

onMounted(() => {
  localRawEmail.value = props.rawEmail;
  if (isProduction) {
    smartcaptchaRender();
  }
});
</script>
<style lang="scss" scoped>
// .container_wrapper {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }
.login_actions {
  min-height: 345px;
}

.footer_message {
  padding: 0 10px;
}
</style>
