<template>
  <div class="balance_item">
    <div class="balance_title_wrapper">
      <i class="icon_myinvestment_briefcase"></i>
      <div class="balance_tag">
        <div class="balance_tag_value">
          <div style="display: inline-block" @click.prevent="">
            <PotokTooltip>
              <button>{{ formatCurrency(statCount, 0) }} штук</button>
              <template #popper>
                Общее количество займов, инвестированных <br />
                вами в Потоке.
              </template>
            </PotokTooltip>
          </div>
        </div>
      </div>
    </div>

    <div class="balance_title">проинвестировано</div>
    <template v-if="loadingCompanyStat">
      <Loader style="margin-top: 10px" />
    </template>
    <template v-if="!loadingCompanyStat">
      <div class="balance_value">
        <div style="display: inline-block" @click.prevent="">
          <PotokTooltip>
            <MoneyValue
              :value="statAmount"
              :rounding="false"
              comma-color="#10273D"
              class="potok-text-h2"
            />
            <template #popper>
              Общая сумма инвестированных вами займов с момента регистрации
              в Потоке. Это не только средства, проинвестированные после
              пополнения счета, но и возвращенные заемщиками средства,
              вложенные в рамках автоинвестирования.
            </template>
          </PotokTooltip>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import mixins from '@/mixins';
import { formatCurrency } from '@/utils/commonUtils/libUtils';
import MoneyValue from '@/components/_generic/MoneyValue.vue';
import { Loader, PotokTooltip } from 'potok-uikit';

export default {
  components: {
    MoneyValue,
    Loader,
    PotokTooltip,
  },
  mixins: [mixins.common],
  props: ['statAmount', 'statCount', 'loadingCompanyStat'],
  methods: {
    formatCurrency,
  },
};
</script>
<style lang="scss" scoped>
@use '../../../../scss/variables.scss' as *;

.balance_title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance_value {
  color: $deep_dive;
}

.balance_tag {
  background: rgb(23 59 87 / 6%);

  .balance_tag_value {
    color: $deep_dive;
  }
}
</style>
