<template>
  <div>
    <div class="row row_first">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.search"
          class="form-control filter_item"
          placeholder="поиск по заемщику, id займа"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.purchaseDeals"
          v-maska
          :data-maska="localMasks.purchaseDeals"
          class="form-control filter_item"
          placeholder="ставка, %"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <vue-datepicker
          v-model="form.loanFundedAt"
          class="form-control filter_item"
          :input-format="'dd.MM.yyyy'"
          :locale="ru"
          placeholder="дата инвестирования"
        ></vue-datepicker>
        <button type="button" class="clear" @click="form.loanFundedAt = null">
          <span>&times;</span>
        </button>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <vue-datepicker
          v-model="form.loanDue"
          class="form-control filter_item"
          :input-format="'dd.MM.yyyy'"
          :locale="ru"
          placeholder="возврат"
        ></vue-datepicker>
        <button type="button" class="clear" @click="form.loanDue = null">
          <span>&times;</span>
        </button>
      </div>
    </div>
    <div class="row row_second">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentAmount"
          v-maska
          :data-maska="localMasks.digits"
          class="form-control filter_item"
          placeholder="сумма инвестиции"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentRepayment"
          v-maska
          :data-maska="localMasks.digits"
          class="form-control filter_item"
          placeholder="погашено"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentIncome"
          v-maska
          :data-maska="localMasks.digits"
          class="form-control filter_item"
          placeholder="процентный доход"
          @keyup="updateFilters"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <input
          v-model="form.myInvestmentDebt"
          v-maska
          :data-maska="localMasks.digits"
          class="form-control filter_item"
          placeholder="остаток основного долга"
          @keyup="updateFilters"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, watch, computed, onMounted } from 'vue';
import VueDatepicker from 'vue3-datepicker';
import ru from 'date-fns/locale/ru';
import masks from '@/utils/masks';
import { vMaska } from 'maska/vue';


const props = defineProps(['filters']);
const emit = defineEmits(['updateFilters']);

const form = reactive({
  search: null,
  purchaseDeals: null,
  loanFundedAt: null,
  loanDue: null,
  myInvestmentAmount: null,
  myInvestmentRepayment: null,
  myInvestmentIncome: null,
  myInvestmentDebt: null,
});


const localMasks = computed(() => {
  return {
    purchaseDeals: masks.digits(2),
    digits: masks.digits(15),
  };
});

const updateFilters = () => {
  emit('updateFilters', form);
};

watch(() => form.loanFundedAt, updateFilters);
watch(() => form.loanDue, updateFilters);

onMounted(() => {
  for (let key in props.filters) {
    if (form[key] !== undefined) {
      form[key] = props.filters[key];
    }
  }
});
</script>
<style lang="scss" scoped>
@use '../../../../../scss/variables.scss' as *;

.row {
  @media (max-width: $size_767) {
    margin: 0;
  }
}

.row_second {
  margin-top: 20px;

  @media (max-width: $size_767) {
    margin-top: 0;
  }
}

.col-xs-12 {
  @media (max-width: $size_767) {
    padding: 0;
  }
}
</style>
